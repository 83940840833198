import React, { useState } from 'react';
import Core from '@atomos/core';
import Business from '@tgf-crm/business';
import { Grid } from '@material-ui/core';

import ComponentBuilder from "../../../core/ComponentBuilder";

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppDenseGrid from '../../../core/components/AppDenseGrid/AppDenseGrid';
import LeftNav from '../LeftNav';
import SearchCard from './includes/SearchCard';
import TableTopBlock from './includes/TableTopBlock';
import RoleNames from '../../../hubs/persona/RoleNames';
import AppCurrencyText from '../../../core/components/text/AppCurrencyText';

const LoadPaymentsName = 'CommissionSummary.LoadPayments';
const LoadAssociatesName = 'CommissionSummary.LoadAssociates';

const PaymentAmountCell = ({ rowData: commissionRecord }) => {
  const paymentAmount = commissionRecord.paymentAmount;
  const hasPayment = Core.Utils.isNumber(paymentAmount);
  const style = hasPayment && paymentAmount < 0 ?
    { color: '#FF0000' } : null;
  return hasPayment ?
    (<AppCurrencyText value={paymentAmount} style={style} />) :
    null;
};

const GridColumns = [
  {
    title: 'First Name',
    field: 'firstName',
    styles: {
      width: '27%',
    },
    dataType: 'string'
  },
  {
    title: 'Last Name',
    field: 'lastName',
    styles: {
      width: '27%',
    },
    dataType: 'string'
  },
  {
    title: 'Payout Amount',
    field: 'paymentAmount',
    styles: {
      width: '26%',
      textAlign: 'center'
    },
    dataType: 'component',
    component: PaymentAmountCell
  },
  {
    title: 'Submitted Date',
    field: 'confirmDate',
    styles: {
      width: '20%',
      textAlign: 'center'
    },
    dataType: 'date'
  }
];

const computePayPeriodOptions = (monthYearSelectedDate) => {
  const payPeriods = Business.Associate.PayPeriod.buildFromMonth(
    monthYearSelectedDate.getMonth(),
    monthYearSelectedDate.getFullYear());

  const payPeriodOptions = payPeriods.map(pp => {
    const range = pp.toString();
    return {
      title: range,
      value: range,
      startDate: pp.startDate,
      endDate: pp.endDate
    }
  });
  return payPeriodOptions;
};

const CommissionSummary = (props) => {

  const {
    dispose,
    loadPayments,
    loadAssociates,
    associates = [],
    monthlyAssociatePayments,
    roles
  } = props;

  const queenRole = roles?.find(r => r.name === RoleNames.Queen);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [showAllAssociates, setShowAllAssociates] = React.useState(false);
  const [payPeriodOptions, setPayPeriodOptions] = React.useState(computePayPeriodOptions(selectedDate));
  const [payPeriod, setPayPeriod] = React.useState(payPeriodOptions[0]);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
    loadPayments(payPeriod);
  }, [payPeriod, loadPayments]);

  React.useEffect(() => {
    if (queenRole) {

      const options = {
        filter: {},
        roles: [queenRole.id],
        limit: 10000
      };

      if (!showAllAssociates) {
        options.filter.isActive = true;
      }

      loadAssociates(options);

    }
  }, [showAllAssociates, queenRole, loadAssociates]);

  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && orderBy === column) ? 'desc' : 'asc';

    setOrder(changeOrder);
    setOrderBy(column);
  };

  const handleMonthYearChange = (monthYearValue) => {
    const date = monthYearValue && monthYearValue.isValid() ?
      monthYearValue.toDate() : null;

    const payPeriods = computePayPeriodOptions(date);
    setSelectedDate(date);
    setPayPeriodOptions(payPeriods);
    setPayPeriod(payPeriods[0]);
  };

  const handlePayPeriodChange = (e, payPeriodValue) => {
    setPayPeriod(payPeriodValue);
  };

  const handleShowAllAssociatesChange = (e) => {
    setShowAllAssociates(e.target.checked);
  };

  const commissionSummaryRecords = associates.map(a => {
    return createCommissionSummaryRecord(a, monthlyAssociatePayments || [])
  });

  const sortedPayments = Core.Utils
    .orderBy(commissionSummaryRecords, [orderBy], [order]);

  const totalAmount = sortedPayments
    .reduce((amount, commSummaryRecord) => {
      const paymentAmount = Core.Utils.isNumber(commSummaryRecord.paymentAmount) ?
        commSummaryRecord.paymentAmount : 0;
      return amount + paymentAmount;
    }, 0);

  return (
    <FullWidthLayout SideNav={LeftNav} title="Commission Summary">
      <Grid container>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <SearchCard
                onMonthYearChange={handleMonthYearChange}
                onPayPeriodChange={handlePayPeriodChange}
                monthYearSelectedDate={selectedDate}
                payPeriodOptions={payPeriodOptions}
                payPeriod={payPeriod}
              />
            </Grid>
            <Grid item>
              <AppDenseGrid
                columns={GridColumns}
                count={sortedPayments.length}
                data={sortedPayments || []}
                orderBy={orderBy}
                order={order}
                HeaderBar={TableTopBlock(totalAmount, handleShowAllAssociatesChange, showAllAssociates)}
                FooterBar={TableTopBlock(totalAmount, handleShowAllAssociatesChange, showAllAssociates)}
                onSort={handleSortChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FullWidthLayout >
  )
}

const createCommissionSummaryRecord = (associate, monthlyAssociatePayments) => {

  const payment = monthlyAssociatePayments.find(payment => payment.associate.id === associate.id);

  return {
    firstName: associate.firstName,
    lastName: associate.lastName,
    paymentAmount: payment?.payment1,
    confirmDate: payment?.confirmDate
  }
}

export default ComponentBuilder
  .wrap(CommissionSummary)
  .stateToProps((state, ownProps) => ({
    monthlyAssociatePayments: state.admin.commissionSummary.monthlyAssociatePayments,
    monthlyAssociatePaymentCount: state.admin.commissionSummary.monthlyAssociatePaymentsCount,
    associates: state.admin.commissionSummary.associates,
    roles: state.persona.roles
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async loadPayments(payPeriod) {
        dispatch(shell.actions.sys.processStart(LoadPaymentsName));
        dispatch(await shell.actions.admin.commissionSummary.loadMonthlyAssociatePayments(payPeriod.startDate));
        dispatch(shell.actions.sys.processComplete(LoadPaymentsName));
      },
      async loadAssociates(options) {
        dispatch(shell.actions.sys.processStart(LoadAssociatesName));
        dispatch(await shell.actions.admin.commissionSummary.loadAssociates(options));
        dispatch(shell.actions.sys.processComplete(LoadAssociatesName));
      },
      async dispose() {
        dispatch(await shell.actions.commission.dispose());
      }
    }
  })
  .build()