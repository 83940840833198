import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppDateText from '../../../../core/components/text/AppDateText';
import AppIntegerText from '../../../../core/components/text/AppIntegerText';
import {Grid} from '@material-ui/core';
import AppTooltip from '../../../../core/components/AppTooltip';
import {ErrorOutline, FindInPage as FindInPageIcon} from '@material-ui/icons';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';

const useStyles = makeStyles((theme) => ({
  pastDue: {
    color: theme.palette.error.main,
    fontWeight: 'bold'
  },
  daysPastDueContainer: {
    '& svg': {
      marginTop: 1,
      height: 16,
      color: theme.palette.error.main
    }
  },
  lessThanZero: {
    color: theme.palette.error.main
  }
}));

const InvoiceDueDateCell = (paymentTerms) => ({ rowData: record }) => {
  const invoiceDueDate = record?.invoiceDate
    .toMoment()
    .add(paymentTerms, 'days')
    .toDate();
  return (<AppDateText value={invoiceDueDate} />);
};

const PastDueDaysCell = (classes) => ({ rowData: record }) => {
  const className = record.invoiceDaysPastDue > 0 ?
    classes.pastDue : null;
  const icon = record.isDisputed ? (<DisputedIcon />) : null;
  return (
    <Grid container className={classes.daysPastDueContainer}>
      <Grid item>
        <AppIntegerText value={record.invoiceDaysPastDue} className={className} />
      </Grid>
      <Grid item>
        {icon}
      </Grid>
    </Grid>
  );
};

const DisputedIcon = (props) => {
  return (
    <AppTooltip title={'This shipment is in Dispute status.'}>
      <ErrorOutline />
    </AppTooltip>
  );
};

const AgingShipmentListing = (props) => {

  const classes = useStyles();

  const {
    paymentTerms,
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    onDocumentQuickViewClick,
    associate
  } = props;

  const GridColumns = [
    {
      field: 'shipmentBolNumber',
      title: 'Ship #',
      styles: {
        width: 60
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
    },
    {
      field: 'shipmentBolDate',
      title: 'Pickup Date',
      styles: {
        width: 80
      },
      dataType: 'date'
    },
    {
      field: 'carrierName',
      title: 'Carrier',
      styles: {
        width: 180
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/carrier/${record.carrierMcNumber}/details`, record.carrierName])
    },
    {
      field: 'shipmentStatusName',
      title: 'Status',
      styles: {
        width: 80
      },
      dataType: 'string'
    },
    {
      field: 'carrierCost',
      title: 'Carrier Cost',
      dataType: 'currency',
      styles: {
        width: 70
      },
    },
    {
      field: 'invoiceTgfFee',
      title: 'TGF Fee',
      styles: {
        width: 70
      },
      dataType: 'currency'
    },
    {
      field: 'customerCost',
      title: 'Cust. Cost',
      styles: {
        width: 70
      },
      dataType: 'currency'
    },
    {
      field: 'totalMargin',
      title: 'Total Margin',
      styles: {
        width: 70
      },
      dataType: 'currency'
    },
    {
      field: 'invoiceActualDeliveryDate',
      title: 'Locked Date',
      styles: {
        width: 80
      },
      dataType: 'date'
    },
    {
      field: 'invoiceEarnedPayout',
      title: 'Payout',
      styles: {
        display: associate.displayPayouts ? '' : 'none',
        width: 80
      },
      dataType: 'currency'
    },
    {
      field: 'commissionPayoutDate',
      title: 'Payout Date',
      dataType: 'date',
      styles: {
        width: 80
      }
    },
    {
      field: 'invoiceDate',
      title: 'Inv. Date',
      styles: {
        width: 80
      },
      dataType: 'date'
    },
    {
      field: '',
      noSort: true,
      title: 'Inv. Due Date',
      styles: {
        width: 80
      },
      dataType: 'component',
      component: InvoiceDueDateCell(paymentTerms)
    },
    {
      field: 'invoiceDaysPastDue',
      title: 'Past Due',
      component: PastDueDaysCell(classes),
      styles: {
        width: 60
      },
      dataType: 'component'
    },
    {
      field: '',
      title: '',
      component: ({ rowData: shipmentRecord }) => {
        return (
          <div style={{textAlign: 'center'}}>
            <AppIconButton Icon={FindInPageIcon} tooltip={'Quick view the shipment\'s documents.'} onClick={() => onDocumentQuickViewClick(shipmentRecord)}/>
          </div>
        );
      },
      styles: {
        width: 40
      },
      dataType: 'component'
    }
  ];

  return (
    <AppDenseGrid
      count={count || 0}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
}

export default AgingShipmentListing;    