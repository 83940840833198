import React from 'react';
import FullWidthLayout from '../../core/layouts/FullWidthLayout';
import LeftNav from './LeftNav';

const Admin = (props) => {
  return (
    <FullWidthLayout SideNav={LeftNav} title={'Admin'}>

    </FullWidthLayout>
  );
}

export default Admin;