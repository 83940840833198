import React from 'react';
import {Grid} from '@material-ui/core';
import {FiberManualRecord} from '@material-ui/icons';
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const ChartLegendBar = ({ chartDescriptor, settings: chartSettings, data, AggregateText = AppCurrencyText }) => {

  const enabledFeatureNames = Array.from(Object.entries(chartSettings.features))
    .filter(([featureName, isEnabled]) => isEnabled)
    .map(([featureName]) => featureName);

  const enabledFeatureTuples = enabledFeatureNames
    .map((featureName) => [featureName, chartDescriptor.getFeatureTitle(featureName)]);

  return (
    <Grid container direction={'row'} alignItems={'center'}>
      {
        enabledFeatureTuples.map(([featureName, title], index) => {
          return (
            <React.Fragment key={index}>
              <FiberManualRecord style={{ fill: chartDescriptor.features[featureName].lineColor }} />
              <span style={{ marginRight: 4 }}>{title} <strong><AggregateText value={chartDescriptor[chartSettings.aggregateType](featureName, data)} /></strong></span>
            </React.Fragment>
          );
        })
      }
    </Grid>
  );
};

export default ChartLegendBar;
