import React from 'react';
import Grid from '@material-ui/core/Grid';

import ComponentBuilder from "../../../../core/ComponentBuilder";
import AppPhoneInput from "../../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput";
import AppInput from "../../../../core/components/inputs/AppInput/AppMuiInput";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";
import SecureField from "../../permissions/SecureField";


const DriverDetailCard = (props) => {

  const {
    driverName,
    driverPhone,
    truckNumber,
    trailerNumber,
    setFieldValue,
    driverPhoneError
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  return (
    <React.Fragment>
        <AppMuiCard title="Driver Detail">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'driverName'}
                as={AppInput}
                id={'driverName'}
                label={'Driver Name'}
                value={driverName || ''}
                inputProps={{
                  maxLength: 60
                }}
                onChange={handleFieldChange('driverName')}
              />
            </Grid>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'driverPhone'}
                as={AppPhoneInput}
                id={'driverPhone'}
                label={'Driver Phone'}
                value={driverPhone || ''}
                onChange={handleFieldChange('driverPhone')}
              />
              <AppValidationFailure
                message={driverPhoneError}
              />
            </Grid>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'truckNumber'}
                as={AppInput}
                id={'tractorNumber'}
                label={'Tractor Number'}
                value={truckNumber || ''}
                inputProps={{
                  maxLength: 60
                }}
                onChange={handleFieldChange('truckNumber')}
              />
            </Grid>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'trailerNumber'}
                as={AppInput}
                id={'trailerNumber'}
                label={'Trailer Number'}
                value={trailerNumber || ''}
                inputProps={{
                  maxLength: 60
                }}
                onChange={handleFieldChange('trailerNumber')}
              />
            </Grid>
          </Grid>
        </AppMuiCard>
    </React.Fragment>
  )
};

export default ComponentBuilder
  .wrap(DriverDetailCard)
  .build();