import React from 'react';
import { Grid } from '@material-ui/core';

import ComponentBuilder from '../../../core/ComponentBuilder';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ReportNav from '../ReportNav';
import MissingCreditAppListing from './includes/MissingCreditAppListing';

const LoadProcessName = 'Reporting.MissingCreditApp.Loaded';

const MissingCreditAppPage = (props) => {

  const {
    companyListing,
    companyCount,
    load,
    dispose
  } = props;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['companyName', 'asc']]);

  React.useEffect(() => () => dispose(), []);

  React.useEffect(() => {
      load(offset, limit, sort);
  }, [offset, limit, sort, load]);

  const handleSortChange = (column) => {
    const [[sortColumn, sortDir]] = sort;
    const changeOrder = (sortDir === 'asc' && sortColumn === column) ? 'desc' : 'asc';
    setSort([[column, changeOrder]]);
  };

  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

return (
  <FullWidthLayout 
  SideNav={ReportNav} 
  title="Credit App on File Report"
  >
    <Grid container spacing={2} direction="column">
      <Grid item>
        <MissingCreditAppListing
          count={companyCount}
          data={companyListing}
          orderBy={sort[0][0]}
          order={sort[0][1]}
          rowsPerPage={limit}
          page={offset / limit}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onSort={handleSortChange}
        />
      </Grid>
    </Grid>
  </FullWidthLayout>
)};

export default ComponentBuilder
.wrap(MissingCreditAppPage)
.stateToProps((state, ownProps) => {
  return {
    companyListing: state.reporting.missingCreditApp.listing,
    companyCount: state.reporting.missingCreditApp.count
  }
})
.dispatchToProps((shell, dispatch, getState) => {
  return {
    async load(offset, limit, sort) {
      dispatch(shell.actions.sys.processStart(LoadProcessName));
      dispatch(await shell.actions.reporting.missingCreditApp.load(offset, limit, sort));
      dispatch(shell.actions.sys.processComplete(LoadProcessName));
    },
    async dispose() {
      dispatch(await shell.actions.reporting.missingCreditApp.dispose());
    }
  }
})
.build();