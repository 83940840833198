// Constants for pulling IDs from the database results.
// They must match the name in the table for the app to work.
const CategoryTypeNames = {
    Customer: 'Customer',
    ShipperConsignee: 'Shipper/Consignee',
    ThirdParty: 'Third Party',
    Lead: 'Lead',
    BillingCompany: 'Billing Company',
    FactoringCompany: 'Factoring Company'
};

export default CategoryTypeNames;