import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Divider } from '@material-ui/core';
import { Publish } from '@material-ui/icons';

import ComponentBuilder from '../../../core/ComponentBuilder';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import CarrierRecordNav from "../CarrierRecordNav";
import CarrierDocumentTypeNames from '../../../hubs/carrier/CarrierDocumentTypeNames';

import AppDocumentDrawer from '../../../core/components/Documents/AppDocumentDrawer';
import AppDocumentRemoveFile from '../../../core/components/Documents/AppDocumentRemoveFile';
import AppDocumentFilters from '../../../core/components/Documents/AppDocumentFilters';
import AppComposeDocumentTypeColors from '../../../core/components/Documents/AppComposeDocumentTypeColors';

import AppButton from '../../../core/components/AppButton';

import AppDocumentCardGroup from '../../../core/components/Documents/AppDocumentCardGroup';
import "./DocumentsPage.scss";

const LoadProcessName = 'Carrier.DocumentsPage.Load';
const PublishProcessName = 'Carrier.DocumentsPage.Publish';
const DeleteProcessName = 'Carrier.DocumentsPage.Delete';

const useStyles = makeStyles(theme => ({
  noDocs: {
    marginTop: 50,
    textAlign: 'center'
  }
}));

const createDocColors = (isAce, hasCarrierPrivilege) => ({
  [CarrierDocumentTypeNames.Insurance]: {
    color: 'tgfBlack',
    isVisible: isAce && hasCarrierPrivilege
  },
  [CarrierDocumentTypeNames.Packet]: {
    color: 'error',
    isVisible: isAce && hasCarrierPrivilege
  },
  [CarrierDocumentTypeNames.Other]: {
    color: 'warning',
    isVisible: isAce && hasCarrierPrivilege
  }
});

const DocumentsPage = (props) => {
  const classes = useStyles();
  const [openNewDocument, setOpenNewDocument] = React.useState(false);
  const [removeFileOpen, setRemoveFileOpen] = React.useState(false);
  const [cardDocument, setCardDocument] = React.useState(null);
  const [documentId, setDocumentId] = React.useState(null);
  const [documentFilter, setDocumentFilter] = React.useState(0); //Defaults to All (0)
  const [animateIn, setAnimateIn] = React.useState(true);
  const {
    dispose,
    isAce,
    load,
    match,
    carrier,
    carrierDocuments,
    deleteCarrierDocument,
    hasCarrierPrivilege,
    publishCarrierDocument,
    carrierDocumentTypes,
    sendSnackbarMessage
  } = props;

  const mcNumber = match.params.id;
  const docColors = createDocColors(isAce, hasCarrierPrivilege);
  const securedCategoryTypes = carrierDocumentTypes
    .filter(docType => docColors[docType.name].isVisible);

  const coloredDocumentTypes = AppComposeDocumentTypeColors(carrierDocumentTypes, docColors);
  const filterColoredDocumentTypes = AppComposeDocumentTypeColors(securedCategoryTypes, docColors);

  React.useEffect(() => {
    load(mcNumber);

    return () => dispose();
  }, [mcNumber, load, dispose]);

  const handleCloseNewDocument = () => setOpenNewDocument(false);
  const handleNewDocument = () => setOpenNewDocument(true);
  const handleRemoveFileClose = () => setRemoveFileOpen(false);

  const handlePublishClick = (carrierDocument) => {
    publishCarrierDocument(mcNumber, carrierDocument)
      .then(() => {
        setOpenNewDocument(false);
        sendSnackbarMessage({ content: 'Document uploaded.'})
      });
  };

  const handleOpenDownloadFileClick = (document, shouldOpen) => {
    const a = window.document.createElement('a');

    shouldOpen ? a.target = '_blank' : a.download = document.filename;

    a.href = `/file/carrier/${mcNumber}/document/${document.id}`;
    a.click();
    a.remove();
  };

  const handleRemoveFileClick = (document) => {
    setCardDocument(document);
    setDocumentId(document.id);
    setRemoveFileOpen(true);
  }

  const buildData = (filterItem) => {
    const cardCarrierDocuments = carrierDocuments
      .filter(item => filterItem === 0 ? item : filterItem === item.typeId)
      .map(document => ({
        ...document,
        docType: coloredDocumentTypes.find(docType => docType.id === document.typeId),
        path: `/carrier/${document.mcNumber}/document/${document.id}`,
      }));
    return cardCarrierDocuments;
  };

  const handleRemoveFileConfirmClick = () => {
    setRemoveFileOpen(false);
    deleteCarrierDocument(mcNumber, documentId)
      .then(() => {
        sendSnackbarMessage({content: 'Document deleted.'});
      });
  };

  const NoDocuments = () => <Typography variant="h4" className={classes.noDocs}>No documents available.</Typography>;

  const handleFilter = (filterItem) => {
    setAnimateIn(false);
    setDocumentFilter(filterItem);
    setTimeout(() => {
      setAnimateIn(true);
    }, 300);
  };

  const filterDocuments = buildData(documentFilter);
  const titlePostfix = carrier && mcNumber ?
    `${carrier.name} (${mcNumber})` :
    '';
  const options = [
    {
      label: 'Open',
      click: (document) => handleOpenDownloadFileClick(document, true),
      isVisible: true
    },
    {
      label: 'Download',
      click: (document) => handleOpenDownloadFileClick(document, false),
      isVisible: true
    },
    {
      label: 'Remove',
      click: (document) => handleRemoveFileClick(document),
      isVisible: isAce && hasCarrierPrivilege
    }
  ];

  return (
    <FullWidthLayout SideNav={CarrierRecordNav} className="carrier-styles">
      <Grid container spacing={2}>
        <Grid item xs={6}><Typography variant="h3">Carriers - Documents - {titlePostfix}</Typography></Grid>
        {hasCarrierPrivilege && 
          <Grid item container xs={6} justify="flex-end">
          <AppButton onClick={handleNewDocument}>
            <Publish /> Upload
          </AppButton>
        </Grid>
        }
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <AppDocumentFilters
            documentTypes={coloredDocumentTypes}
            onFilter={handleFilter}
            filterItem={documentFilter}
          />
        </Grid>
        <Grid item xs={12}>
          {
            filterDocuments.length > 0 ?
              <AppDocumentCardGroup
                documents={filterDocuments}
                options={options}
                animateIn={animateIn}
              />
              : <NoDocuments />
          }
        </Grid>
      </Grid>
      <AppDocumentDrawer
        anchor="right"
        documentTypes={filterColoredDocumentTypes}
        open={openNewDocument}
        onClose={handleCloseNewDocument}
        onPublish={handlePublishClick}
      />
      {cardDocument && <AppDocumentRemoveFile
        document={cardDocument}
        open={removeFileOpen}
        onClose={handleRemoveFileClose}
        onConfirmClick={handleRemoveFileConfirmClick}
      />
      }
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(DocumentsPage)
  .stateToProps((state, ownProps) => (
    {
      carrier: state.carrier.modification.carrier,
      carrierDocuments: state.carrier.modification.carrierDocuments,
      carrierDocumentTypes: state.support.carrierDocumentTypes,
      hasCarrierPrivilege: state.persona.modifyingAssociate.hasCarrierPrivilege,
      isAce: isAce(state)
    }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(mcNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const actionResults = await Promise.all([
          shell.actions.carrier.modification.loadCarrier(mcNumber),
          shell.actions.carrier.modification.loadCarrierDocuments(mcNumber)
        ]);

        actionResults.forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.modification.dispose());
      },
      async deleteCarrierDocument(mcNumber, documentId) {
        dispatch(shell.actions.sys.processStart(DeleteProcessName));
        dispatch(await shell.actions.carrier.modification.deleteCarrierDocument(mcNumber, documentId));
        dispatch(shell.actions.sys.processComplete(DeleteProcessName));
      },
      async publishCarrierDocument(mcNumber, carrierDocument) {
        dispatch(shell.actions.sys.processStart(PublishProcessName));
        dispatch(await shell.actions.carrier.modification.publishCarrierDocument(mcNumber, carrierDocument));
        dispatch(shell.actions.sys.processComplete(PublishProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();
