import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonGroup, Button } from '@material-ui/core';

import combineClassNames from '../../../core/utils/combineClassNames';

/**
 * Provides the navigation bar for the LoadBoard page.
 */
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.tgfGrey.light,
      },
    },
    active: {
      textDecoration: 'none',
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main,
      }
    },
    badge: {
      borderRadius: 5,
      marginLeft: 5,
      padding: '0 3px',
      color: theme.palette.white,
    },
    activeBadge: {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    },
    default: {
      backgroundColor: theme.palette.default.main,
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
    primary: {
      backgroundColor: theme.palette.primary.main,
    }
  }
));
const ListingFilterBar = ({ counts, trackedSet, isAce, onClick }) => {

  const classes = useStyles();
  const params = useParams();

  const buttonList = [
    {
      title: 'New / In Transit',
      link: '/shipment/listing/working',
      value: counts.working,
      location: 'working',
      style: 'primary',
      visible: true
    },
    {
      title: 'Awaiting POD',
      link: '/shipment/listing/awaiting-pod',
      value: counts['awaiting-pod'],
      location: 'awaiting-pod',
      style: 'primary',
      visible: true
    },
    {
      title: 'Delivered',
      link: '/shipment/listing/delivered',
      value: counts.delivered,
      location: 'delivered',
      style: 'error',
      visible: true
    },
    {
      title: 'Dispute',
      link: '/shipment/listing/dispute',
      value: counts.dispute,
      location: 'dispute',
      style: 'warning',
      visible: true
    },
    {
      title: 'Hold',
      link: '/shipment/listing/hold',
      value: counts.hold,
      location: 'hold',
      style: 'primary',
      visible: isAce
    },
    {
      title: 'Locked',
      link: '/shipment/listing/locked',
      value: counts.locked,
      location: 'locked',
      style: 'primary',
      visible: isAce
    }
  ];

  return (
    <ButtonGroup role="group" aria-label="CRM toolbar button group.">
      {
        buttonList.map((item, key) => {
          const activeClassName = item.location === params.trackedSet ? classes.active : classes.buttonLink;
          const activeBadge = item.location === params.trackedSet && item.style === 'primary' ? classes.activeBadge : classes[item.style];
          return (
            item.visible &&
            <Button
              component={Link}
              className={activeClassName}
              key={key}
              onClick={onClick}
              to={item.link}>
              {item.title}
              <span className={combineClassNames(classes.badge, activeBadge)}>{item.value}</span>
            </Button>
          )
        })
      }
    </ButtonGroup>
  );
}

export default ListingFilterBar;
