import React from 'react';
import { Grid } from '@material-ui/core';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppButton from '../../../core/components/AppButton';
import AppDatePicker from '../../../core/components/inputs/AppDatePicker';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AddressBookRecordNav from '../AddressBookRecordNav';
import ComponentBuilder from '../../../core/ComponentBuilder';
import selectCustomerShipmentsSearch from '../../../hubs/shipment/selectors/selectCustomerShipmentsSearch';
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';
import ShipmentHistoryListing from './includes/ShipmentHistoryListing';
import './ShipmentHistoryPage.scss';
import CrmDocumentQuickViewDrawer from '../../../crm/components/CrmDocumentQuickViewDrawer';
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import FormFactor from "../../../core/FormFactor/FormFactor";
import renderDetailsForm from "../DetailsPage/renderDetailsForm";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import isAce from "../../../hubs/persona/selectors/isAce";

const LoadProcessName = 'AddressBook.ShipmentHistoryPage.Load';
const SearchProcessName = 'AddressBook.ShipmentHistoryPage.Search';
const ShipmentHistorySearchId = 'AddressBook.ShipmentHistoryPage';

const ShipmentHistoryPage = (props) => {
  const {
    company,
    isAce,
    modifyingAssociate,
    dispose,
    match,
    shipments,
    shipmentCount,
    searchCustomerShipments,
    clearCustomerSearch,
    load,
    associate
  } = props;

  const companyId = match.params.id &&
    parseInt(match.params.id);

  if (isNewCompany(companyId)) {
    throw new Error('CompanyId url parameter missing.');
  }

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['shipmentBolNumber', 'desc']]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('shipmentBolNumber');

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [shipmentNumber, setShipmentNumber] = React.useState('');
  const [docQuickViewShipmentId, setDocQuickViewShipmentId] = React.useState(null);

  React.useEffect(() => {
    return () => clearCustomerSearch();
  }, [clearCustomerSearch]);

  React.useEffect(() => {
    load(companyId);
    return () => dispose();
  }, [companyId, load, dispose]);

  React.useEffect(() => {
    performSearch();
  }, [offset, limit, sort]);

  const performSearch = () => {

    const processedStartDate = startDate && startDate.format('YYYY-MM-DD');
    const processedEndDate = endDate && endDate.format('YYYY-MM-DD');

    const args = [
      companyId,
      processedStartDate || null,
      processedEndDate || null,
      (shipmentNumber && parseInt(shipmentNumber)) || null,
      offset,
      limit,
      sort
    ];
    searchCustomerShipments(...args);
  };

  const handleSearchClick = (e) => {
    setOffset(0);
    performSearch();
  };

  const handleStartDateChange = (dateValue) => {
    setStartDate(dateValue);
  };

  const handleEndDateChange = (dateValue) => {
    setEndDate(dateValue);
  };

  const handleShipmentNumberChange = (e) => {
    setShipmentNumber(e.target.value);
  };

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleDocumentQuickViewClick = (shipment) => {
    setDocQuickViewShipmentId(shipment.shipmentBolNumber);
  };

  const handleDocumentQuickViewClose = () => {
    setDocQuickViewShipmentId(null);
  };

  const title = company ?
    `Address Book - Shipment History - ${company.name}` :
    `Address Book - Shipment History`;

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title} className="address-book-styles">
      {company !== null ?
        <CheckIfAuthorized
          isAce={isAce}
          modifyingAssociate={modifyingAssociate}
          company={company} >
          <Grid container spacing={1}>
            <Grid item xs={12} md={3}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} md={2}>From:</Grid>
                <Grid item xs={12} md={10}>
                  <AppDatePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} md={2}>To:</Grid>
                <Grid item xs={12} md={10}>
                  <AppDatePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item xs={12} md={2}>Ship #:</Grid>
                <Grid item xs={12} md={10}>
                  <AppInput
                    onChange={handleShipmentNumberChange}
                    inputProps={{
                      maxLength: 10
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={3} style={{ textAlign: 'right' }}>
              <AppButton
                onClick={handleSearchClick}>
                Search
              </AppButton>
            </Grid>
            <Grid item xs={12}>
              <ShipmentHistoryListing
                count={shipmentCount}
                data={shipments}
                orderBy={orderBy}
                order={order}
                rowsPerPage={limit}
                page={offset / limit}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onSort={handleSortChange}
                onDocumentQuickViewClick={handleDocumentQuickViewClick}
                associate={associate}
              />
            </Grid>
          </Grid>
          {
            docQuickViewShipmentId &&
            <CrmDocumentQuickViewDrawer
              shipmentId={docQuickViewShipmentId}
              onClose={handleDocumentQuickViewClose}
            />
          }
        </CheckIfAuthorized>
        :
        <MissingOrDeniedCompanyAlert />}

    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(ShipmentHistoryPage)
  .stateToProps((state, ownProps) => {
    const { shipments, count: shipmentCount } = selectCustomerShipmentsSearch(state, ShipmentHistorySearchId) || {};
    return {
      company: state.addressBook.modification.company,
      isAce: isAce(state),
      modifyingAssociate: state.persona.modifyingAssociate,
      associate: state.persona.associate,
      shipments,
      shipmentCount
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async searchCustomerShipments(companyId, fromDate, toDate, shipmentNumber, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(SearchProcessName));
        dispatch(await shell.actions.shipment.customer
          .searchCustomerShipments(ShipmentHistorySearchId, companyId, fromDate, toDate, shipmentNumber, false, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(SearchProcessName));
      },
      async clearCustomerSearch() {
        dispatch(await shell.actions.shipment.customer.clearCustomerShipmentsSearch(ShipmentHistorySearchId));
      },
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.addressBook.modification.loadCompany(companyId));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      }
    };
  })
  .build();