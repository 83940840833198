import React from 'react';

import {Grid} from '@material-ui/core';
import AppInput from '../../../../core/components/inputs/AppInput';
import AppToolTip from '../../../../core/components/AppTooltip/AppTooltip';
import {FileCopy, Visibility, VisibilityOff} from '@material-ui/icons';
import {makeStyles} from "@material-ui/core/styles";
import browserDetect from "../../../../core/utils/browserDetect";

const PasswordField = (props) => {

    const useStyles = makeStyles(theme => ({
        passwordHidden: {
            FontFamily: 'text-security-disc',
            WebkitTextSecurity: 'disc'
        },
    }));
    const classes = useStyles();
    const {
        sendSnackbarMessage,
        password,
        onPasswordChange
    } = props;
    let sBrowser =  browserDetect();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleToggleVisibilityClick = () =>
        setShowPassword(!showPassword);
    const VisibilityIcon = showPassword ? VisibilityOff : Visibility;
    const titlePrefix = showPassword ? 'Hide' : 'Show';

    const handleCopyPasswordClick = () => {
        navigator.clipboard.writeText(password);
        sendSnackbarMessage({content: 'Password copied to clipboard.'});
    };
    if (sBrowser === "Mozilla Firefox") {

        return (

            <Grid container item spacing={1} xs={12} alignItems={'flex-end'}>
                <Grid item xs={8}>

                    <AppInput
                        id={'decryptedPassword'}
                        label={'Password'}
                        value={password || ''}
                        type={showPassword ? 'text' : 'password'}
                        inputProps={{
                            form: {
                                autocomplete: 'off'
                            }
                        }}
                        onChange={e => onPasswordChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <AppToolTip title={`${titlePrefix} Password`}>
                        <VisibilityIcon onClick={handleToggleVisibilityClick} fontSize={'small'}/>
                    </AppToolTip>
                </Grid>
                <Grid item xs={1}>
                    <AppToolTip title={'Copy password to clipboard.'}>
                        <FileCopy onClick={handleCopyPasswordClick}/>
                    </AppToolTip>
                </Grid>
            </Grid>
        );


    } else if (sBrowser !== "Mozilla Firefox")

        return (

            <Grid container item spacing={1} xs={12} alignItems={'flex-end'}>
                <Grid item xs={8}>
                    <AppInput
                        id={'decryptedPassword'}
                        label={'Password'}
                        value={password || ''}
                        type={'text'}
                        className={showPassword ? '' : classes.passwordHidden}
                        inputProps={{
                            form: {
                                autocomplete: 'off'
                            }
                        }}
                        onChange={e => onPasswordChange(e.target.value)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <AppToolTip title={`${titlePrefix} Password`}>
                        <VisibilityIcon onClick={handleToggleVisibilityClick} fontSize={'small'}/>
                    </AppToolTip>
                </Grid>
                <Grid item xs={1}>
                    <AppToolTip title={'Copy password to clipboard.'}>
                        <FileCopy onClick={handleCopyPasswordClick}/>
                    </AppToolTip>
                </Grid>
            </Grid>
        )
};

export default PasswordField;