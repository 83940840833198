import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {AppBar, Tab, Tabs} from '@material-ui/core';
import TabPanel from './TabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
    height: '75vh'
  }
}));

const tabProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

const DocumentTabs = (props) => {

  const classes = useStyles();

  const {
    rulesContent,
    bolContent,
    rateConContent,
    deliveryOrderContent
  } = props;

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [
    {
      title: 'Rules',
      visible: true,
      content: rulesContent
    },
    {
      title: 'BOL',
      visible: Boolean(bolContent),
      content: bolContent
    },
    {
      title: 'Rate Con.',
      visible: Boolean(rateConContent),
      content: rateConContent
    },
    {
      title: 'Delivery Order',
      visible: Boolean(deliveryOrderContent),
      content: deliveryOrderContent
    }
  ].filter(t => t.visible);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleTabChange} aria-label="Tabs Example">
          {
            tabs.map((tab, index) => (
              <Tab label={tab.title} {...tabProps(index)} key={index} />
            ))
          }
        </Tabs>
      </AppBar>
      {
        tabs.map((tab, index) => (
          <TabPanel value={value} index={index} key={index} className={classes.tab}>
            {tab.content}
          </TabPanel>
        ))
      }
    </div>
  );

};

export default DocumentTabs;