import React from 'react';
import {Grid} from '@material-ui/core';
import {Save, Undo} from '@material-ui/icons';
import Core from '@atomos/core';

import AppButton from '../../../core/components/AppButton';
import OverallCosts from "./includes/OverallCosts";
import StatusDates from "./includes/StatusDates";
import AddedCarrierCosts from "./includes/AddedCarrierCosts";
import DeductedCarrierCosts from "./includes/DeductedCarrierCosts";
import AddedCustomerCosts from "./includes/AddedCustomerCosts";
import DeductedCustomerCosts from "./includes/DeductedCustomerCosts";
import FinancialNotes from "./includes/FinancialNotes";

import './financialPage.scss';

const renderFinancialForm = ({values, originalValues, errors, isValid, isDirty, getFieldValue, setFieldValue, setFieldValues, resetForm}) => {

  const handleResetClick = (e) =>
    resetForm();

  const overallCostProps = {
    isAce: Core.Utils.get(values, 'isAce'),
    carrierCost: Core.Utils.get(values, 'invoice.carrierCost', null),
    customerCost: Core.Utils.get(values, 'invoice.customerCost', null),
    adjustedCarrierCost: Core.Utils.get(values, 'invoice.adjustedCarrierCost', null),
    adjustedCustomerCost: Core.Utils.get(values, 'invoice.adjustedCustomerCost', null),
    carrierCostError: errors.invoice?.carrierCost,
    customerCostError: errors.invoice?.customerCost,
    adjustedCarrierCostError: errors.invoice?.adjustedCarrierCost,
    adjustedCustomerCostError: errors.invoice?.adjustedCustomerCost,
    actualDeliveryDateError: errors.invoice?.actualDeliveryDate,
    setFieldValue,
    getFieldValue
  };

  const statusDatesProps = {
    isAce: Core.Utils.get(values, 'isAce'),
    actualDeliveryDate: Core.Utils.get(values, 'invoice.actualDeliveryDate', null),
    confirmedAssociateWasPaid: Core.Utils.get(values, 'invoice.confirmedAssociateWasPaid', false),
    invoiceDate: Core.Utils.get(values, 'invoice.invoiceDate', null),
    invoiceDueDate: Core.Utils.get(values, 'invoice.invoiceDueDate', null),
    customerPaidDate: Core.Utils.get(values, 'invoice.customerPaidDate', null),
    paymentTerms: Core.Utils.get(values, 'shipmentCustomer.paymentTerms', 30),
    customerHasPaid: Core.Utils.get(values, 'customerHasPaid'),
    actualDeliveryDateError: errors.invoice?.actualDeliveryDate,
    setFieldValue,
    setFieldValues
  };

  const addedCarrierCosts = {
    detentionAmount: Core.Utils.get(values, 'invoice.detentionAmount', null),
    layoverAmount: Core.Utils.get(values, 'invoice.layoverAmount', null),
    reweighAdjustmentAmount: Core.Utils.get(values, 'invoice.reweighAdjustmentAmount', null),
    reclassAdjustmentAmount: Core.Utils.get(values, 'invoice.reclassAdjustmentAmount', null),
    carrierLumpersAmount: Core.Utils.get(values, 'invoice.carrierLumpersAmount', null),
    carrierOtherAmountLabel: Core.Utils.get(values, 'invoice.carrierOtherAmountLabel', null),
    carrierOtherAmount: Core.Utils.get(values, 'invoice.carrierOtherAmount', null),
    detentionAmountError: errors.invoice?.detentionAmount,
    layoverAmountError: errors.invoice?.layoverAmount,
    reweighAdjustmentAmountError: errors.invoice?.reweighAdjustmentAmount,
    reclassAdjustmentAmountError: errors.invoice?.reclassAdjustmentAmount,
    carrierLumpersAmountError: errors.invoice?.carrierLumpersAmount,
    carrierOtherAmountLabelError: errors.invoice?.carrierOtherAmountLabel,
    carrierOtherAmountError: errors.invoice?.carrierOtherAmount,
    setFieldValue
  };

  const deductedCarrierCosts = {
    isAce: Core.Utils.get(values, 'isAce'),
    carrierDeductedCost1Label: Core.Utils.get(values, 'invoice.carrierDeductedCost1Label', null),
    carrierDeductedCost1: Core.Utils.get(values, 'invoice.carrierDeductedCost1', null),
    carrierDeductedCost2Label: Core.Utils.get(values, 'invoice.carrierDeductedCost2Label', null),
    carrierDeductedCost2: Core.Utils.get(values, 'invoice.carrierDeductedCost2', null),
    carrierDeductedCost1LabelError: errors.invoice?.carrierDeductedCost1Label,
    carrierDeductedCost1Error: errors.invoice?.carrierDeductedCost1,
    carrierDeductedCost2LabelError: errors.invoice?.carrierDeductedCost2Label,
    carrierDeductedCost2Error: errors.invoice?.carrierDeductedCost2,
    setFieldValue
  };

  const addedCustomerCosts = {
    customerDetentionAmount: Core.Utils.get(values, 'invoice.customerDetentionAmount', null),
    customerLayoverAmount: Core.Utils.get(values, 'invoice.customerLayoverAmount', null),
    customerReweighAmount: Core.Utils.get(values, 'invoice.customerReweighAmount', null),
    customerReclassAmount: Core.Utils.get(values, 'invoice.customerReclassAmount', null),
    customerLumpersAmount: Core.Utils.get(values, 'invoice.customerLumpersAmount', null),
    customerOtherAmountLabel: Core.Utils.get(values, 'invoice.customerOtherAmountLabel', null),
    customerOtherAmount: Core.Utils.get(values, 'invoice.customerOtherAmount', null),
    customerDetentionAmountError: errors.invoice?.customerDetentionAmount,
    customerLayoverAmountError: errors.invoice?.customerLayoverAmount,
    customerReweighAmountError: errors.invoice?.customerReweighAmount,
    customerReclassAmountError: errors.invoice?.customerReclassAmount,
    customerLumpersAmountError: errors.invoice?.customerLumpersAmount,
    customerOtherAmountLabelError: errors.invoice?.customerOtherAmountLabel,
    customerOtherAmountError: errors.invoice?.customerOtherAmount,
    setFieldValue
  };

  const deductedCustomerCosts = {
    isAce: Core.Utils.get(values, 'isAce'),
    customerDeductedCost1Label: Core.Utils.get(values, 'invoice.customerDeductedCost1Label', null),
    customerDeductedCost1: Core.Utils.get(values, 'invoice.customerDeductedCost1', null),
    customerDeductedCost2Label: Core.Utils.get(values, 'invoice.customerDeductedCost2Label', null),
    customerDeductedCost2: Core.Utils.get(values, 'invoice.customerDeductedCost2', null),
    customerDeductedCost1LabelError: errors.invoice?.customerDeductedCost1Label,
    customerDeductedCost1Error: errors.invoice?.customerDeductedCost1,
    customerDeductedCost2LabelError: errors.invoice?.customerDeductedCost2Label,
    customerDeductedCost2Error: errors.invoice?.customerDeductedCost2,
    setFieldValue
  };

  const financialNotes = {
    invoiceNote: Core.Utils.get(values, 'invoice.invoiceNote', null),
    financialNote: Core.Utils.get(values, 'invoice.financialNote', null),
    invoiceNoteError: errors.invoice?.invoiceNote,
    financialNoteError: errors.invoice?.financialNote,
    setFieldValue
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <AppButton type={'submit'} startIcon={<Save/>} disabled={!(isValid && isDirty)}>Save</AppButton>
          </Grid>
          <Grid item>
            <AppButton type={'button'} startIcon={<Undo/>} color={'warning'} disabled={!isDirty}
                       onClick={handleResetClick}>Reset</AppButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <OverallCosts
            {...overallCostProps}
          />
          <StatusDates
            {...statusDatesProps}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={1}>
          <AddedCarrierCosts
            {...addedCarrierCosts}
          />
          <DeductedCarrierCosts
            {...deductedCarrierCosts}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Grid container spacing={1}>
          <AddedCustomerCosts
            {...addedCustomerCosts}
          />
          <DeductedCustomerCosts
            {...deductedCustomerCosts}
          />
        </Grid>
      </Grid>
      <FinancialNotes
        {...financialNotes}
      />
    </Grid>
  );
};

export default renderFinancialForm;