import React from 'react';
import Grid from '@material-ui/core/Grid';

import ComponentBuilder from "../../../../core/ComponentBuilder";
import AppPhoneInput from "../../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput";
import AppInput from "../../../../core/components/inputs/AppInput/AppMuiInput";
import AppMuiCard from "../../../../core/components/cards/AppCard";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";
import SecureField from "../../permissions/SecureField";

const CarrierContactDetailCard = (props) => {

  const {
    experienceNote,
    repName,
    repPhone,
    repEmail,
    setFieldValue,
    repPhoneError,
    repEmailError
  } = props;
  
  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  const handleCarrierContactFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  return (
    <React.Fragment>
      <AppMuiCard title="Carrier Contact Detail">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'repName'}
                as={AppInput}
                id={'carrierRep'}
                label={'Carrier Rep.'}
                inputProps={{
                  maxLength: 30
                }}
                value={repName || ''}
                onChange={handleCarrierContactFieldChange('repName')}
              />
            </Grid>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'repPhone'}
                as={AppPhoneInput}
                id={'carrierRepPhone'}
                label={'Rep. Phone'}
                value={repPhone || ''}
                onChange={handleCarrierContactFieldChange('repPhone')}
              />
              <AppValidationFailure
                message={repPhoneError}
              />
            </Grid>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'repEmail'}
                as={AppInput}
                id={'carrierRepEmail'}
                label={'Rep. Email'}
                inputProps={{
                  maxLength: 150
                }}
                value={repEmail || ''}
                onChange={handleCarrierContactFieldChange('repEmail')}
              />
              <AppValidationFailure message={repEmailError} />
            </Grid>
            <Grid item xs={12}>
              <SecureField
                pageName={'details'}
                fieldName={'experienceNote'}
                as={AppInput}
                id={'carrierExperienceNotes'}
                label={'Experience Notes'}
                multiline={true}
                rows={'6'}
                value={experienceNote || ''}
                inputProps={{
                  maxLength: 200
                }}
                onChange={handleFieldChange('experienceNote')}
              />
            </Grid>
          </Grid>
        </AppMuiCard>
    </React.Fragment>
  )
};

export default ComponentBuilder
  .wrap(CarrierContactDetailCard)
  .build();