import React from 'react';
import {Link} from 'react-router-dom';

import { Edit, Lock, CheckCircle } from '@material-ui/icons';
import AppMuiTable from '../../../../core/components/AppDenseGrid';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';

const RoleNameCell = ({ rowData: associate }) =>
  associate.roleName;

const CheckmarkCell = (fieldName) => ({ rowData: associate }) =>
  associate[fieldName] ?
    <CheckCircle color={'primary'} fontSize={'small'} /> :
    null;

const FailedAttemptsCell = ({ rowData: associate }) =>
  associate.loginAttempts >= 3 ?
    <Lock color={'error'} fontSize={'small'} /> :
    null;

const ControlsCell = ({ rowData: associate }) =>
  <AppIconButton to={`/admin/manage-users/${associate.id}`} Icon={Edit} component={Link} />

const IconCellWidth = 80;

const GridColumns = [
  {
    title: 'Last Name',
    field: 'lastName',
    styles: {},
    dataType: 'string'
  },
  {
    title: 'First Name',
    field: 'firstName',
    styles: {},
    dataType: 'string'
  },
  {
    title: 'System ID',
    field: 'systemId',
    styles: {
      width: 90,
      textAlign: 'center'
    },
    dataType: 'string'
  },
  {
    title: 'Ext',
    field: 'businessPhoneExt',
    styles: {
      width: 60,
      textAlign: 'center'
    },
    dataType: 'string'
  },
  {
    title: 'Email',
    field: 'emailAddress',
    styles: {},
    dataType: 'string'
  },
  {
    title: 'Role',
    field: 'roleId',
    styles: {
      width: 80,
      textAlign: 'center'
    },
    dataType: 'component',
    component: RoleNameCell
  },
  {
    title: 'Carrier Privilege',
    field: 'hasCarrierPrivilege',
    styles: {
      width: IconCellWidth,
      textAlign: 'center'
    },
    dataType: 'component',
    component: CheckmarkCell('hasCarrierPrivilege')
  },
  {
    title: 'Collection Role',
    field: 'collectionRole',
    styles: {
      width: IconCellWidth,
      textAlign: 'center'
    },
    dataType: 'component',
    component: CheckmarkCell('collectionRole')
  },
  {
    title: 'Account Active',
    field: 'isActive',
    styles: {
      width: IconCellWidth,
      textAlign: 'center'
    },
    dataType: 'component',
    component: CheckmarkCell('isActive')
  },
  {
    title: 'Account Locked',
    field: 'loginAttempts',
    styles: {
      width: IconCellWidth,
      textAlign: 'center'
    },
    dataType: 'component',
    component: FailedAttemptsCell
  },
  {
    field: 'editButtons',
    noSort: true,
    styles: {
      width: IconCellWidth,
      textAlign: 'center'
    },
    dataType: 'component',
    component: ControlsCell
  }
];

const AssociatesTable = (props) => <AppMuiTable columns={GridColumns} {...props} />

export default AssociatesTable;