// Constants for pulling IDs from the database results.
// They must match the name in the table for the app to work.
const ShipmentDocumentTypeNames = {
  SignedRateCon: 'Signed Rate Confirmation',
  POD: 'POD',
  CarrierInvoice: 'Carrier Invoice',
  CarrierInvoicePOD: 'Carrier Invoice & POD',
  CustomerInvoice: 'Customer Invoice',
  Other: 'Other',
};

export default ShipmentDocumentTypeNames;