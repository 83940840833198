import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableBody, TableRow, TableCell } from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  ...theme.overrides.MuiTableRow,
  inActive: {
    background: `${theme.palette.warning.main}!important`
  },
  bold: {
    '& td': {
      fontWeight: 'bold',
      color: `${theme.palette.default.contrastText}!important`
    }
  },
  rowPadding: {
    paddingLeft: '5px!important'
  },
  removeHover: {
    background: 'transparent!important'
  }
}));

const AppTableBody = ({ cellRenderers, cellStyles, data, rowProps=null }) => {
  const classes = useStyle();

  return (<TableBody className={classes.root}>
      {data.map((row, key) => <TableRow className={rowProps ? classes[rowProps(row)] : null} key={key}>
        {
          cellRenderers.map((renderer, index) => {
            return (
              <TableCell
                key={index}
                className={classes.rowPadding}
                style={cellStyles[index]}>
                {renderer(row)}
              </TableCell>
            )
          })
        }
      </TableRow>)
      }
    </TableBody>
  )
}
export default AppTableBody;
