import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Beenhere, Edit } from '@material-ui/icons';

import AppDenseGrid from '../../../core/components/AppDenseGrid/AppDenseGrid';
import AppIconButton from '../../../core/components/AppButton/AppIconButton';

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  coveredCell: {
    color: theme.palette.tgfGreen.main,
    textAlign: 'center'
  },
  centerCells: {
    textAlign: 'center'
  },
  editButton: {
    "&:hover": {
      backgroundColor: "transparent",
      color: '#FFFFFF'
    }
  }
}));

const OpenLoadsListingTable = (props) => {
  const classes = useStyles();
  const {
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    onSelectShipment
  } = props;

  const CoveredCell = (classes) => ({ rowData: openLoadRecord }) =>
    openLoadRecord.shipmentIsCovered && <Beenhere className={classes.coveredCell} />;

  const ControlsCell = ({ rowData: record }) => <AppIconButton
    Icon={Edit}
    tooltip={`Edit ${record.shipmentBolNumber}`}
    onClick={onSelectShipment(record)}
    className={classes.editButton}
  />;

  const GridColumns = [
    {
      title: 'Covered',
      field: 'shipmentIsCovered',
      styles: {
        width: 55,
      },
      dataType: 'component',
      component: CoveredCell(classes)
    },
    {
      title: 'REP',
      field: 'associateSystemId',
      styles: {
        width: 50,
      },
      dataType: 'string',
    },
    {
      title: 'Ship #',
      field: 'shipmentBolNumber',
      styles: {
        width: 65,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
    },
    {
      title: 'Pickup Date',
      field: 'shipmentBolDate',
      styles: {
        width: 85,
      },
      dataType: 'date'
    },
    {
      title: 'Customer Name',
      field: 'customerCompanyName',
      styles: {
        width: 185,
      },
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.customerCompanyId}/details`, record.customerCompanyName])
    },
    {
      title: 'From City',
      field: 'shipperCompanyCity',
      styles: {
        width: 100,
      },
      dataType: 'string',
    },
    {
      title: 'From State',
      field: 'shipperCompanyStateProvince',
      styles: {
        width: 50,
      },
      dataType: 'string',
    },
    {
      title: 'To City',
      styles: {
        width: 100,
      },
      dataType: 'string',
      field: 'consigneeCompanyCity'
    },
    {
      title: 'To State',
      styles: {
        width: 50,
      },
      dataType: 'string',
      field: 'consigneeCompanyStateProvince'
    },
    {
      title: 'Equipment Type',
      field: 'shipmentEquipmentType',
      styles: {
        width: 185,
      },
      dataType: 'string',
    },
    {
      title: 'Cust. Cost',
      field: 'customerCost',
      styles: {
        width: 80,
      },
      dataType: 'currency',
    },
    {
      title: 'Posted For',
      field: 'postedFor',
      styles: {
        width: 80,
      },
      dataType: 'currency',
    },
    {
      title: 'Notes',
      field: 'shipmentOpenLoadNotes',
      styles: {
        width: 225,
      },
      dataType: 'string',
    },
    {
      title: ' ',
      field: '',
      noSort: true,
      styles: {
        width: 45,
      },
      dataType: 'component',
      component: ControlsCell
    },
  ];

  return (
    <AppDenseGrid
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default OpenLoadsListingTable;