import React from 'react';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import {Delete, Settings} from '@material-ui/icons';
import {Box} from '@material-ui/core';
import loadFinancialHealthDataSet from './loadFinancialHealthDataSet';
import PaymentAgeChart from './PaymentAgeChart';
import VolumeChart from './VolumeChart';
import MoneyChart from './MoneyChart';
import {MoneyChartDescriptor, PaymentAgeChartDescriptor, VolumeChartDescriptor} from './SupportTypes';

const CustomerAnalysisChart = (props) => {

  const {
    company,
    settings: chartSettings,
    onDeleteClick,
    onEditClick
  } = props;

  const [data, setData] = React.useState([]);

  React.useEffect(() => {

    loadFinancialHealthDataSet(company.id, chartSettings.timePeriod, chartSettings.startDate, chartSettings.endDate)
      .then((results) => setData(results));

  }, [company.id, chartSettings]);

  const Chart = ChartTypes[chartSettings.type];

  return (
    <div style={{ marginBottom: 20 }}>
      <Box style={{ display: 'flex', p: 1, borderBottom: '1px solid #ddd' }}>
        <Box style={{ p: 1, flexGrow: 1 }}>
          <h2 style={{ margin: '10px 0 0 0' }}>
            {chartSettings.title}
          </h2>
        </Box>
        <Box style={{ p: 1 }}>
          <AppIconButton Icon={Delete} onClick={onDeleteClick} />
          <AppIconButton Icon={Settings} onClick={onEditClick} />
        </Box>
      </Box>
      {
        data?.length > 0 &&
        <Chart settings={chartSettings} company={company} data={data} />
      }
      {
        !(data?.length > 0) &&
        <h4>No data available.</h4>
      }
    </div>
  );
};

const ChartTypes = {
  [MoneyChartDescriptor.id]: MoneyChart,
  [PaymentAgeChartDescriptor.id]: PaymentAgeChart,
  [VolumeChartDescriptor.id]: VolumeChart
}

export default CustomerAnalysisChart;