import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadAssociates = async (shell, loadAllAssociates = false) => {

  const options = {
    filter: {},
    offset: 0,
    limit: 10000,
    sort: []
  };

  if (!loadAllAssociates) {
    options.filter.isActive = true;
  }

  const { count, associates } = await shell.gateway.getAllAssociates(options);

  return {
    type: ActionTypes.Admin.ManageUsers.ListingLoaded,
    associates,
    count
  };
};

export default ActionBuilder
  .for(loadAssociates)
  .build();