import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ButtonGroup, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
  active: {
    textDecoration: 'none',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  }
}
));

const ListingButtonGroup = ({ links }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}
      role="group"
      aria-label="CMR toolbar button group.">
      <ButtonGroup color="primary">
        {
          links.map((link, key) => {
            const activeClassName = link.isActive ? classes.active : classes.buttonLink;
            return (
              <Button 
              component={Link}
              className={activeClassName}
              key={key}
              onClick={link.onClick}
              to={link.url}>
              {link.title}
              </Button>
            )
          })
        }
      </ButtonGroup>
    </Grid>
  )
};

export default ListingButtonGroup;