import * as Redux from 'redux';
import laneLookup from './laneLookup';
import invoiceAging from './invoiceAging';
import customerPayments from './customerPayments';
import customerReport from './customerReport';
import monthlyGrossMargin from './monthlyGrossMargin';
import searchLoads from './searchLoads';
import missingPrimaryContacts from './missingPrimaryContacts';
import missingCreditApp from './missingCreditApp';

export default Redux.combineReducers({
    missingCreditApp,
    customerPayments,
    customerReport,
    invoiceAging,
    laneLookup,
    monthlyGrossMargin,
    missingPrimaryContacts,
    searchLoads
});