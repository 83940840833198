import React from 'react';
import * as D3 from 'd3';
import LineChartTooltip from './LineChartTooltip';
import {
  ChartDimensions,
  TimePeriodFields
} from './SupportTypes';

const DefaultHorizontalScaleFactory = ({ company, chartSettings, data }) => {
  const scale = D3.scaleLinear()
    .domain([0, data.length - 1])
    .range([ChartDimensions.LeftMargin, ChartDimensions.Width]);
  return scale;
};

const DefaultTickFormatter = (timePeriod, data) => (rowIndex) => {
  const row = data[rowIndex];
  return TimePeriodFields.format(timePeriod, row);
};

const DefaultHorizontalAxisFactory = ({ company, chartSettings, data, scale, tickFormatter = DefaultTickFormatter(chartSettings.timePeriod, data) }) => {
  const factory = (g) => g
    .attr('transform', 'translate(0, ' + (ChartDimensions.Height - ChartDimensions.BottomMargin) + ')')
    .call(D3.axisBottom(scale).tickFormat(tickFormatter));
  return factory;
};

const DefaultVerticalScaleFactory = ({ company, chartSettings, data, chartDescriptor }) => {

  const enabledFeatureNames = Array.from(Object.entries(chartSettings.features))
    .filter(([featureName, isEnabled]) => isEnabled)
    .map(([featureName]) => featureName);

  const featureFields = chartDescriptor.fields(enabledFeatureNames, chartSettings.aggregateType);

  const selectValuesFrom = (fields) => (row) =>
    fields.map(fieldName => {
      const value = row[fieldName];
      return value ?
        parseFloat(value) : value;
    });

  const values = data
    .map(selectValuesFrom(featureFields))
    .flat(Infinity);

  const scale = D3.scaleLinear()
    .domain(D3.extent(values))
    .range([ChartDimensions.Height - ChartDimensions.BottomMargin, ChartDimensions.TopMargin]);

  return scale;
};

const DefaultVerticalAxisFactory = ({ company, chartSettings, data, scale }) => {
  const factory = (g) => g
    .attr('transform', 'translate(' + ChartDimensions.LeftMargin + ', 0)')
    .call(D3.axisLeft(scale));

  return factory;
};

const DefaultLinesFactory = ({ company, chartSettings, data, horizontalScale, verticalScale, chartDescriptor }) => {

  const enabledFeatureNames = Array.from(Object.entries(chartSettings.features))
    .filter(([featureName, isEnabled]) => isEnabled)
    .map(([featureName]) => featureName);

  const featureFields = chartDescriptor.fields(enabledFeatureNames, chartSettings.aggregateType);

  return (lineGroup) => {
    for (let i = 0; i < featureFields.length; i++) {
      const featureName = enabledFeatureNames[i];
      lineGroup
        .append('g')
        .append('path')
        .datum(data)
        .attr('fill', 'none')
        .attr('stroke', chartDescriptor.features[featureName].lineColor)
        .attr('stroke-width', 1.5)
        .attr('d', D3.line()
          .x((row, index) => horizontalScale(index))
          .y(row => verticalScale(row[featureFields[i]]))
        );
    }
  };

};

const FinancialHealthLineChart = (props) => {

  const {
    company,
    settings: chartSettings,
    data,
    chartDescriptor,
    horizontalScale = DefaultHorizontalScaleFactory,
    horizontalAxis = DefaultHorizontalAxisFactory,
    verticalScale = DefaultVerticalScaleFactory,
    verticalAxis = DefaultVerticalAxisFactory,
    lines: linesFactory = DefaultLinesFactory,
    finalizer = () => {}
  } = props;

  const chartContainer = React.useRef(null);

  React.useEffect(() => {

    if (!data || data.length === 0 || !chartContainer.current ) {
      return;
    }

    const xScale = horizontalScale({ company, chartSettings, data });
    const xAxisDelegate = horizontalAxis({ company, chartSettings, data, scale: xScale });
    const yScale = verticalScale({ company, chartSettings, data, chartDescriptor });
    const yAxisDelegate = verticalAxis({ company, chartSettings, data, scale: yScale });
    const linesDelegate = linesFactory({ company, chartSettings, data, chartDescriptor, horizontalScale: xScale, verticalScale: yScale });

    const d3Container = D3
      .select(chartContainer.current);

    let chart = d3Container.select('svg');

    if (!chart || chart.size() === 0) {

      chart = d3Container.append('svg');

      chart
        .style('background-color', '#fff')
        .attr('width', ChartDimensions.Width)
        .attr('height', ChartDimensions.Height);
    }

    // Remove the main group if it already exists.
    chart.select('.mainGroup').remove();

    const mainGroup = chart.append('g')
      .classed('mainGroup', true);

    mainGroup.append('g')
      .call(xAxisDelegate)

    mainGroup.append('g')
      .call(yAxisDelegate);

    mainGroup.append('g')
      .call(linesDelegate);

    LineChartTooltip({
      chart,
      chartDescriptor,
      chartSettings,
      horizontalScale: xScale,
      verticalScale: yScale,
      data
    });

    finalizer({ company, chartSettings, data, group: mainGroup, horizontalScale: xScale, verticalScale: yScale });

    // TODO: Do we need to dispose anything D3?
    return () => {};

  }, [chartSettings, company, data, chartDescriptor, finalizer, horizontalScale, horizontalAxis, linesFactory, verticalScale, verticalAxis]);

  return (
    <div ref={chartContainer} />
  );
};

export default FinancialHealthLineChart;