import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Paper,
  Table
} from '@material-ui/core';
import LinkCell from './cells/LinkCell';

import AppTableHead from './includes/AppTableHead';
import AppTableBody from './includes/AppTableBody';
import AppTablePagination from './includes/AppTablePagination';
import AppMuiCard from '../cards/AppCard';
import computeCellStyle from './includes/computeCellStyle';
import AppDateText from '../text/AppDateText';
import AppCurrencyText from '../text/AppCurrencyText';
import AppText from '../text/AppText';
import AppIntegerText from '../text/AppIntegerText';
import AppNumberText from '../text/AppNumberText';
import AppDateTimeText from '../text/AppDateTimeText';
import AppTimeText from '../text/AppTimeText';
import AppPercentText from '../text/AppPercentText';

const EmptyBar = () => null;

const NoDataAvailable = () => {
  return (
    <AppMuiCard>
      No data available.
    </AppMuiCard>
  );
};

const createCellFunction = (column) => {

  const field = column.field;
  const ChildComponent = column.component;

  switch (column.dataType) {
    case 'date':
      return (row) => <AppDateText value={row[field]} />
    case 'date-time':
      return (row) => <AppDateTimeText value={row[field]} />
    case 'currency':
      return (row) => <AppCurrencyText value={row[field]} />
    case 'component':
      return (row) => <ChildComponent rowData={row} />;
    case 'integer':
      return (row) => <AppIntegerText value={row[field]} />;
    case 'percent':
      return (row) => <AppPercentText value={row[field]} />;
    case 'number':
      return (row) => <AppNumberText value={row[field]} />;
    case 'time':
      return (row) => <AppTimeText value={row[field]} />;
    case 'string':
    default:
      return (row) => <AppText value={row[field]} />
  }
};

const useStyle = (width, height) => makeStyles(theme => ({
  root: {},
  container: {
    maxWidth:`calc(100vw - ${width}px)`,
    maxHeight: `calc(100vh - ${height}px)`,
    '& table': {
      tableLayout: 'fixed'
    },
    '& th.MuiTableCell-root': {
      borderRight: `1px solid ${theme.palette.tgfGrey.border}`,
      backgroundColor: theme.palette.tgfGrey.table,
      overflowWrap: 'break-word',
      padding: 2
    },
    '& td.MuiTableCell-root': {
      overflowWrap: 'break-word',
      padding: 2,
      borderRight: `1px solid ${theme.palette.tgfGrey.border}`,
    },
    '& .MuiTableSortLabel-root': {
      display: 'flex'
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.tgfGrey.table
    }
  },
  rowStyle: {
    background: theme.palette.tgfGreen.light
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}))();

const AppDenseGrid = (props) => {

  const {
    width,
    height,
    columns,
    data,
    count,
    headerBarTitle,
    onSort,
    order,
    orderBy,
    onChangePage,
    onChangeRowsPerPage,
    page,
    rowsPerPage,
    rowsPerPageOptions = [10, 20, 50, 100],
    rowProps,
    HeaderPagination = AppTablePagination,
    FooterPagination = AppTablePagination,
    HeaderBar = EmptyBar,
    FooterBar = EmptyBar
  } = props;

  const classes = useStyle(width, height);

  const paginationProps = {
    rowsPerPageOptions,
    count,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    page
  };

  const barProps = {
    data,
    count,
    headerBarTitle
  };

  const handleSort = (index) => onSort ? onSort(columns[index].field) : null;
  const handleDirection = (index, order) => orderBy === columns[index].field ? order : 'asc';

  const cellRenderers = columns.map(createCellFunction);

  const cellStyles = columns.map(column => computeCellStyle(column.styles, column.styles.cell));

  return (
    <Paper className={classes.root}>
      {
        count > 0 ?
          <>
            {onChangePage && <HeaderPagination {...paginationProps} />}
            <TableContainer component={Paper} className={classes.container}>
              <HeaderBar {...barProps} />
              <Table className={classes.table} stickyHeader>
                <AppTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  handleDirection={handleDirection}
                  handleSort={handleSort}
                />
                <AppTableBody
                  cellRenderers={cellRenderers}
                  cellStyles={cellStyles}
                  data={data}
                  rowProps={rowProps}
                />
              </Table>
            </TableContainer>
            <FooterBar {...barProps} />
            {onChangePage && <FooterPagination {...paginationProps} />}
          </> :
          <NoDataAvailable />
      }
    </Paper>
  )
};

AppDenseGrid.Cells = {
  LinkCell
};

const ColumnShape = PropTypes.shape({
  field: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
  dataType: PropTypes.string.isRequired,
  component: PropTypes.func
});

AppDenseGrid.propTypes = {
  columns: PropTypes.arrayOf(ColumnShape).isRequired,
  data: PropTypes.array.isRequired,
  order: PropTypes.string,
  orderBy: PropTypes.string
};

export default AppDenseGrid;