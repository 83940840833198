import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';

import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import LaneLookupListingTable from './includes/LaneLookupListingTable';
import ReportNav from '../ReportNav';
import selectStateProvinces from '../../../hubs/support/selectors/stateProvinces';
import AppDropdown from '../../../core/components/dropdowns/AppDropdown/AppDropdown';
import CrmAssociateComboBox from "../../../crm/components/CrmAssociateComboBox";
import AppMuiCheckbox from "../../../core/components/inputs/AppCheckbox";

const LoadProcessName = 'Reporting.LaneLookup.Loaded';

const LaneLookup = (props) => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);
  const [sort, setSort] = useState([['bolNumber', 'desc']]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('bolNumber');
  const [fromState, setFromState] = useState(null);
  const [toState, setToState] = useState(null);
  const [filterAssociate, setFilterAssociate] = useState(null);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const {
    dispose,
    associate,
    isAce,
    load,
    laneLookupListing,
    laneLookupCount,
    stateProvinces
  } = props;

  useEffect(() => () => dispose(), [])

  useEffect(() => {
    const associateid = isAce ? null : associate.id;

    load(fromState, toState, offset, limit, sort, associateid, filterAssociate, checkboxChecked)
  }, [fromState, toState, offset, limit, sort, associate.id, isAce, load, filterAssociate, checkboxChecked]);

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };
  const handleFromState = (e, value) => {
    setFromState(value.abbreviation);
    setOffset(0);
  }
  const handleToState = (e, value) => {
    setToState(value.abbreviation);
    setOffset(0);
  }

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  // Handles when an Ace selects and agent.
  const onSelectAssociate = (associate) => {
    const associateValue = associate ? associate.id : null;
    setFilterAssociate(associateValue);
    setOffset(0);
  };

  const handleCheckboxChange = () => {
    setCheckboxChecked(checkboxChecked === false);
    setOffset(0);
  }

  const options = [{ abbreviation: null, name: 'All' }, ...stateProvinces];

  return (
    <FullWidthLayout SideNav={ReportNav} title="Lane Look Up/History">
      <Grid container spacing={1} direction="column">
        <Grid item>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} md={2}>
              <AppDropdown
                label="State From"
                id="fromState"
                defaultValue={{ abbreviation: null, name: 'All' }}
                field={'name'}
                fieldIndex={'abbreviation'}
                options={options}
                onChange={handleFromState}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <AppDropdown
                label="State To"
                id="toState"
                defaultValue={{ abbreviation: null, name: 'All' }}
                field={'name'}
                fieldIndex={'abbreviation'}
                options={options}
                onChange={handleToState}
              />
            </Grid>
            {isAce ?
              <Grid item>
                <CrmAssociateComboBox
                  placeholder={'Search agents...'}
                  onSelectAssociate={onSelectAssociate}
                  style={{ minWidth: '250px', maxWidth: '350px' }}
                />
              </Grid>
            :
              <Grid item>
                <AppMuiCheckbox
                  label={"View my shipments only"}
                  checked={checkboxChecked}
                  onChange={handleCheckboxChange}>

                </AppMuiCheckbox>
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item>
          <LaneLookupListingTable
            count={laneLookupCount}
            data={laneLookupListing}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>

      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(LaneLookup)
  .stateToProps((state, ownProps) => {
    return {
      isAce: isAce(state),
      laneLookupListing: state.reporting.laneLookup.listing,
      laneLookupCount: state.reporting.laneLookup.laneLookupCount,
      associate: state.persona.modifyingAssociate,
      stateProvinces: selectStateProvinces(state)
        .map(([abbreviation, name]) => ({ abbreviation, name })),
    }
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(fromState, toState, offset, limit, sort, associateId, filterAssociate, checkboxChecked) {

        const filter = {};

        if (filterAssociate) {
          filter.associateId = filterAssociate;
        }

        if(checkboxChecked) {
          filter.associateId = associateId;
        }


        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.laneLookup.load(fromState, toState, offset, limit, sort, associateId, filter));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },

      async dispose() {
        dispatch(await shell.actions.reporting.laneLookup.dispose());
      }
    }
  })
  .build()