import React from 'react';
import Grid from '@material-ui/core/Grid';

import ComponentBuilder from "../../../../core/ComponentBuilder";
import AppInput from "../../../../core/components/inputs/AppInput/AppMuiInput";
import SecureField from "../../permissions/SecureField";


const GeneralNotesCard = (props) => {

  const {
    note,
    rateConNote,
    ratingRefNumber,
    proNumber,
    allowRatingNote,
    allowRatingRef,
    setFieldValue
  } = props;

  const handleFieldChange = (key) => (e) => {
    setFieldValue(key, e.target.value);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SecureField
            pageName={'details'}
            fieldName={'note'}
            as={AppInput}
            id={'generalNotes'}
            label={'General Notes (Appear on the Bill of Lading and Rate Con)'}
            multiline={true}
            rows={'6'}
            value={note || ''}
            inputProps={{
              maxLength: 850
            }}
            onChange={handleFieldChange('note')}
          />
        </Grid>
        {
          allowRatingNote &&
          <Grid item xs={12}>
            <SecureField
              pageName={'details'}
              fieldName={'rateConNote'}
              as={AppInput}
              id={'rateConfirmationNotes'}
              label={'Rate Con Notes (Appear only on the Rate Con)'}
              multiline={true}
              rows={'6'}
              value={rateConNote || ''}
              inputProps={{
                maxLength: 850
              }}
              onChange={handleFieldChange('rateConNote')}
            />
          </Grid>
        }
        <Grid item xs={12}>
          <SecureField
            pageName={'details'}
            fieldName={'proNumber'}
            as={AppInput}
            id={'proNumber'}
            label={'Tracking #'}
            value={proNumber || ''}
            inputProps={{
              maxLength: 30
            }}
            onChange={handleFieldChange('proNumber')}
          />
        </Grid>
        {
          allowRatingRef &&
          <Grid item xs={12}>
            <SecureField
              pageName={'details'}
              fieldName={'ratingRefNumber'}
              as={AppInput}
              id={'ratingRefNumber'}
              label={'Third Party Shipment #'}
              inputProps={{
                maxLength: 25
              }}
              value={ratingRefNumber || ''}
              onChange={handleFieldChange('ratingRefNumber')}
            />
          </Grid>

          }
          </Grid>
    </React.Fragment>
  )
};

export default ComponentBuilder
  .wrap(GeneralNotesCard)
  .build();