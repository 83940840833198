import React, {useState} from 'react';
import ComponentBuilder from '../../../core/ComponentBuilder';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import {Grid} from '@material-ui/core';
import CarrierRecordNav from "../CarrierRecordNav";
import CarrierHistoryListing from "./includes/CarrierHistoryListing";
import isAce from '../../../hubs/persona/selectors/isAce';
import "./HistoryPage.scss";

const LoadCarrierProcessName = 'Carrier.HistoryPage.CarrierLoad';
const LoadHistoryProcessName = 'Carrier.HistoryPage.CarrierHistoryLoad';

const HistoryPage = (props) => {

  const {
    isAce,
    modifyingAssociate,
    dispose,
    disposeHistory,
    carrier,
    carrierHistory,
    counts,
    loadCarrier,
    loadHistory,
    match
  } = props;

  const mcNumber = match.params.id;

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['shipmentBolNumber', 'desc']]);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('shipmentBolNumber');

  React.useEffect(() => {
    loadCarrier(mcNumber);
    return () => {
      dispose();
    };
  }, [mcNumber, dispose, loadCarrier]);

  React.useEffect(() => {
    loadHistory(mcNumber, offset, limit, sort);
    return () => {
      disposeHistory();
    };
  }, [mcNumber, offset, limit, sort, disposeHistory, loadHistory]);

  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  }

  const title = carrier ?
    `Carrier - History - ${carrier.name} (${mcNumber})` :
    'Carrier - History';

  return (
    <FullWidthLayout SideNav={CarrierRecordNav} title={title} className="carrier-styles">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CarrierHistoryListing
            limitDataVisibility={!isAce}
            associateId={modifyingAssociate.id}
            count={counts}
            data={carrierHistory}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}/>
        </Grid>
      </Grid>
    </FullWidthLayout>
  )
}
export default ComponentBuilder
  .wrap(HistoryPage)
  .stateToProps((state, ownProps) => ({
    isAce: isAce(state),
    modifyingAssociate: state.persona.modifyingAssociate,
    carrier: state.carrier.modification.carrier,
    carrierHistory: state.carrier.modification.history,
    counts: state.carrier.modification.historyCount
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async loadCarrier(mcNumber) {
        dispatch(shell.actions.sys.processStart(LoadCarrierProcessName));
        dispatch(await shell.actions.carrier.modification.loadCarrier(mcNumber));
        dispatch(shell.actions.sys.processComplete(LoadCarrierProcessName));
      },
      async loadHistory(mcNumber, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadHistoryProcessName));
        dispatch(await shell.actions.carrier.modification.loadHistory(mcNumber, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadHistoryProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.modification.dispose())
      },
      async disposeHistory() {
        dispatch(await shell.actions.carrier.modification.disposeHistory());
      }
    }
  })
  .build();