import React from 'react';
import Core from '@atomos/core';
import * as Yup from "yup";
import { Grid } from '@material-ui/core';
import Add from '@material-ui/icons/Add';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import associate from '../../../hubs/persona/selectors/associate';
import ComponentBuilder from "../../../core/ComponentBuilder";
import FormFactor from "../../../core/FormFactor/FormFactor";
import AppDropdown from '../../../core/components/dropdowns/AppDropdown';
import shipmentTrackingPhases from '../../../hubs/support/selectors/shipmentTrackingPhases';

import AppButton from '../../../core/components/AppButton';
import AppDialog from "../../../core/components/AppDialog";
import AppTitleBar from '../../../core/components/AppTitleBar';
import AppValidationFailure from "../../../core/components/AppValidationFailure";
import AppMuiInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';
import ShipmentRecordNav from "../ShipmentRecordNav";
import './LoadTrackingPage.scss';
import When from "../../../core/components/condtionals/When";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';
import SecureAction from '../permissions/SecureAction';
import AppDateTimeText from '../../../core/components/text/AppDateTimeText';

const LoadProcessName = 'LoadTrackingPage.Load';
const SaveProcessName = 'LoadTrackingPage.Save';

const NewNoteSchema = Yup.object().shape({
  note: Yup.string()
    .trim('Note is required.')
    .required('Note is required.')
});

const LoadTrackingPage = (props) => {

  const {
    associateId,
    match,
    shipment = {},
    trackingNotes = [],
    trackingPhases = [],
    load,
    saveCompanyNote,
    sendSnackbarMessage
  } = props;

  const bolNumber = match.params.id && parseInt(match.params.id);

  if (Core.Utils.isUndefined(bolNumber) || bolNumber === 0) {
    throw new Error('BolNUmber url parameter missing.');
  }

  const [isAddingNote, setIsAddingNote] = React.useState(false);

  React.useEffect(() => {
    load(bolNumber);
  }, [bolNumber, load]);

  const handleNewNoteOpen = (e) =>
    setIsAddingNote(true);

  const handleNewNoteClose = (e) =>
    setIsAddingNote(false);

  const handleNewNoteSubmit = (values, formFactor) => {
    const newNote = {
      bolNumber: bolNumber,
      associateId: associateId,
      note: values.note,
      loadTrackingPhaseId: values.selectedPhase.id
    };
    saveCompanyNote(newNote)
      .then(() => {
        setIsAddingNote(false);
        sendSnackbarMessage({ content: 'Tracking note saved.' });
      });
  };

  const newNoteInitialValues = {
    trackingPhases,
    selectedPhase: trackingPhases[0],
    note: '',
    onClose: handleNewNoteClose
  };

  return (
    <FullWidthLayout SideNav={ShipmentRecordNav} className={"shipments-styles"}>
      <Grid container spacing={1}>

        <When condition={!shipment}>
          <MissingOrDeniedShipmentAlert />
        </When>
        <When condition={shipment}>
          <Grid item xs={12}>
            <AppTitleBar title={`Shipments - Tracking Notes - ${bolNumber}`} />
          </Grid>
          <Grid item xs={12}>
            <SecureAction
              pageName={'loadTracking'}
              actionName={'addNote'}
              as={AppButton}
              startIcon={<Add />}
              onClick={handleNewNoteOpen}
            >
              Add Note
            </SecureAction>
          </Grid>
          <Grid item xs={12}>
            <AppMuiSimpleTable
              columns={Columns}
              tablePropMap={makeTableFixed}
              data={trackingNotes}
            />
          </Grid>
        </When>
      </Grid>

      {isAddingNote && <FormFactor
        initialValues={newNoteInitialValues}
        schema={NewNoteSchema}
        onSubmit={handleNewNoteSubmit}>
        {renderNewNoteForm}
      </FormFactor>
      }
    </FullWidthLayout>
  );

};

const makeTableFixed = (baseProps) =>
  Core.Utils.merge({}, baseProps, {
    style: { tableLayout: 'fixed' },
    width: '100%'
  });

const Columns = [
  {
    title: 'Note',
    headerPropMap(index, columns) {
      return { width: '52%' };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { overflowWrap: 'break-word' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.note
    }
  },
  {
    title: 'Phase',
    headerPropMap(index, columns) {
      return { width: '13%' };
    },
    valueMap(note, index, data, columns) {
      return note.phase ?
        note.phase.name :
        '[BLANK]'
    }
  },
  {
    title: 'Associate',
    headerPropMap(index, columns) {
      return { width: '20%', style: { textAlign: 'center' } };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { textAlign: 'center' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.associate ?
        `${note.associate.firstName}  ${note.associate.lastName}` :
        '[BLANK]'
    }
  },
  {
    title: 'Date',
    headerPropMap(index, columns) {
      return { width: '15%', style: { textAlign: 'center' } };
    },
    valueMap(note, index, data, columns) {
      return <AppDateTimeText value={note.createDate} />
    }
  }
];

const renderNewNoteForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {
  const newNoteActions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleTrackingPhaseChange = (e, value) => {
    const trackingPhase = value;

    if (trackingPhase) {
      setFieldValue('trackingPhase', trackingPhase.id);
      setFieldValue('selectedPhase', trackingPhase);
    }
    else {
      setFieldValue('trackingPhase', null);
      setFieldValue('selectedPhase', null);
    }
  };

  const handleNoteChange = (e) =>
    setFieldValue('note', e.target.value);

  return (
    <AppDialog
      title="New Load Tracking Note"
      open={true}
      width='sm'
      onClose={values.onClose}
      actionButtons={newNoteActions}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <AppDropdown
            field={'name'}
            fieldIndex={'id'}
            label="Phase"
            tabIndex={1}
            options={values.trackingPhases}
            value={values.selectedPhase}
            onChange={handleTrackingPhaseChange}
          />
        </Grid>
        <Grid item>
          <AppMuiInput
            autoFocus
            id="companyNote"
            label="Note"
            tabIndex={2}
            rows="10"
            multiline={true}
            inputProps={{
              maxLength: 1000
            }}
            value={values.note}
            onChange={handleNoteChange} />
          <AppValidationFailure message={errors.note} />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

const AscendingLoadTrackingNotesSort = (noteA, noteB) => {
  if (noteA.createDate > noteB.createDate)
    return -1;
  else if (noteB.createDate > noteA.createDate)
    return 1;
  else
    return 0;
};

export default ComponentBuilder
  .wrap(LoadTrackingPage)
  .stateToProps((state, ownProps) => {
    return {
      associateId: Core.Utils.get(associate(state), 'id', null),
      shipment: state.shipment.modification.shipment,
      trackingNotes: state.shipment.modification.shipmentTrackingNotes
        .sort(AscendingLoadTrackingNotesSort),
      trackingPhases: shipmentTrackingPhases(state)
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const shipmentAction = await shell.actions.shipment.modification.loadShipment(bolNumber);

        dispatch(shipmentAction);

        if (shipmentAction.shipment) {
          dispatch(await shell.actions.shipment.modification.loadShipmentTrackingNotes(bolNumber));
        }

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveCompanyNote(note) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.shipment.modification.saveShipmentTrackingNote(note));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();