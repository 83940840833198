import React, { useState } from 'react';
import Core from '@atomos/core';
import * as Yup from "yup";
import { Add } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';

import AppButton from '../../../core/components/AppButton';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import FormFactor from "../../../core/FormFactor/FormFactor";
import LeftNav from '../CarrierNav';

import AppDialog from '../../../core/components/AppDialog';
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';
import "./NotesPage.scss";

const LoadProcessName = 'Carrier.CarrierNote.Load';

const NewNoteSchema = Yup.object().shape({
  note: Yup.string().required('Note is required.')
});

const Columns = [
  {
    title: 'Note',
    headerPropMap(index, columns) {
      return { width: '65%' };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { overflowWrap: 'break-word' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.content
    }
  },
  {
    title: 'Date',
    headerPropMap(index, columns) {
      return { width: '15%', style: { textAlign: 'center' } };
    },
    valueMap(note, index, data, columns) {
      return note.createDate.toMoment().format('L LT');
    }
  },
  {
    title: 'Associate',
    headerPropMap(index, columns) {
      return { width: '20%', style: { textAlign: 'center' } };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { textAlign: 'center' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.associate ?
        `${note.associate.firstName}  ${note.associate.lastName}` :
        '[BLANK]'
    }
  }
];
const renderNewNoteForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {

  const newNoteActions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleNoteChange = (e) => setFieldValue('note', e.target.value);

  return (
    <AppDialog
      title="New Note"
      open={true}
      onClose={values.onClose}
      width='sm'
      actionButtons={newNoteActions}
    >
      <Grid container>
        <Grid item xs={12}>
          <AppInput
            autoFocus
            id="carrierNote"
            rows="10"
            cols="45"
            inputProps={{
              maxLength: 1000
            }}
            multiline={true}
            onChange={handleNoteChange} />
          <AppValidationFailure message={errors.note} />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

const CarrierNotesPage = (props) => {
  const {
    carrier,
    carrierNotes,
    dispose,
    load,
    match,
    saveNote,
    sendSnackbarMessage
  } = props;

  const mcNumber = match.params.id;

  const sortedCarrierNotes = carrierNotes
    .slice()
    .sort(DescendingNoteSort)

  const [isAddingNote, setIsAddingNote] = useState(false);

  React.useEffect(() => {
    load(mcNumber);

    return () => { dispose(); }
  }, [mcNumber, dispose, load]);

  const handleNewNoteClose = (e) =>
    setIsAddingNote(false);

  const newNoteInitialValues = {
    note: '',
    onClose: handleNewNoteClose
  };

  const handleNewNoteSubmit = (values, formFactor) => {
    setIsAddingNote(false);
    saveNote(mcNumber, values.note)
      .then(() => {
        sendSnackbarMessage({ content: 'Note saved.' });
      });
  };

  const handleNewNoteOpen = () => setIsAddingNote(true);

  const makeTableFixed = (baseProps) =>
    Core.Utils.merge({}, baseProps, {
      style: { tableLayout: 'fixed' },
      width: '100%'
    });

  const title = carrier ?
    `Carrier - Notes - ${carrier.name} (${mcNumber})` :
    'Carrier - Notes';

  return (
    <FullWidthLayout SideNav={LeftNav} title={title} className="carrier-styles">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <AppButton startIcon={<Add />} onClick={handleNewNoteOpen}>New Note</AppButton>
        </Grid>
        <Grid item xs={12}>
          <AppMuiSimpleTable
            columns={Columns}
            tablePropMap={makeTableFixed}
            data={sortedCarrierNotes}
          />
        </Grid>
        <Grid item xs={12}>
          {isAddingNote && <FormFactor
            initialValues={newNoteInitialValues}
            schema={NewNoteSchema}
            onSubmit={handleNewNoteSubmit}>
            {renderNewNoteForm}
          </FormFactor>
          }
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

const DescendingNoteSort = (noteA, noteB) => {
  if (noteA.createDate > noteB.createDate)
    return -1;
  else if (noteB.createDate > noteA.createDate) {
    return 1;
  }
  else return 0;
};

export default ComponentBuilder
  .wrap(CarrierNotesPage)
  .stateToProps((state, ownProps) => ({
    carrier: state.carrier.modification.carrier,
    carrierNotes: state.carrier.modification.carrierNotes
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(mcNumber) {

        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const actions = await Promise.all([
          shell.actions.carrier.modification.loadCarrierNotes(mcNumber),
          shell.actions.carrier.modification.loadCarrier(mcNumber)
        ]);
        actions.forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveNote(mcNumber, note) {

        dispatch(shell.actions.sys.processStart(LoadProcessName));

        dispatch(await shell.actions.carrier.modification.saveCarrierNote(mcNumber, note));

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.carrier.modification.dispose())
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();