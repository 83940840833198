import ActionTypes from '../../../ActionTypes';
import ActionBuilder from "../../../ActionBuilder2";

const load = async (shell, options) => {

  const {
    limitToPaidDiscrepancy,
    hideDisputeStatusShipments,
    associateId,
    customerPaidStatusId,
    disputeStatusId,
    collectionAssociateId,
    offset,
    limit,
    sort
  } = options;

  const loadMethodOptions = {
    limitToPaidDiscrepancy,
    ignoreDisputed: hideDisputeStatusShipments,
    associateId,
    customerPaidStatusId,
    disputeStatusId,
    collectionAssociateId,
    offset,
    limit,
    sort
  };

  const { count, shipments } = await shell.gateway.getInvoiceAging(loadMethodOptions);

  return {
    type: ActionTypes.Reporting.InvoiceAging.Loaded,
    shipments,
    count
  };

};

export default ActionBuilder
  .for(load)
  .build();