import {Link} from "react-router-dom";
import React from "react";
import { searchTermMatch } from "./CrmSearchAllComboBox";

// function for hiding individual dropdowns
const handleHideLinks = (classNameToHide) => {
  const linkEls = document.querySelectorAll(classNameToHide);
  linkEls.forEach((el) => {
    el.classList.contains('hidden') ? el.classList.remove('hidden') : el.classList.add('hidden');
  })
}

class RenderShipmentNumbersStrategy {
  render(shipments){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={`bolHeader`} onClick={() => handleHideLinks('.shipmentNumber-link')}>Shipment #</h3>
        {shipments.map((shipment, index) => (
          <Link className={'dropDownLink shipmentNumber-link'} key={`bolNumber${index}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
            #{shipment.bolNumber}
          </Link>
        ))}
      </>
    )
  }
}

class RenderCarrierStrategy {
  render(carriers){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={'carrierHeader'} onClick={() => handleHideLinks('.carrier-link')}>Carriers</h3>
        {carriers.map((carrier, index) => (
          <Link className={'dropDownLink carrier-link'} key={`carrier${index}`} to={`/carrier/${carrier.mcNumber}/compliance`} target="_blank">
            {carrier.mcNumber} / {carrier.name}
          </Link>
        ))}
      </>
    )
  }
}

class RenderCompaniesStrategy {
  render(companies) {
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={'companyHeader'} onClick={() => handleHideLinks('.company-link')}>Companies</h3>
        {companies.map((company, index) => (
          <Link className={'dropDownLink company-link'} key={`company${index}`}
                to={`/address-book/${company.companyId}/details`} target="_blank">
            {company.companyName} ({company.categoryTypeName})
          </Link>
        ))}
      </>
    )
  }
}

class RenderTrackingNumbersStrategy {
  render(shipments){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={'trackingHeader'} onClick={() => handleHideLinks('.tracking-number-link')}>Tracking #</h3>
        {shipments.map((shipment, index) => (
          <Link className={'dropDownLink tracking-number-link'} key={`proNumber${index}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
            <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
            <strong>TRACKING #</strong>{shipment.proNumber}
          </Link>
        ))}
      </>
    )
  }
}

class RenderThirdPartyNumbersStrategy {
  render(shipments){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={'thirdPartHeader'} onClick={() => handleHideLinks('.third-party-number-link')}>Third party shipment #</h3>
        {shipments.map((shipment, index) => (
          <Link className={'dropDownLink third-party-number-link'} key={`ratingRefNumber${index}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
            <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
            <strong>THIRD PTY #</strong>{shipment.ratingRefNumber}
          </Link>
        ))}
      </>
    )
  }
}

class RenderPickupNumbersStrategy {
  render(shipments){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={'pickupHeader'} onClick={() => handleHideLinks('.pickup-number-link')}>Pickup #</h3>
        {shipments.map((shipment, index) => (
          <Link className={'dropDownLink pickup-number-link'} key={`pickupNumber${index}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
            <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
            <strong>PICKUP #</strong>{shipment.pickupNumber}
          </Link>
        ))}
      </>
    )
  }
}

class RenderDeliveryNumbersStrategy {
  render(shipments){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={'deliveryHeader'} onClick={() => handleHideLinks('.delivery-number-link')}>Delivery #</h3>
        {shipments.map((shipment, index) => (
          <Link className={'dropDownLink delivery-number-link'} key={`deliveryNumber${index}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
            <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
            <strong>DELIVERY #</strong>{shipment.deliveryNumber}
          </Link>
        ))}
      </>
    )
  }
}

class RenderReferenceNumbersStrategy {
  render(shipments){
    return (
      <>
        <h3 className={'dropDownCategoryHeader'} key={"refNumHeader"} onClick={() => handleHideLinks('.reference-number-link')}>Additional Ref #</h3>
        {shipments.map((shipment, index) => (
          <React.Fragment key={`refNumFragment${index}`}>
            {shipment.refNum1 && searchTermMatch(shipment.refNum1) ?
            <>
              <Link className={'dropDownLink reference-number-link'} key={`${shipment.refNum1}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
                <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
                <strong>REF #</strong>{shipment.refNum1}
              </Link>
            </> : null}
            {shipment.refNum2 && searchTermMatch(shipment.refNum2) ?
            <>
              <Link className={'dropDownLink reference-number-link'} key={`${shipment.refNum2}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
                <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
                <strong>REF #</strong>{shipment.refNum2}
              </Link>
            </> : null}
            {shipment.refNum3 && searchTermMatch(shipment.refNum3) ?
            <>
              <Link className={'dropDownLink reference-number-link'} key={`${shipment.refNum3}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
                <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
                <strong>REF #</strong>{shipment.refNum3}
              </Link>
            </> : null}
            {shipment.refNum4 && searchTermMatch(shipment.refNum4) ?
            <>
              <Link className={'dropDownLink reference-number-link'} key={`${shipment.refNum4}`} to={`/shipment/${shipment.bolNumber}/details`} target="_blank">
                <p className={'ref-num-bol-number'}>Shipment #{shipment.bolNumber}</p>
                <strong>REF #</strong>{shipment.refNum4}
              </Link>
            </> : null}
          </React.Fragment>
        ))}
      </>
    )
  }
}

class ContentRender
{
  constructor(strategy) {
    this.internalStrategy = strategy
  }
  renderContents(contents){
    return this.internalStrategy.render(contents) ;
  }
}

export class SearchAllRenderFactory {
  static getContentRender (strategyType) {
    switch (strategyType){
      case 'carriers':
        return new ContentRender(new RenderCarrierStrategy());
      case 'shipmentNumbers':
        return new ContentRender(new RenderShipmentNumbersStrategy());
      case 'companies':
        return new ContentRender(new RenderCompaniesStrategy());
      case 'trackingNumbers':
        return new ContentRender(new RenderTrackingNumbersStrategy());
      case 'thirdPartyNumbers':
        return new ContentRender(new RenderThirdPartyNumbersStrategy());
      case 'pickupNumbers':
        return new ContentRender(new RenderPickupNumbersStrategy());
      case 'deliveryNumbers':
        return new ContentRender(new RenderDeliveryNumbersStrategy());
      case 'referenceNumbers':
        return new ContentRender(new RenderReferenceNumbersStrategy());
      default:
        throw 'not a valid strategy';
    }
  }
}