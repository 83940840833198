import React from 'react';
import Business from '@tgf-crm/business';
import {SettingsBackupRestore, CheckCircle, Brightness1, OpenInBrowser, ErrorOutline} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import StatusTypeNames from '../../../../hubs/shipment/StatusTypeNames';
import AppTooltip from "../../../../core/components/AppTooltip";
import AppCurrencyText from '../../../../core/components/text/AppCurrencyText';

const NormalIconStatuses = [
  StatusTypeNames.Hold,
  StatusTypeNames.InvoiceGenerated,
  StatusTypeNames.PaymentsPending,
  StatusTypeNames.CustomerPaid,
  StatusTypeNames.Dispute
];

const makeStatusKey = (statusName) =>
  statusName.replace(' ', '-');

const InvoiceCheckCell = (getChecked, classes) => ({rowData: dashboardRecord}) =>
  getChecked(dashboardRecord) && <CheckCircle className={classes.checkIcon}/>;

const InvoiceAuditCheckCell = (classes) =>
  InvoiceCheckCell((shipment) => Boolean(shipment.invoiceAuditFinalizedDate), classes);

const InvoiceGeneratedCheckCell = (classes) =>
  InvoiceCheckCell((shipment) => shipment.invoiceGenerated, classes);

const InvoiceSentCheckCell = (classes) =>
  InvoiceCheckCell((shipment) => shipment.invoiceSent, classes);

const CustomerPaidCheckCell = (classes) =>
  InvoiceCheckCell((shipment) => shipment.customerPaid, classes);

const NormalStatusIcon = (className) =>
  <Brightness1 className={className}/>;

const ErrorStatusIcon = (className, message) =>
  <AppTooltip title={message}><ErrorOutline className={className}/></AppTooltip>;

const StatusCell = (classes) => ({rowData: dashboardRecord}) => {

  const classKey = makeStatusKey(dashboardRecord.statusName);

  let statusIcon = NormalIconStatuses.includes(dashboardRecord.statusName) ?
    NormalStatusIcon(classes[classKey]) : null;

  if (dashboardRecord.flagRuleMessage) {
    statusIcon = ErrorStatusIcon(classes[classKey], dashboardRecord.flagRuleMessage);
  }

  return statusIcon;
};

const TotalMarginCell = (classes) => ({rowData: dashboardRecord}) => {
  const totalMargin = Business.Shipment.ShipmentMath.grossMargin(dashboardRecord);
  const className = totalMargin < 0 ?
    classes.lessThanZero : null;
  return (
    <AppCurrencyText value={totalMargin} className={className} />
  );
};

const ControlsCell = (onSelectCustomerClick, rowRevertShipmentClickFactory) => ({rowData: dashboardRecord}) => {
  const handleRevertShipmentClick = rowRevertShipmentClickFactory(dashboardRecord);
  return (
    <div style={{textAlign: 'center'}}>
      <AppIconButton Icon={OpenInBrowser} tooltip={'Select Customer'} onClick={() => onSelectCustomerClick(dashboardRecord.customerId)} />
      <AppIconButton Icon={SettingsBackupRestore} tooltip={'Revert Shipment'} disabled={!handleRevertShipmentClick} onClick={handleRevertShipmentClick} />
    </div>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    checkIcon: {
      color: theme.palette.tgfGreen.main
    },
    lessThanZero: {
      color: '#FF0000'
    },
    [makeStatusKey(StatusTypeNames.New)]: { color: theme.palette.black },
    [makeStatusKey(StatusTypeNames.InTransit)]: { color: theme.palette.black },
    [makeStatusKey(StatusTypeNames.AwaitingPod)]: { color: theme.palette.black },
    [makeStatusKey(StatusTypeNames.Delivered)]: { color: theme.palette.black },
    [makeStatusKey(StatusTypeNames.Hold)]: { color: '#459e00' },
    [makeStatusKey(StatusTypeNames.InvoiceGenerated)]: { color: '#df03fc' },
    [makeStatusKey(StatusTypeNames.PaymentsPending)]: { color: '#f00' },
    [makeStatusKey(StatusTypeNames.CustomerPaid)]: { color: '#00ccff' },
    [makeStatusKey(StatusTypeNames.Dispute)]: { color: '#f5f50d' }
  };
});

const DashboardListingTable = (props) => {

  const classes = useStyles();

  const {
    shipmentCount,
    shipments,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    rowRevertShipmentClickFactory,
    onSelectCustomerClick
  } = props;

  const GridColumns = [
    {
      title: '',
      field: '',
      dataType: 'component',
      styles: {
        width: '2%',
        textAlign: 'center',
        paddingLeft: 0
      },
      component: StatusCell(classes)
    },
    {
      title: 'Ship #',
      field: 'shipmentBolNumber',
      dataType: 'component',
      styles: {
        width: '3%',
        textAlign: 'center'
      },
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.shipmentBolNumber}/details`, record.shipmentBolNumber])
    },
    {
      title: 'REP',
      field: 'associateSystemId',
      dataType: 'string',
      styles: {
        width: '3%',
        textAlign: 'center'
      }
    },
    {
      title: 'Carrier Cost',
      field: 'adjustedCarrierCost',
      dataType: 'currency',
      styles: {
        width: '5%',
        textAlign: 'center'
      }
    },
    {
      title: 'Customer Cost',
      field: 'adjustedCustomerCost',
      dataType: 'currency',
      styles: {
        width: '5%',
        textAlign: 'center'
      }
    },
    {
      title: 'Total Margin',
      field: 'totalMargin',
      dataType: 'component',
      styles: {
        width: '5%',
        textAlign: 'center'
      },
      component: TotalMarginCell(classes)
    },
    {
      title: 'Load Type',
      field: 'freightCategoryType',
      dataType: 'string',
      styles: {
        width: '3%',
        textAlign: 'center'
      }
    },
    {
      title: 'Carrier Audited',
      field: 'invoiceAuditFinalizedDate',
      dataType: 'component',
      styles: {
        width: '4%',
        textAlign: 'center'
      },
      component: InvoiceAuditCheckCell(classes)
    },
    {
      title: 'Carrier Name',
      field: 'carrierName',
      dataType: 'component',
      styles: {
        width: '11%'
      },
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/carrier/${record.carrierMcNumber}/details`, record.carrierName])
    },
    {
      title: 'Third Party',
      field: 'thirdPartyName',
      dataType: 'string',
      styles: {
        width: '8%'
      },
    },
    {
      title: 'Pickup Date',
      field: 'shipmentBolDate',
      dataType: 'date',
      styles: {
        width: '5%',
        textAlign: 'center'
      }
    },
    {
      title: 'Locked Date',
      field: 'invoiceActualDeliveryDate',
      dataType: 'date',
      styles: {
        width: '5%',
        textAlign: 'center'
      }
    },
    {
      title: 'Customer Name',
      field: 'customerName',
      dataType: 'component',
      styles: {
        width: '14%'
      },
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.customerId}/details`, record.customerName])
    },
    {
      title: 'Inv. Gen.',
      field: 'invoiceGenerated',
      dataType: 'component',
      styles: {
        width: '4%',
        textAlign: 'center'
      },
      component: InvoiceGeneratedCheckCell(classes)
    },
    {
      title: 'Inv. Sent',
      field: 'invoiceSent',
      dataType: 'component',
      styles: {
        width: '3%',
        textAlign: 'center'

      },
      component: InvoiceSentCheckCell(classes)
    },
    {
      title: 'Inv. Sent Date',
      field: 'invoiceSentDate',
      dataType: 'date',
      styles: {
        width: '7%',
        textAlign: 'center'
      }
    },
    {
      title: 'Cust Paid',
      field: 'customerPaid',
      dataType: 'component',
      styles: {
        width: '4%',
        textAlign: 'center'
      },
      component: CustomerPaidCheckCell(classes)
    },
    {
      title: '',
      field: '',
      noSort: true,
      dataType: 'component',
      styles: {
        width: '6%',
        textAlign: 'center'
      },
      component: ControlsCell(onSelectCustomerClick, rowRevertShipmentClickFactory)
    }
  ];

  return (
    <AppDenseGrid
      count={shipmentCount}
      data={shipments || []}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default DashboardListingTable;