import * as Redux from 'redux';

import addressBookHub from '../../hubs/addressBook';
import adminHub from '../../hubs/admin';
// import carrierDirectoryHub from '../../hubs/carrierDirectory';
import carrierHub from '../../hubs/carrier';
import commissionHub from '../../hubs/commission';
import dashboardHub from '../../hubs/dashboard';
import personaHub from '../../hubs/persona';
import shipmentHub from '../../hubs/shipment';
import supportHub from '../../hubs/support';
import sysHub from '../../hubs/sys';
import reporting from '../../hubs/reporting';

const createStateStoreReducers = (config) => {
  return Redux.combineReducers({
    addressBook: addressBookHub.reducer,
    admin: adminHub.reducer,
    carrier: carrierHub.reducer,
    commission: commissionHub.reducer,
    dashboard: dashboardHub.reducer,
    // carrierDirectory: carrierDirectoryHub.hubReducer,
    persona: personaHub.reducer,
    reporting: reporting.reducer,
    shipment: shipmentHub.reducer,
    support: supportHub.reducer,
    sys: sysHub.reducer
  });
};

export default createStateStoreReducers;