import React from 'react';
import {Grid} from '@material-ui/core';
import {Block, LockOpen, Save, Undo} from '@material-ui/icons';
import AppButton from '../../../../core/components/AppButton/AppMuiButton';
import FormFactorSlice from '../../../../core/FormFactor/FormFactorSlice';

const ButtonBar = (props) => {

  const {
    onUnlockClick,
    onTerminateSessionClick
  } = props;

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item>
        <FormFactorSlice slices={['isDirty', 'isValid']}>
          {
            ([isDirty, isValid]) => {
              return (
                <AppButton
                  startIcon={<Save />}
                  type={'submit'}
                  disabled={!(isValid && isDirty)}>
                  Save
                </AppButton>
              );
            }
          }
        </FormFactorSlice>
      </Grid>
      <Grid item>
        <FormFactorSlice slices={['isDirty', 'resetForm']}>
          {
            ([isDirty, resetForm]) =>
              <AppButton
                startIcon={<Undo />}
                disabled={!isDirty}
                color="warning"
                onClick={() => resetForm()}
              >
                Reset
              </AppButton>
          }
        </FormFactorSlice>
      </Grid>
      <Grid item>
        <AppButton
          startIcon={<LockOpen />}
          disabled={!onUnlockClick}
          color={'error'}
          onClick={onUnlockClick}
        >
          Unlock Account
        </AppButton>
      </Grid>
      <Grid item>
        <AppButton
          startIcon={<Block />}
          color={'error'}
          onClick={onTerminateSessionClick}
        >
          Log Out
        </AppButton>
      </Grid>
    </Grid>
  )
};

export default ButtonBar;