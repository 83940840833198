import React from 'react';
import Core from '@atomos/core';
import { Grid } from '@material-ui/core';
import * as Yup from 'yup';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FormFactor from '../../../core/FormFactor/FormFactor';
import renderFinancialForm from './renderFinancialForm';
import isAce from '../../../hubs/persona/selectors/isAce';
import trimNullifyObject from '../../../core/utils/trimNullifyObject';
import ShipmentRecordNav from '../ShipmentRecordNav';
import When from "../../../core/components/condtionals/When";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';
import StatusTypeNames from '../../../hubs/shipment/StatusTypeNames';
import FinancialFormRules from './FinancialFormRules';
import "./financialPage.scss";

const LoadProcessName = 'Shipment.FinancialPage.Load';
const SaveProcessName = 'Shipment.FinancialPage.Save';

// Help method to null out zero value amounts.
// This avoids errors in validation for any records
// that were stored with zeros.  Plus the UI looks cleaner.
const nullifyAmount = value =>
  Core.Utils.isNumber(value) && value === 0 ?
    null : value;

const FinancialPage = (props) => {

  const {
    dispose,
    match,
    invoice,
    shipment,
    shipmentCustomer,
    shipmentStatusTypes,
    isAce,
    load,
    saveInvoice,
    sendSnackbarMessage
  } = props;

  const bolNumber = match.params.id !== 'new' ?
    parseInt(match.params.id) : undefined;

  React.useEffect(() => {
    load(bolNumber);

    return () => dispose();
  }, [bolNumber, dispose, load]);

  const custPaidStatus = shipmentStatusTypes.find(t => t.name === StatusTypeNames.CustomerPaid);
  const customerHasPaid = shipment?.statusId === custPaidStatus.id ||
    Boolean(invoice?.customerPaidDate);

  const initialValues = {
    isAce,
    invoice: {
      ...invoice,
      detentionAmount: nullifyAmount(invoice?.detentionAmount),
      layoverAmount: nullifyAmount(invoice?.layoverAmount),
      reweighAdjustmentAmount: nullifyAmount(invoice?.reweighAdjustmentAmount),
      reclassAdjustmentAmount: nullifyAmount(invoice?.reclassAdjustmentAmount),
      carrierLumpersAmount: nullifyAmount(invoice?.carrierLumpersAmount),
      carrierOtherAmount: nullifyAmount(invoice?.carrierOtherAmount),
      carrierDeductedCost1: nullifyAmount(invoice?.carrierDeductedCost1),
      carrierDeductedCost2: nullifyAmount(invoice?.carrierDeductedCost2),
      customerDetentionAmount: nullifyAmount(invoice?.customerDetentionAmount),
      customerLayoverAmount: nullifyAmount(invoice?.customerLayoverAmount),
      customerReweighAmount: nullifyAmount(invoice?.customerReweighAmount),
      customerReclassAmount: nullifyAmount(invoice?.customerReclassAmount),
      customerLumpersAmount: nullifyAmount(invoice?.customerLumpersAmount),
      customerOtherAmount: nullifyAmount(invoice?.customerOtherAmount),
      customerDeductedCost1: nullifyAmount(invoice?.customerDeductedCost1),
      customerDeductedCost2: nullifyAmount(invoice?.customerDeductedCost2)
    },
    customerHasPaid,
    shipment,
    shipmentCustomer
  };

  const handleSubmit = (values, formFactor) => {
    saveInvoice(trimNullifyObject(values.invoice))
      .then(() => {
        sendSnackbarMessage({ content: 'Shipment financials saved.' });
      });
  };

  return (
    <FullWidthLayout SideNav={ShipmentRecordNav} title={`Shipments - Financial - ${bolNumber}`} className={"shipments-styles"}>
      <Grid container spacing={1}>

        <When condition={!shipment}>
          <MissingOrDeniedShipmentAlert />
        </When>

        <When condition={shipment}>
          <Grid item xs={12}>
            <FormFactor
              initialValues={initialValues}
              schema={InvoiceFormSchema}
              rules={FinancialFormRules}
              onSubmit={handleSubmit}>
              {renderFinancialForm}
            </FormFactor>
          </Grid>
        </When>
      </Grid>
    </FullWidthLayout>
  );
};

const InvoiceFormSchema = Yup.lazy(values => {

  const invoiceSchemaShape = {
    carrierCost: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerCost: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    adjustedCustomerCost: Yup.number()
      .typeError('Must be a number.')
      .nullable(),
    detentionAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    layoverAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    reweighAdjustmentAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    reclassAdjustmentAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    carrierLumpersAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    carrierOtherAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    carrierDeductedCost1: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    carrierDeductedCost2: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerDetentionAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerLayoverAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerReweighAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerReclassAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerLumpersAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerOtherAmount: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerDeductedCost1: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.'),
    customerDeductedCost2: Yup.number()
      .typeError('Must be a number.')
      .nullable()
      .min(0, 'Cannot be negative value.')
  };

  if (values.invoice.carrierOtherAmount) {
    invoiceSchemaShape.carrierOtherAmountLabel = Yup.string()
      .nullable()
      .required('Label is required.')
  }

  if (values.invoice.carrierDeductedCost1) {
    invoiceSchemaShape.carrierDeductedCost1Label = Yup.string()
      .nullable()
      .required('Label is required.')
  }

  if (values.invoice.carrierDeductedCost2) {
    invoiceSchemaShape.carrierDeductedCost2Label = Yup.string()
      .nullable()
      .required('Label is required.')
  }

  if (values.invoice.customerOtherAmount) {
    invoiceSchemaShape.customerOtherAmountLabel = Yup.string()
      .nullable()
      .required('Label is required.')
  }

  if (values.invoice.customerDeductedCost1) {
    invoiceSchemaShape.customerDeductedCost1Label = Yup.string()
      .nullable()
      .required('Label is required.')
  }

  if (values.invoice.customerDeductedCost2) {
    invoiceSchemaShape.customerDeductedCost2Label = Yup.string()
      .nullable()
      .required('Label is required.')
  }

  return Yup.object().shape({
    invoice: Yup.object().shape(invoiceSchemaShape)
  });
});

export default ComponentBuilder
  .wrap(FinancialPage)
  .stateToProps((state, ownProps) => ({
    invoice: state.shipment.modification.shipmentInvoice,
    shipment: state.shipment.modification.shipment,
    shipmentCustomer: state.shipment.modification.shipmentCompanies.customer,
    shipmentStatusTypes: state.support.shipmentStatusTypes,
    isAce: isAce(state)
  }))
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        let actions = await Promise.all([
          shell.actions.shipment.modification.loadShipment(bolNumber),
          shell.actions.shipment.modification.loadShipmentInvoice(bolNumber)
        ]);

        const loadShipmentAction = actions[0];

        if (loadShipmentAction.shipment) {
          actions = actions.concat(await Promise.all([
            shell.actions.shipment.modification.loadShipmentCompany('customer', loadShipmentAction.shipment.customerId)
          ]));
        }

        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveInvoice(invoice) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.shipment.modification.saveShipmentInvoice(invoice));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.modification.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();