import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, ButtonGroup } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonLink: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.tgfGrey.light,
    },
  },
  active: {
    textDecoration: 'none',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  }
}));

const AppDocumentFilters = ({ documentTypes, filterItem, onFilter }) =>{
  const classes = useStyles();

  return (
  <Grid container direction="column" alignItems="center" justify="center">
    <Grid item>
      <ButtonGroup>
        <Button
          key={'All'}
          className={filterItem === 0 ? classes.active : classes.buttonLink}
          onClick={() => onFilter(0)}>
          All
          </Button>
        {
          documentTypes.map((docType, index) => {
            return (
              <Button
                key={index}
                className={filterItem === docType.id ? classes.active : classes.buttonLink}
                onClick={() => onFilter(docType.id)}
              >
                {docType.name}
              </Button>)
          })
        }

      </ButtonGroup>
    </Grid>
  </Grid>
)
}
export default AppDocumentFilters;
