const browserDetect=() =>
{
    let sBrowser = "unknown";
    let browserType;
    let sUsrAg;

    browserType = navigator.userAgent;

    if (browserType.indexOf("Firefox") > -1) {
        sBrowser = "Mozilla Firefox";
    } else if (browserType.indexOf("SamsungBrowser") > -1) {
        sBrowser = "Samsung Internet";
    } else if (browserType.indexOf("Opera") > -1) {
        sBrowser = "Opera";
    } else if (browserType.indexOf("Trident") > -1) {
        sBrowser = "Microsoft Internet Explorer";
    } else if (browserType.indexOf("Edge") > -1) {
        sBrowser = "Microsoft Edge";
    } else if (browserType.indexOf("Chrome") > -1) {
        sBrowser = "Google Chrome or Chromium";
    } else if (browserType.indexOf("Safari") > -1) {
        sBrowser = "Apple Safari";
    } else {
        sBrowser = "unknown";
    }
    return sBrowser;
}



export default browserDetect;