import React, { useState } from 'react';
import {
  AccountBalance,
  Assessment,
  DateRange,
  Home,
  Pageview,
  ContactPhone,
  Receipt,
  Timeline,
  PersonAddDisabled
} from '@material-ui/icons';

import ComponentBuilder from '../../core/ComponentBuilder';
import AppSideBar from '../../core/components/AppSideBar';
import isJack from '../../hubs/persona/selectors/isJack';

const ReportUrl = '/reports';

const BaseLinks = [
  { title: 'Reports', icon: Home, key: 'reports', href: `${ReportUrl}` },
  { title: 'Customer Report', icon: Assessment, key: 'customer-report', href: `${ReportUrl}/customer-report` },
  { title: 'Credit App on File', icon: PersonAddDisabled, key: 'missing-credit-app', href: `${ReportUrl}/missing-credit-app` },
  { title: 'Lane Look Up/History', icon: Timeline, key: 'lane-lookup', href: `${ReportUrl}/lane-lookup` },
  { title: 'Invoice Aging', icon: Receipt, key: 'invoice-aging', href: `${ReportUrl}/invoice-aging` },
  { title: 'Invoice Aging Summary', icon: Receipt, key: 'invoice-aging-summary', href: `${ReportUrl}/invoice-aging-summary` },
  { title: 'Search Loads', icon: Pageview, key: 'search-loads', href: `${ReportUrl}/search-loads` },
  { title: 'Missing Primary Contacts', icon: ContactPhone, key: 'missing-primary-contacts', href: `${ReportUrl}/missing-primary-contacts` }
];

const CommissionLinks = [
  { title: 'Commission Center', icon: AccountBalance, key: 'commission-center', href: `${ReportUrl}/commission-center` },
  { title: 'Monthly GM Report', icon: DateRange, key: 'monthly-gross-margin', href: `${ReportUrl}/monthly-gross-margin` },
];


const Wrapper = (props) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppSideBar menuList={props.links || []} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />
  );
};

export default ComponentBuilder
  .wrap(Wrapper)
  .stateToProps((state, ownProps) => {
    return {
      links: BaseLinks.concat(!isJack(state) ? CommissionLinks : [])
    };
  })
  .build();