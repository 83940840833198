import StatusTypeNames from '../../../hubs/shipment/StatusTypeNames';
import Permissions from './Permissions';
import DetailsPagePermissions from './DetailsPagePermissions';
import LoadTrackingPagePermissions from './LoadTrackingPagePermissions';
import DocumentsPagePermissions from './DocumentsPagePermissions';
import FinancialPagePermissions from './FinancialPagePermissions';

const AnyElementType = Symbol('AnyElementType');
const AnyElementName = Symbol('AnyElementName');

const InTransitExceptions = [
  [DetailsPagePermissions, 'field', 'experienceNote'],
  [DetailsPagePermissions, 'field', 'rateConNote'],
  [DetailsPagePermissions, 'field', 'estimatedDeliveryDate'],
  [DetailsPagePermissions, 'field', 'deliveryTime'],
  [DetailsPagePermissions, 'field', 'proNumber'],
  [DetailsPagePermissions, 'field', 'ratingRefNumber'],
  [LoadTrackingPagePermissions, AnyElementType, AnyElementName],
  [DocumentsPagePermissions, 'field', 'documentType'],
  [DocumentsPagePermissions, 'field', 'description'],
  [DocumentsPagePermissions, 'action', 'uploadDocument'],
  [FinancialPagePermissions, 'field', 'detentionAmount'],
  [FinancialPagePermissions, 'field', 'layoverAmount'],
  [FinancialPagePermissions, 'field', 'reweighAdjustmentAmount'],
  [FinancialPagePermissions, 'field', 'reclassAdjustmentAmount'],
  [FinancialPagePermissions, 'field', 'carrierLumpersAmount'],
  [FinancialPagePermissions, 'field', 'carrierOtherAmountLabel'],
  [FinancialPagePermissions, 'field', 'carrierOtherAmount'],
  [FinancialPagePermissions, 'field', 'customerDetentionAmount'],
  [FinancialPagePermissions, 'field', 'customerLayoverAmount'],
  [FinancialPagePermissions, 'field', 'customerReweighAmount'],
  [FinancialPagePermissions, 'field', 'customerReclassAmount'],
  [FinancialPagePermissions, 'field', 'customerLumpersAmount'],
  [FinancialPagePermissions, 'field', 'customerOtherAmountLabel'],
  [FinancialPagePermissions, 'field', 'customerOtherAmount'],
  [FinancialPagePermissions, 'field', 'invoiceNote'],
  [FinancialPagePermissions, 'field', 'financialNote']
];

const AwaitingPodDeliveredExceptions = [
  [DetailsPagePermissions, 'field', 'experienceNote'],
  [LoadTrackingPagePermissions, AnyElementType, AnyElementName],
  [DocumentsPagePermissions, 'field', 'documentType'],
  [DocumentsPagePermissions, 'field', 'description'],
  [DocumentsPagePermissions, 'action', 'uploadDocument'],
  [FinancialPagePermissions, 'field', 'detentionAmount'],
  [FinancialPagePermissions, 'field', 'layoverAmount'],
  [FinancialPagePermissions, 'field', 'reweighAdjustmentAmount'],
  [FinancialPagePermissions, 'field', 'reclassAdjustmentAmount'],
  [FinancialPagePermissions, 'field', 'carrierLumpersAmount'],
  [FinancialPagePermissions, 'field', 'carrierOtherAmountLabel'],
  [FinancialPagePermissions, 'field', 'carrierOtherAmount'],
  [FinancialPagePermissions, 'field', 'customerDetentionAmount'],
  [FinancialPagePermissions, 'field', 'customerLayoverAmount'],
  [FinancialPagePermissions, 'field', 'customerReweighAmount'],
  [FinancialPagePermissions, 'field', 'customerReclassAmount'],
  [FinancialPagePermissions, 'field', 'customerLumpersAmount'],
  [FinancialPagePermissions, 'field', 'customerOtherAmountLabel'],
  [FinancialPagePermissions, 'field', 'customerOtherAmount'],
  [FinancialPagePermissions, 'field', 'invoiceNote'],
  [FinancialPagePermissions, 'field', 'financialNote']
];

const BackOfficeStatuses = [
  StatusTypeNames.Locked,
  StatusTypeNames.Hold,
  StatusTypeNames.InvoiceGenerated,
  StatusTypeNames.PaymentsPending,
  StatusTypeNames.CustomerPaid,
  StatusTypeNames.Dispute
];

const BackOfficeStatusExceptions = [
  [LoadTrackingPagePermissions, AnyElementType, AnyElementName],
  [DocumentsPagePermissions, 'field', 'documentType'],
  [DocumentsPagePermissions, 'field', 'description'],
  [DocumentsPagePermissions, 'action', 'uploadDocument']
];

const Deciders = [

  // Initialize all actions to None and fields to View.
  (statusName, permissionSet, elementType, elementName, currentPermission) => {
    return elementType === 'field' ?
      Permissions.View : Permissions.None;
  },

  // 1. All fields and actions are actionable when New.
  (statusName, permissionSet, elementType, elementName, currentPermission) => {
    return statusName === StatusTypeNames.New ?
      Permissions.Action : currentPermission;
  },

  // 2. All fields and actions are unactionable when In Transit with exceptions:
  (statusName, permissionSet, elementType, elementName, currentPermission) => {
    return statusName === StatusTypeNames.InTransit &&
      InTransitExceptions.some(([permSet, eType, eName]) => {
        return permSet === permissionSet &&
          (eType === AnyElementType || eType === elementType) &&
          (eName === AnyElementName || eName === elementName);
      }) ?
        Permissions.Action : currentPermission;
  },

  // 3. All fields and actions are unactionable when Awaiting POD or Delivered with exceptions:
  (statusName, permissionSet, elementType, elementName, currentPermission) => {
    return [StatusTypeNames.AwaitingPod, StatusTypeNames.Delivered].includes(statusName) &&
      AwaitingPodDeliveredExceptions.some(([permSet, eType, eName]) => {
        return permSet === permissionSet &&
          (eType === AnyElementType || eType === elementType) &&
          (eName === AnyElementName || eName === elementName);
      }) ?
        Permissions.Action : currentPermission;
  },

  // 4. All fields and actions are unactionable when Locked, Hold, Invoice Generated, Payments Pending, Customer Paid, Dispute with exceptions:
  (statusName, permissionSet, elementType, elementName, currentPermission) => {
    return BackOfficeStatuses.includes(statusName) &&
      BackOfficeStatusExceptions.some(([permSet, eType, eName]) => {
        return permSet === permissionSet &&
          (eType === AnyElementType || eType === elementType) &&
          (eName === AnyElementName || eName === elementName);
      }) ?
        Permissions.Action : currentPermission;
  },

];


// 2. All fields and actions are unactionable when

export default Deciders;