import React from "react";
import { Grid } from "@material-ui/core";

import AppAlertStatic from "../../../../core/components/AppAlertStatic";
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppDialog from "../../../../core/components/AppDialog";
import AppNumericInput from "../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput";
import AppValidationFailure from "../../../../core/components/AppValidationFailure";

const renderCompleteConfirmation = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {
  const actions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid
    }
  ];

  const handleFieldChange = (key) => (e) =>
    setFieldValue(key, e.target.value);

  const handleRangeChange = (e, value) => {
    const rangeType = value;

    if (rangeType) {
      setFieldValue('typeId', rangeType.id);
      setFieldValue('selectedRangeValue', rangeType);
    }
    else {
      setFieldValue('typeId', null);
      setFieldValue('selectedRangeValue', null);
    }

    if (!rangeType.requiresEstimatedValue)
      setFieldValue('confirmedValue', 0);

  };

  return (
    <AppDialog
      title='Truckload Value Confirmation'
      open={true}
      width="sm"
      onClose={values.onClose}
      actionButtons={actions}
    >
      <Grid container spacing={2} direction="column">
        <Grid item>
          <AppAlertStatic title="Select the range the applies.">
            If the value is over $100,000 you will need to enter an estimated value.
          </AppAlertStatic>
        </Grid>
        <Grid item>
          <AppDropdown
            label="Range"
            field={'name'}
            fieldIndex={'id'}
            options={values.rangeType}
            value={values.selectedRangeValue}
            onChange={handleRangeChange}
          />
          <AppValidationFailure message={errors.selectedRangeValue} />
        </Grid>
        {values.selectedRangeValue.requiresEstimatedValue &&
          <Grid item>
            <label>Estimated Value</label>
            <AppNumericInput
              decimalScale={0}
              value={values.confirmedValue}
              onChange={handleFieldChange('confirmedValue')}
            />
            <AppValidationFailure message={errors.confirmedValue} />
          </Grid>
        }
        <Grid item>
          <AppAlertStatic color="warning" title="REMINDER">
            The carrier you are tendering the load for must have cargo insurance that a) meets or exceeds the value of this shipment, b) purchased additional insurance equal to the cargo’s value, or c) have on file an acknowledgment by the customer that they are moving this load with less insurance than the stated value and accepting the risk. By assigning a carrier, you are assuming responsibility and acknowledging that the value of the cargo is protected with one of these three measures.
          </AppAlertStatic>
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default renderCompleteConfirmation;