import React from 'react';
import Core from '@atomos/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import FormFactor from '../../../core/FormFactor/FormFactor';
import * as Yup from 'yup';
import renderAuditForm from './renderAuditForm';
import InvoiceAuditNotes from './includes/InvoiceAuditNotes';
import selectAssociate from '../../../hubs/persona/selectors/associate';
import ShipmentRecordNav from "../ShipmentRecordNav";
import When from "../../../core/components/condtionals/When";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';
import AppDialog from "../../../core/components/AppDialog/AppMuiDialog";
import "./AuditingPage.scss";
const LoadProcessName = 'Shipment.AuditingPage.Load';
const SaveProcessName = 'Shipment.AuditingPage.Save';
const SaveNoteProcessName = 'Shipment.AuditingPage.SaveNote';

const AuditingPage = (props) => {

  const {
    match,
    invoice,
    shipment,
    associate,
    invoiceAuditNotes,
    isAce,
    load,
    saveInvoice,
    saveInvoiceAuditNote,
    getFactoringCompanies,
    dispose,
    sendSnackbarMessage
  } = props;

  const bolNumber = match.params.id ?
    parseInt(match.params.id) : null;

  const [finalizeAudit, setFinalizeAudit] = React.useState(false);
  const [revertFinalizeAudit, setRevertFinalizeAudit] = React.useState(false);

  React.useEffect(() => {
    load(bolNumber)
    return () => dispose();
  }, [bolNumber, dispose, load])

  const handleSubmit = (values, formFactor) => {
    const updatedInvoice = Core.Utils.merge({}, invoice, {
      invoicedAmount: values.invoicedAmount,
      auditDate: values.invoiceAuditDate,
      invoiceDueDate: values.invoiceDueDate,
      remitToName: values.remitToName,
      remitToAddress1: values.remitToAddress1,
      remitToAddress2: values.remitToAddress2,
      remitToCity: values.remitToCity,
      remitToStateProvince: values.remitToStateProvince,
      remitToPostalCode: values.remitToPostalCode,
      auditFinalizedDate: values.auditFinalizedDate,
      finalizingAssociateId: values.finalizingAssociateId
    });

    saveInvoice(updatedInvoice)
      .then(() => {
        sendSnackbarMessage({ content: 'Auditing saved.' });
      })
  };

  const handleFinalizeAuditClick = () => {
    setFinalizeAudit(true);
  };

  const handleRevertFinalizeAuditClick = () => {
    setRevertFinalizeAudit(true);
  };

  const handleFinalizeAuditConfirm = () => {
    setFinalizeAudit(false);
    invoice.auditFinalizedDate = new Date();
    invoice.finalizingAssociateId = associate.id;
    invoice.finalizingAssociate = associate;
    saveInvoice(invoice)
      .then(() => {
        sendSnackbarMessage({ content: 'Audit finalized.' });
      });
  };

  const handleRevertFinalizeAuditConfirm = () => {
    setRevertFinalizeAudit(false);
    invoice.auditFinalizedDate = null;
    invoice.finalizingAssociateId = null;
    invoice.finalizingAssociate = null;
    saveInvoice(invoice)
      .then(() => {
        sendSnackbarMessage({ content: 'Audit reverted.' });
      });
  };

  const handleFinalizeAuditClose = (e) =>
    setFinalizeAudit(false);

  const handleRevertFinalizeAuditClose = (e) =>
    setRevertFinalizeAudit(false);

  const initialValues = {
    bolNumber,
    isAce,
    markAsFinalized: false,
    invoicedAmount: Core.Utils.get(invoice, 'invoicedAmount', null),
    invoiceAuditDate: Core.Utils.get(invoice, 'auditDate', null),
    invoiceDueDate: Core.Utils.get(invoice, 'invoiceDueDate', null),
    finalizingAssociateId: Core.Utils.get(invoice, 'finalizingAssociateId', null),
    finalizingAssociate: Core.Utils.get(invoice, 'finalizingAssociate', null),
    auditFinalizedDate: Core.Utils.get(invoice, 'auditFinalizedDate', null),
    remitToName: Core.Utils.get(invoice, 'remitToName', null),
    remitToAddress1: Core.Utils.get(invoice, 'remitToAddress1', null),
    remitToAddress2: Core.Utils.get(invoice, 'remitToAddress2', null),
    remitToCity: Core.Utils.get(invoice, 'remitToCity', null),
    remitToStateProvince: Core.Utils.get(invoice, 'remitToStateProvince', null),
    remitToPostalCode: Core.Utils.get(invoice, 'remitToPostalCode', null),
    onFinalizedAuditClick: handleFinalizeAuditClick,
    onRevertFinalizeAuditClick: handleRevertFinalizeAuditClick,
    associate,
    shipment,
    invoice,
    getFactoringCompanies
  };

  const invoiceAuditNotesProps = {
    invoiceAuditNotes: invoiceAuditNotes
      .slice()
      .sort(NoteSort),
    onNoteAdded(noteContent) {
      const note = {
        id: 0,
        bolNumber,
        associateId: associate.id,
        content: noteContent,
        createDate: new Date()
      };

      saveInvoiceAuditNote(note)
        .then(() => {
          sendSnackbarMessage({ content: 'Invoice audit note saved.' });
        });
    }
  };

  const FinalizeActions = [
    {
      title: 'Cancel',
      action: handleFinalizeAuditClose
    },
    {
      title: 'Confirm',
      action: handleFinalizeAuditConfirm
    }
  ];

  const RevertFinalizeActions = [
    {
      title: 'Cancel',
      action: handleRevertFinalizeAuditClose
    },
    {
      title: 'Confirm',
      action: handleRevertFinalizeAuditConfirm
    }
  ];

  return (
    <FullWidthLayout SideNav={ShipmentRecordNav} className="shipments-styles">
      <When condition={!shipment}>
        <MissingOrDeniedShipmentAlert />
      </When>
      <When condition={shipment}>
        <FormFactor
          initialValues={initialValues}
          schema={AuditFormSchema}
          onSubmit={handleSubmit}
        >
          {renderAuditForm}
        </FormFactor>

        <InvoiceAuditNotes
          {...invoiceAuditNotesProps}
        />
      </When>
      {
        finalizeAudit && <AppDialog
          title={'Finalize Audit'}
          width={'sm'}
          open={finalizeAudit}
          onClose={handleFinalizeAuditClose}
          actionButtons={FinalizeActions}>
          Are you sure you want to finalize the audit for shipment #{bolNumber}?
        </AppDialog>
      }
      {
        revertFinalizeAudit && <AppDialog
          title={'Revert Audit'}
          width={'sm'}
          open={revertFinalizeAudit}
          onClose={handleRevertFinalizeAuditClose}
          actionButtons={RevertFinalizeActions}>
          Are you sure you want to revert the audit for shipment #{bolNumber}?
        </AppDialog>
      }

    </FullWidthLayout>
  );

};

const AuditFormSchema = Yup.lazy(values => {
  const schema = {};

  if (values.markAsFinalized) {
    schema.invoicedAmount = Yup
      .number()
      .nullable()
      .min(0, 'Cannot be negative value.')
      .typeError('Value is required.')
      .required('Value is required.');
    schema.invoiceAuditDate = Yup
      .date()
      .nullable()
      .required('Date is required.');
    schema.invoiceDueDate = Yup
      .date()
      .nullable()
      .required('Date is required.');
  }

  return Yup.object().shape(schema);
});

const NoteSort = (noteA, noteB) => {
  if (noteA.createDate > noteB.createDate)
    return -1;
  else if (noteB.createDate > noteA.createDate) {
    return 1;
  }
  else return 0;
};

export default ComponentBuilder
  .wrap(AuditingPage)
  .stateToProps((state, ownProps) => {
    return {
      associate: selectAssociate(state),
      invoice: state.shipment.modification.shipmentInvoice,
      shipment: state.shipment.modification.shipment,
      invoiceAuditNotes: state.shipment.modification.shipmentInvoiceAuditNotes,
      isAce: isAce(state)
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.shipment.modification.loadShipmentInvoice(bolNumber),
          shell.actions.shipment.modification.loadShipment(bolNumber),
          shell.actions.shipment.modification.loadShipmentInvoiceAuditNotes(bolNumber)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveInvoice(invoice) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.shipment.modification.saveShipmentInvoice(invoice));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async saveInvoiceAuditNote(note) {
        dispatch(shell.actions.sys.processStart(SaveNoteProcessName));
        dispatch(await shell.actions.shipment.modification.saveShipmentInvoiceAuditNote(note));
        dispatch(shell.actions.sys.processComplete(SaveNoteProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.modification.dispose());
      },
      async getFactoringCompanies() {
        const categoryTypes = await shell.gateway.getCompanyCategoryTypes();
        const fcType = categoryTypes.find(ct => ct.name === 'Factoring Company');
        const searchOptions = {
          filter: {
            categoryTypeId: fcType.id
          },
          limit: 1000,
          sort: [['companyName', 'asc']]
        };
        const { companies } = await shell.gateway.searchCompanies(searchOptions);
        return companies;
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();