import ActionBuilder from '../../ActionBuilder2';
import ActionTypes from '../../ActionTypes';

const saveShipmentInvoice = async (shell, shipment, invoice) => {

  const [
    updatedShipment,
    updatedInvoice
  ] = await Promise.all([
    shell.gateway.saveShipment(shipment),
    shell.gateway.saveInvoice(invoice)
  ]);

  return {
    type: ActionTypes.Dashboard.ShipmentInvoiceSaved,
    shipment: updatedShipment,
    invoice: updatedInvoice
  };

};

export default ActionBuilder
  .for(saveShipmentInvoice)
  .build();