import React from 'react';
import {Link} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

const NoOpProps = () => ({});

const useStyles = makeStyles((theme) => ({
  linkCell: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const LinkCell = (partsSelector, additionalProps = NoOpProps) => {
  const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);

  const classes = useStyles();

  return ({ rowData }) => {
    const [url, title] = partsSelector(rowData);
      const otherProps = additionalProps(rowData);

    return (
        <React.Fragment>
          <Link to={url} target={openLinksInNewTab ? "_blank" : "" } className={classes.linkCell} {...otherProps}>{title}</Link>
        </React.Fragment>
    );
  };
};

export default LinkCell;