import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import ComponentBuilder from '../../../../core/ComponentBuilder';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import isAce from '../../../../hubs/persona/selectors/isAce';
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
  tdStyles: {
    textDecoration: 'none',
    color: theme.palette.tgfLink.main,
    fontWeight: 'bold',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));
const LaneLookupListingTable = (props) => {
  const classes = useStyles();
  const {
    count,
    data,
    modifyingAssociate,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
    isAce
  } = props;

  const currencyFormatter = (amount) => {
    return new Intl.NumberFormat('en-US',
      { style: 'currency', currency: 'USD' }
    ).format(amount)
  };

  const canView = (associateId) =>
    isAce || (associateId === modifyingAssociate.id);

  const empty = (formatter) => (data) => Boolean(data) ?
    formatter(data) : null;

  const emptyValue = empty(x => x);
  const emptyCurrency = empty(currencyFormatter);

  const renderCustomerCost = (dataValue, associateId) => canView(associateId) ? emptyCurrency(dataValue) : null;
  const renderCustomerName = (dataValue, associateId) => canView(associateId) ? emptyValue(dataValue) : null;

  const calcTotalMargin = (carrierCost = 0, customerCost = 0, associateId) => {
    const calcValue = customerCost - carrierCost;
    const dataValue = calcValue !== 0 ? calcValue : null;

    return canView(associateId) ? emptyCurrency(dataValue) : null
  }
  const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
  const cellLink = (path, title, associateId) => {
    const rowLink = <Link to={path} target={openLinksInNewTab ? "_blank" : "" } className={classes.tdStyles}>{title}</Link>;

    return canView(associateId) || !associateId ? rowLink : title;
  }

  const AceColumns = !isAce ? [] :
    [{
      title: 'REP',
      field: 'repName',
      styles: {
        width: 50,
      },
      dataType: 'string'
    }];

  const GridColumns = [
    ...AceColumns,
    {
      title: 'Ship #',
      field: 'bolNumber',
      styles: {
        width: 50,
      },
      dataType: 'component',
      component: ({ rowData }) => cellLink(`/shipment/${rowData.bolNumber}/details`, rowData.bolNumber, rowData.associateId)
    },
    {
      title: 'Carrier Cost',
      field: 'carrierCost',
      styles: {
        width: 70,
      },
      dataType: 'component',
      component: ({ rowData }) => emptyCurrency(rowData.carrierCost)
    },
    {
      title: 'Customer Cost',
      field: 'customerCost',
      styles: {
        width: 70,
      },
      dataType: 'component',
      component: ({ rowData }) => renderCustomerCost(rowData.customerCost, rowData.associateId)
    },
    {
      title: 'Total Margin',
      field: 'totalMargin',
      noSort: true,
      styles: {
        width: 70,
      },
      dataType: 'component',
      component: ({ rowData }) => calcTotalMargin(rowData.carrierCost, rowData.customerCost, rowData.associateId)
    },
    {
      title: 'Carrier Name',
      field: 'carrierName',
      styles: {
        width: 170,
      },
      dataType: 'component',
      component: ({ rowData }) => cellLink(`/carrier/${rowData.carrierMcNumber}/details`, rowData.carrierName, null)
    },
    {
      title: 'Third Party',
      field: 'thirdPartyCompanyName',
      styles: {
        width: 105,
      },
      dataType: 'string'
    },
    {
      title: 'Pickup Date',
      field: 'bolDate',
      styles: {
        width: 80,
      },
      dataType: 'date'
    },
    {
      title: 'City From',
      field: 'fromCity',
      styles: {
        width: 90,
      },
      dataType: 'string'
    },
    {
      title: 'State From',
      field: 'fromState',
      styles: {
        width: 40,
      },
      dataType: 'string'
    },
    {
      title: 'City To',
      field: 'toCity',
      styles: {
        width: 90,
      },
      dataType: 'string'
    },
    {
      title: 'State To',
      field: 'toState',
      styles: {
        width: 40,
      },
      dataType: 'string'
    },
    {
      title: 'Customer Name',
      field: 'customerName',
      styles: {
        width: 150,
      },
      dataType: 'component',
      component: ({ rowData }) => cellLink(`/address-book/${rowData.customerId}/details`, renderCustomerName(rowData.customerName, rowData.associateId), rowData.associateId)
    },
    {
      title: 'Equipment Type',
      field: 'equipmentType',
      styles: {
        width: 100,
      },
      dataType: 'string'
    }
  ];

  return (
    <AppDenseGrid
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default ComponentBuilder
  .wrap(LaneLookupListingTable)
  .stateToProps((state, ownProps) => {
    return {
      isAce: isAce(state),
      modifyingAssociate: state.persona.modifyingAssociate
    }
  })
  .build();