import Thunk from 'redux-thunk';
import * as ReduxLogger from 'redux-logger';

const createLogger = () =>
  ReduxLogger.createLogger({
    duration: true,
    level: 'info',
    diff: true,
    collapsed: true
  });

/**
 * Creates an array of middleware to be used by the state store.
 * @param {object} config - Runtime configuration.
 * @return {function[]}
 */
const createStateStoreMiddleware = (config) => {
  const middleware = [
    Thunk
  ];

  // Only allow Redux action logging if we're developing locally or
  // in the shared development environment.
  if (config.isLocal() || config.isDevelopment()) {
    middleware.push(createLogger());
  }

  return middleware;
};

export default createStateStoreMiddleware;