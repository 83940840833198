import AppDateText from '../../../../core/components/text/AppDateText';
import React from 'react';
import {Grid} from '@material-ui/core';

const ChartSettingsSummaryBar = ({ settings: chartSettings }) => {

  let rangeComp = null;

  if (chartSettings.startDate && chartSettings.endDate) {
    rangeComp = (
      <SettingsSummary label={'Range'} value={<><AppDateText value={chartSettings.startDate} /> - <AppDateText value={chartSettings.endDate} /></>} />
    );
  }
  else if (chartSettings.startDate) {
    rangeComp = (
      <SettingsSummary label={'Range'} value={<>&gt; <AppDateText value={chartSettings.startDate} /></>} />
    );
  }
  else if (chartSettings.endDate) {
    rangeComp = (
      <SettingsSummary label={'Range'} value={<>&lt; <AppDateText value={chartSettings.endDate} /></>} />
    );
  }
  else {
    rangeComp = (
      <SettingsSummary label={'Range'} value={'all time'} />
    );
  }

  return (
    <React.Fragment>
      <Grid container direction={'row'} alignItems={'center'}>
        <SettingsSummary label={'Chart'} value={chartSettings.type} />
        {rangeComp}
        <SettingsSummary label={'Time Period'} value={chartSettings.timePeriod} />
        <SettingsSummary label={'Aggregate'} value={chartSettings.aggregateType} />
      </Grid>
    </React.Fragment>
  );
};

const SettingsSummary = ({ label, value }) => (
  <span style={{ marginRight: 8 }}>
    <label>{label}:</label> <strong>{value}</strong>
  </span>
);

export default ChartSettingsSummaryBar;