import * as D3 from 'd3';
import {ChartDimensions} from './SupportTypes';

const NumberFormatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

const LineChartTooltip = ({ chart, chartDescriptor, chartSettings, horizontalScale, verticalScale, data }) => {

  const enabledFeatureNames = Array.from(Object.entries(chartSettings.features))
    .filter(([featureName, isEnabled]) => isEnabled)
    .map(([featureName]) => featureName);

  const groupPairs = enabledFeatureNames.map((featureName) => {
    const group = chart.append('g')
      .attr('class', 'tooltip-group')
      .style('display', 'none')

    group.append('circle')
      .attr('class', 'tooltip-circle')
      .attr('r', 4)
      .style('fill', chartDescriptor.features[featureName].lineColor)

    group.append('rect')
      .attr('class', 'tooltip-background')
      .attr('width', ChartDimensions.TooltipWidth)
      .attr('height', ChartDimensions.TooltipHeight)
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', 4)
      .attr('ry', 4);

    group.append('text')
      .attr('class', `tooltip-value`)
      .style('font-size', '1.1em')
      .style('font-weight', 'bold')
      .attr('x', 5)
      .attr('y', 20)
      .style('fill', '#fff');

    return [featureName, group];
  });

  const groups = Object.fromEntries(groupPairs);

  const overlayRect = chart.append("rect")
    .attr("class", "overlay")
    .attr("width", ChartDimensions.Width)
    .attr("height", ChartDimensions.Height)
    .style('fill', 'none')
    .style('pointer-events', 'all')
    .on("mouseover", mouseover)
    .on("mouseout", mouseout)
    .on("mousemove", mousemove);

  function mouseover() {
    Array.from(Object.values(groups))
      .forEach((group) => group.style('display', null));
  }

  function mouseout() {
    Array.from(Object.values(groups))
      .forEach((group) => group.style('display', 'none'));
  }

  function mousemove(e) {

    const pointerCoords = D3.pointer(e, overlayRect.node());

    // Row index is the x axis.
    const rowIndexComputedValue = horizontalScale.invert(pointerCoords[0]);
    const preliminaryRowIndex = Math.floor(rowIndexComputedValue);

    const rowIndex = rowIndexComputedValue > (preliminaryRowIndex + .4) ?
      preliminaryRowIndex + 1 :
      preliminaryRowIndex;


    chartDescriptor.fields(enabledFeatureNames, chartSettings.aggregateType)
      .forEach((fieldName, index) => {
        const featureName = enabledFeatureNames[index];
        const row = data[rowIndex];

        let value = 0;

        // Temp hack to hide if row isn't found from rowIndex.
        if (row)
          value = row[fieldName] || 0;

        const valueX = horizontalScale(rowIndex);
        const valueY = verticalScale(value);

        // If the x or y puts the tooltip display in jeopardy, then
        // adjust accordingly.

        // Identify boundaries that the tooltip should flip at.
        const xUpperLimit = ChartDimensions.Width - (ChartDimensions.Width * .2);
        const yUpperLimit = ChartDimensions.Height - (ChartDimensions.Height * .5);

        // Flip the x and y if boundary has been surpassed.
        const groupX = valueX >= xUpperLimit ?
          valueX - ChartDimensions.TooltipWidth : valueX;
        const groupY = valueY >= yUpperLimit ?
          valueY - ChartDimensions.TooltipHeight : valueY;

        const group = groups[featureName];
        group.attr('transform', `translate(${groupX}, ${groupY})`);

        const text = group.select('.tooltip-value');

        text.text(NumberFormatter.format(value));

        const circle = group.select('.tooltip-circle');

        const circleX = valueX >= xUpperLimit ?
          ChartDimensions.TooltipWidth : 0;
        const circleY = valueY >= yUpperLimit ?
          ChartDimensions.TooltipHeight : 0;
        circle.attr('transform', `translate(${circleX}, ${circleY})`);

      });

      // const [tooltipRect, tooltipText] = tooltips[featureName];
      //
      // const [x, y] = coords;
      // circle.attr('transform', `translate(${x}, ${y})`);
      //
      // mainGroup.attr('transform', `translate(${tooltipX}, ${tooltipY})`);
      // // tooltipText.attr('transform', `translate(${tooltipX}, ${tooltipY})`);
      //
      // tooltipText.text(value);

  }

};

export default LineChartTooltip;