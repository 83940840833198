import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

import AppMenuButton from '../AppButton/AppMenuButton';
import AppCard from '../cards/AppCard';
import AppToolTip from '../AppTooltip/AppTooltip';

const useStyles = makeStyles(({
  icon: {
    textAlign: 'right'
  },
  cardToolTip: {
    display: 'inline-block',
    width: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
const dateCell = (value) => {
  const dateValue = new Date(value);
  return (<>
    {
      value ?
        dateValue.toMoment().format('LLL') :
        ''
    }
  </>)
};

const AppDocumentCard = (props) => {
  const classes = useStyles();
  const {
    document,
    cardColor,
    cardColumns,
    options,
    borderLocation
  } = props;
  
  const buildAssociate = (associate) => {
    const { lastName, firstName, systemId } = associate.uploadingAssociate;

    return fieldTooltip(`${firstName} ${lastName} (${systemId})`);
  };
  const fieldTooltip = (filename) => <AppToolTip title={filename}><span className={classes.cardToolTip}>{filename}</span></AppToolTip>;
  const buildCell = (row, column) => {
    switch (column.dataType) {
      case 'string':
        return row[column.field];
      case 'date':
        return dateCell(row[column.field]);
      case 'tooltip':
        return fieldTooltip(row[column.field]);
      case 'component':
        const ChildComponent = column.component
        return <ChildComponent rowData={row} />;
      default:
        return row[column.field];
    }
  };

  const defaultCardColumns = [
    {
      title: 'Description',
      field: 'description',
      dataType: 'string'
    },
    {
      title: 'Filename',
      field: 'filename',
      dataType: 'tooltip'
    },
    {
      title: 'Associate',
      field: 'uploadingAssociateId',
      dataType: 'component',
      component: ({ rowData }) => buildAssociate(rowData)
    },
    {
      title: 'Upload Date',
      field: 'uploadDate',
      dataType: 'date'
    }
  ];

  const columns = cardColumns || defaultCardColumns;
  const GetMenuButton = () => <AppMenuButton
    document={document}
    options={options}
  />;

  return (
        <AppCard
          cardColor={cardColor}
          {...borderLocation}
          title={borderLocation?.cardHeader && (document.docType?.name || document.title)}
          action={borderLocation?.cardHeader && <GetMenuButton />}
        >
          <Grid container direction="column">
            {
              !borderLocation?.cardHeader && <Grid item>
                <Grid container alignItems="center" justify="flex-start">
                  <Grid item xs={8}>
                    <Typography variant="h5">{document.docType?.name || document.title}</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.icon}>
                    <GetMenuButton />
                  </Grid>
                </Grid>
              </Grid>
            }
            {
              columns.map((column, key) =>
                document[column.field] ? <Grid item key={key}>
                  <Grid container>
                    <Grid item xs={12} md={4}>{column.title}:</Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">{buildCell(document, column)}</Typography>
                    </Grid>
                  </Grid>
                </Grid> : null
              )
            }
          </Grid>
        </AppCard>
  );
};

export default AppDocumentCard;