import React, { useState } from 'react';
import {
  Home,
  AccountBalance,
  AccountBalanceWallet,
  Dashboard,
  Payment,
  People,
  Settings, DateRange, Assignment
} from '@material-ui/icons';

import AppSideBar from '../../core/components/AppSideBar';

const isDashboardSelected = () =>
  window.location.pathname.startsWith('/admin/dashboard');

const LeftNav = (props) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const NavLinks = [
    { title: 'Admin', icon: Home, key: 'admin', href: '/admin' },
    { title: 'Home Page Settings', icon: Settings, key: 'home-settings', href: '/admin/home-settings' },
    { title: 'Dashboard', icon: Dashboard, key: 'dashboard', href: '/admin/dashboard/all', isSelected: isDashboardSelected },
    // TODO: UNCOMMENT WHEN READY TO INTRODUCE PHASE 2 ITEMS.
    // { title: 'Automatic Invoicing', icon: Assignment, key: 'automated-invoice-queue', href: '/admin/automated-invoice-queue' },
    { title: 'Manual Invoicing', icon: Assignment, key: 'invoicing-exception-queue', href: '/admin/invoicing-exception-queue' },
    { title: 'Commission Center', icon: AccountBalance, key: 'commission-center', href: '/admin/commission-center' },
    { title: 'Commission Summary', icon: AccountBalanceWallet, key: 'commission-summary', href: '/admin/commission-summary' },
    { title: 'Customer Payments', icon: Payment, key: 'customer-payments', href: '/admin/customer-payments' },
    { title: 'Manage Users', icon: People, key: 'manage-users', href: '/admin/manage-users' },
    { title: 'Company GM Report', icon: DateRange, key: 'company-gross-margin', href: '/admin/company-gross-margin' },
  ];
  return (
    <AppSideBar menuList={NavLinks} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />
  );
}

export default LeftNav