import React from 'react';
import {Grid} from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppMonthYear from '../../../core/components/inputs/AppDatePicker/AppMonthYear';
import CrmTypedAddressBookListingComboBox from '../../../crm/components/CrmTypedAddressBookListingComboBox';
import GrossMarginTable from '../../../crm/components/associate/GrossMarginTable';
import CustomerListingTable from './includes/CustomerListingTable';
import ReportNav from '../ReportNav';
import './CustomerReport.scss';
import MarginCard from "./includes/MarginCard";

const LoadProcessName = 'Reporting.CustomerReport.Loaded';
const LoadGmProcessName = 'Reporting.CustomerReport.GrossMarginBreakdown.Loaded';

const computeUtcDateRange = (localDate) => {
  const utcStartDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), 1));
  const utcEndDate = utcStartDate.toMoment().utc().add(1, 'month').subtract(1, 'ms').toDate();

  return [utcStartDate, utcEndDate];
};

const CustomerReport = (props) => {

  const {
    dispose,
    loadShipments,
    loadGrossMarginBreakdown,
    grossMarginBreakdown = null,
    customerReportListing,
    customerReportCount
  } = props;

  const currentDate = new Date();
  const localFirstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const [utcStartDate, utcEndDate] = computeUtcDateRange(localFirstOfMonth);

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['bolNumber', 'desc']]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('bolNumber');
  const [customerAssociate, setCustomerAssociate] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [monthYearSelectedDate, setMonthYearSelectedDate] = React.useState(new Date());
  const [startDate, setStartDate] = React.useState(utcStartDate);
  const [endDate, setEndDate] = React.useState(utcEndDate);

  React.useEffect(() => {
    if (selectedCompany) {
      loadGrossMarginBreakdown(startDate, endDate, selectedCompany.companyId, selectedCompany.associateId);
    }
    return () => dispose();
  }, [startDate, endDate, selectedCompany, dispose, loadGrossMarginBreakdown]);

  React.useEffect(() => {
    if (selectedCompany) {
      loadShipments(startDate, endDate, selectedCompany.companyId, selectedCompany.associateId, sort, offset, limit)
    }
  }, [startDate, endDate, selectedCompany, sort, offset, limit, loadShipments]);

  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  const handleMonthYearChange = (monthYearValue) => {
    const date = monthYearValue && monthYearValue.isValid() ?
      monthYearValue.toDate() : null;
    setMonthYearSelectedDate(date);
    const [utcStartDate, utcEndDate] = computeUtcDateRange(date);
    setStartDate(utcStartDate);
    setEndDate(utcEndDate);
    setOffset(0);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleCustomerChange = (customer) => {
    setOffset(0);
    setSelectedCompany(customer)
    setCustomerAssociate({
      id: customer?.associateId,
      firstName: customer?.associateFirstName,
      lastName: customer?.associateLastName
    })
  };

  return (
    <FullWidthLayout SideNav={ReportNav} title="Customer Report">
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item xs={12} md={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label htmlFor="monthYear">Month/Year:</label>
              <AppMonthYear
                id="monthYear"
                openTo="year"
                views={["year", "month"]}
                inputVariant="outlined"
                value={monthYearSelectedDate}
                onChange={handleMonthYearChange}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="search">Customer Name:</label>
              <CrmTypedAddressBookListingComboBox
                types={[CrmTypedAddressBookListingComboBox.Customer]}
                value={selectedCompany || null}
                onChange={handleCustomerChange}
                popupIcon={' '}
              />
            </Grid>
            <Grid item xs={6}>
              {
                grossMarginBreakdown.total && <MarginCard
                  text={'Total Margin'}
                  value={grossMarginBreakdown.total ? grossMarginBreakdown.total.margin : ''}
                  type={'margin'}
              />
              }
            </Grid>
            <Grid item xs={6} >
              <MarginCard
                text={'Avg. Margin %'}
                value={ grossMarginBreakdown.total ? grossMarginBreakdown.total.margin / grossMarginBreakdown.total.customerCost : ''}
                type={'percent'}
            />
            </Grid>
            <Grid item xs={12}>
              {
                selectedCompany && <>
                  Belongs to <span style={{ fontWeight: 'bold' }}>{`${customerAssociate?.firstName} ${customerAssociate?.lastName}`}</span>
                </>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={9}>
          <GrossMarginTable
            date={monthYearSelectedDate}
            grossMarginData={grossMarginBreakdown}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomerListingTable
            count={customerReportCount}
            data={customerReportListing}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid >
    </FullWidthLayout >
  )
}

export default ComponentBuilder
  .wrap(CustomerReport)
  .stateToProps((state, ownProps) => {
    return {
      customerReportListing: state.reporting.customerReport.listing,
      grossMarginBreakdown: state.reporting.customerReport.grossMarginBreakdown,
      customerReportCount: state.reporting.customerReport.customerReportCount
    }
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async loadShipments(startDate, endDate, customerId, associateId, sort, offset, limit) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.customerReport.loadShipments(startDate, endDate, customerId, associateId, sort, offset, limit));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async loadGrossMarginBreakdown(startDate, endDate, customerId, associateId) {
        dispatch(shell.actions.sys.processStart(LoadGmProcessName));
        dispatch(await shell.actions.reporting.customerReport.loadGrossMarginBreakdown(associateId, customerId, startDate, endDate));
        dispatch(shell.actions.sys.processComplete(LoadGmProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.reporting.customerReport.dispose());
      }
    }
  })
  .build()