import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from './areas/home/HomePage'
import AddressBookListingPage from './areas/address-book/ListingPage';
import AgingShipmentHistoryPage from './areas/address-book/AgingShipmentHistoryPage';
import AddressBookDocumentsPage from './areas/address-book/DocumentsPage';
import AddressBookCompanyOutcomeStreamPage from './areas/address-book/OutcomeStreamPage/OutcomeStreamPage';
import CarrierDetailPage from './areas/carriers/DetailsPage';
import CarrierCompliancePage from './areas/carriers/CompliancePage';
import CarrierDocumentsPage from './areas/carriers/DocumentsPage';
import CarrierNotesPage from './areas/carriers/NotesPage';
import CarrierHistoryPage from './areas/carriers/HistoryPage';
import BillingSettingsPage from './areas/address-book/BillingSettingsPage';
import FinancialHealthPage from './areas/address-book/FinancialHealthPage';
import ContactsPage from './areas/address-book/ContactsPage';
import DetailsPage from './areas/address-book/DetailsPage';
import LoginPage from './areas/security/LoginPage';
import OpenLoadsPage from './areas/open-loads';
import NotesPage from './areas/address-book/NotesPage';
import ShipmentHistoryPage from './areas/address-book/ShipmentHistoryPage';
import ShipmentCreateDocsPage from './areas/shipments/CreateDocsPage/CreateDocsPage';
import ShipmentListingPage from './areas/shipments/ListingPage/ListingPage';
import ShipmentDetailsPage from './areas/shipments/DetailsPage/DetailsPage';
import ShipmentDuplicationPage from './areas/shipments/DuplicationPage/DuplicationPage';
import ShipmentContentsPage from './areas/shipments/ContentsPage/ContentsPage';
import ShipmentLoadTrackingPage from './areas/shipments/LoadTrackingPage/LoadTrackingPage';
import ShipmentDocumentsPage from './areas/shipments/DocumentsPage/DocumentsPage';
import ShipmentFinancialPage from './areas/shipments/FinancialPage/FinancialPage';
import ShipmentAuditingPage from './areas/shipments/AuditingPage/AuditingPage';
import ShipmentMultipleStopsPage from './areas/shipments/MultipleStopsPage';
import AuthorizedRoute from './areas/security/AuthorizedRoute';

//Reporting route section
import CarriersList from './areas/reporting/AllCarriers';
import CustomerReport from './areas/reporting/CustomerReport';
import InvoiceAging from './areas/reporting/InvoiceAging';
import InvoiceAgingSummaryPage from './areas/reporting/InvoiceAgingSummary/InvoiceAgingSummaryPage';
import LaneLookup from './areas/reporting/LaneLookUp';
import SearchLoads from './areas/reporting/SearchLoads';
import MonthlyGrossMarginPage from './areas/reporting/MonthlyGrossMargin';
import CompanyGrossMarginPage from './areas/admin/CompanyGrossMargin';
import MissingCreditAppPage from './areas/reporting/MissingCreditApp';
import MissingPrimaryContactsPage from './areas/reporting/MissingPrimaryContacts';
import Reports from './areas/reporting/Reports';

//Admin route section
import CommissionSummary from './areas/admin/CommissionsSummary';
import CarrierListingPage from './areas/carriers/CarrierListing/CarrierListingPage';
import Admin from './areas/admin';
import AdminCommissionCenterPage from './areas/admin/CommissionCenter/CommissionsCenterPage';
import ReportCommissionCenterPage from './areas/reporting/CommissionCenter/CommissionCenterPage';
import CustomerPaymentsPage from './areas/admin/CustomerPayments';
import HomeSettingsPage from "./areas/admin/HomeSettingsPage";
import ManageUsersListingPage from './areas/admin/ManageUsers/ListingPage/ListingPage';
import ManageUsersDetailsPage from './areas/admin/ManageUsers/DetailsPage/DetailsPage';
import Help from './areas/help';
import DashboardPage from './areas/admin/Dashboard';
import AutomatedInvoiceQueuePage from './areas/admin/AutomatedInvoiceQueue/AutomatedInvoiceQueuePage';
import InvoicingExceptionWorkflowQueuePage from './areas/admin/InvoicingExceptionWorkflowQueue/InvoicingExceptionWorkflowQueuePage';
import BOLs from './areas/bols';
import GlobalSnackbar from './crm/components/GlobalSnackbar';
import GlobalSpinner from './crm/components/GlobalSpinner';
import Error404Page from './areas/errors/Error404Page';
import Business from '@tgf-crm/business';

import './App.scss';
import AccessDeniedPage from "./areas/errors/AccessDeniedPage";
import SecurityBlanket from "./areas/security/SecurityBlanket";

const RoleNames = Business.Associate.RoleNames;
const Noop = (props) => null;
const AdminOnlyRoles = [RoleNames.Ace];
const AllRoles = [RoleNames.Ace, RoleNames.Queen, RoleNames.Jack];

const App = (props) => {
  return (
    <React.Fragment>
      <Switch>

        <Route path={'/login'} component={LoginPage} />

         <SecurityBlanket>
          <Switch>
            <AuthorizedRoute exact path={'/'} component={HomePage} roles={AllRoles} />
            <AuthorizedRoute path={'/logout'} component={Noop} roles={AllRoles} />

            <AuthorizedRoute path={'/address-book/listing/:categoryTypeId?'} component={AddressBookListingPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/details'} component={DetailsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/documents'} component={AddressBookDocumentsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/contacts'} component={ContactsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/notes'} component={NotesPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/billing'} component={BillingSettingsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/shipment-history'} component={ShipmentHistoryPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/aging-shipment-history'} component={AgingShipmentHistoryPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/financial-health'} component={FinancialHealthPage} roles={AllRoles} />
            <AuthorizedRoute path={'/address-book/:id/outcome-stream'} component={AddressBookCompanyOutcomeStreamPage} roles={AllRoles} />

            <AuthorizedRoute path={'/carrier/listing'} component={CarrierListingPage} roles={AllRoles} />
            <AuthorizedRoute path={'/carrier/:id/details'} component={CarrierDetailPage} roles={AllRoles} />
            <AuthorizedRoute path={'/carrier/:id/compliance'} component={CarrierCompliancePage} roles={AllRoles} />
            <AuthorizedRoute path={'/carrier/:id/documents'} component={CarrierDocumentsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/carrier/:id/history'} component={CarrierHistoryPage} roles={AllRoles} />
            <AuthorizedRoute path={'/carrier/:id/notes'} component={CarrierNotesPage} roles={AllRoles} />

            <AuthorizedRoute path={'/open-loads'} component={OpenLoadsPage} roles={AllRoles} />

            <AuthorizedRoute path={'/shipment/listing/:trackedSet'} component={ShipmentListingPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/create-docs'} component={ShipmentCreateDocsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/details'} component={ShipmentDetailsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/duplicate'} component={ShipmentDuplicationPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/contents'} component={ShipmentContentsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/load-tracking'} component={ShipmentLoadTrackingPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/documents'} component={ShipmentDocumentsPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/financial'} component={ShipmentFinancialPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/auditing'} component={ShipmentAuditingPage} roles={AllRoles} />
            <AuthorizedRoute path={'/shipment/:id/multiple-stops'} component={ShipmentMultipleStopsPage} roles={AllRoles} />

            <AuthorizedRoute exact path={'/bols'} component={BOLs} roles={AllRoles} />
            <AuthorizedRoute exact path={'/support/help'} component={Help} roles={AllRoles} />

            <AuthorizedRoute exact path={'/reports'} component={Reports} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/customer-report'} component={CustomerReport} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/invoice-aging'} component={InvoiceAging} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/invoice-aging-summary'} component={InvoiceAgingSummaryPage} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/lane-lookup'} component={LaneLookup} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/search-loads'} component={SearchLoads} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/monthly-gross-margin'} component={MonthlyGrossMarginPage} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/carriers-list'} component={CarriersList} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/missing-credit-app'} component={MissingCreditAppPage} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/commission-center'} component={ReportCommissionCenterPage} roles={AllRoles} />
            <AuthorizedRoute path={'/reports/missing-primary-contacts'} component={MissingPrimaryContactsPage} roles={AllRoles} />

            <AuthorizedRoute exact path={'/admin'} component={Admin} roles={AdminOnlyRoles}/>
            <AuthorizedRoute path={'/admin/commission-summary'} component={CommissionSummary} roles={AdminOnlyRoles} />
            <AuthorizedRoute exact path={'/admin/home-settings'} component={HomeSettingsPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/commission-center'} component={AdminCommissionCenterPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/customer-payments'} component={CustomerPaymentsPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/dashboard/:statusSet'} component={DashboardPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute exact path={'/admin/manage-users'} component={ManageUsersListingPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/manage-users/:id'} component={ManageUsersDetailsPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/company-gross-margin'} component={CompanyGrossMarginPage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/automated-invoice-queue'} component={AutomatedInvoiceQueuePage} roles={AdminOnlyRoles} />
            <AuthorizedRoute path={'/admin/invoicing-exception-queue'} component={InvoicingExceptionWorkflowQueuePage} roles={AdminOnlyRoles} />
            <Route component={Error404Page}/>
          </Switch>
        </SecurityBlanket>
      </Switch>
      <GlobalSnackbar />
      <GlobalSpinner />
    </React.Fragment>
  );
};

export default App;