import React from 'react';
import FinancialHealthLineChart from './FinancialHealthLineChart';
import ChartSettingsSummaryBar from './ChartSettingsSummaryBar';
import {
  VolumeChartDescriptor
} from './SupportTypes';
import ChartLegendBar from './ChartLegendBar';
import AppIntegerText from '../../../../core/components/text/AppIntegerText';

const VolumeChart = ({ company, settings: chartSettings, data }) => {

  return (
    <React.Fragment>
      <ChartSettingsSummaryBar settings={chartSettings} />
      <FinancialHealthLineChart
        company={company}
        settings={chartSettings}
        chartDescriptor={VolumeChartDescriptor}
        data={data}
      />
      <ChartLegendBar chartDescriptor={VolumeChartDescriptor} settings={chartSettings} data={data} AggregateText={AppIntegerText} />
    </React.Fragment>
  );
};

export default VolumeChart;