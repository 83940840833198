import React from 'react';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppMuiTable from '../../../../core/components/AppDenseGrid';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import {NoteAdd} from '@material-ui/icons';

const CategoryTypeCell = ({ rowData: note  }) =>
  note.type.name;

const CreatedByCell = ({ rowData: note }) =>
  note.createdByAssociate.fullName;

const NotesCard = (props) => {

  const {
    associateNotes = [],
    onAddNoteClick
  } = props;

  const hasNotes = associateNotes.length > 0;

  const tableColumns = [
    {
      title: 'Category',
      field: 'typeId',
      noSort: true,
      styles: {
        width: 100,
      },
      dataType: 'component',
      component: CategoryTypeCell
    },
    {
      title: 'Content',
      field: 'content',
      noSort: true,
      styles: {},
      dataType: 'string'
    },
    {
      title: 'Created By',
      field: 'createdByAssociateId',
      noSort: true,
      styles: {
        width: 100,
      },
      dataType: 'component',
      component: CreatedByCell
    },
    {
      title: 'Date',
      field: 'createDate',
      noSort: true,
      styles: {
        width: 80,
      },
      dataType: 'date'
    }
  ];

  const cardActions = [
    <AppIconButton key={'upload'} Icon={NoteAdd} tooltip={'Add Note'} onClick={onAddNoteClick} disabled={!onAddNoteClick} />
  ];

  return (
    <AppMuiCard title={'Notes'} action={cardActions}>
      { !hasNotes && 'Associate doesn\'t have any notes.' }
      {
        hasNotes &&
        <AppMuiTable
          columns={tableColumns}
          data={associateNotes}
          count={associateNotes.length}
        />
      }
    </AppMuiCard>
  );
};

export default NotesCard;