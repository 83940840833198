import React from 'react';
import * as Yup from "yup";
import Core from '@atomos/core';
import { Add } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AddressBookRecordNav from '../AddressBookRecordNav';
import FormFactor from "../../../core/FormFactor/FormFactor";
import ComponentBuilder from "../../../core/ComponentBuilder";
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';

import AppButton from '../../../core/components/AppButton';
import AppDialog from '../../../core/components/AppDialog';
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';
import composePageTitle from '../../../core/utils/composePageTitle';
import AppDateTimeText from '../../../core/components/text/AppDateTimeText';
import "./NotesListing.scss";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import isAce from "../../../hubs/persona/selectors/isAce";

const LoadProcessName = 'AddressBook.NotesPage.Load';
const SaveProcessName = 'AddressBook.NotesPage.Save';

const NewNoteSchema = Yup.object().shape({
  note: Yup.string().required('Note is required.')
});


const NotesPage = (props) => {

  const {
    company,
    companyNotes,
    dispose,
    match,
    load,
    isAce,
    modifyingAssociate,
    saveCompanyNote,
    sendSnackbarMessage
  } = props;

  const companyId = match.params.id && parseInt(match.params.id);

  if (isNewCompany(companyId)) {
    throw new Error('CompanyId url parameter missing.');
  }

  const [isAddingNote, setIsAddingNote] = React.useState(false);

  React.useEffect(() => {
    load(companyId);
    return () => dispose();
  }, [companyId, dispose, load]);

  const handleNewNoteOpen = (e) =>
    setIsAddingNote(true);

  const handleNewNoteClose = (e) =>
    setIsAddingNote(false);

  const handleNewNoteSubmit = (values, formFactor) => {
    setIsAddingNote(false);
    saveCompanyNote(company.id, values.note)
      .then(() => {
        sendSnackbarMessage({ content: 'Note saved.' });
      });
  };

  const newNoteInitialValues = {
    note: '',
    onClose: handleNewNoteClose
  };

  const title = composePageTitle('Address Book', 'Notes', company?.name);

  const sortedNotes = companyNotes
    .slice()
    .sort(NoteSort);

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title} className="address-book-styles">
      {company !== null ?
        <CheckIfAuthorized
          isAce={isAce}
          modifyingAssociate={modifyingAssociate}
          company={company} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AppButton startIcon={<Add />} onClick={handleNewNoteOpen}>New Note</AppButton>
            </Grid>
            <Grid item xs={12}>
              <AppMuiSimpleTable
                columns={Columns}
                tablePropMap={makeTableFixed}
                data={sortedNotes}
              />
            </Grid>
            <Grid item xs={12}>
              {isAddingNote && <FormFactor
                initialValues={newNoteInitialValues}
                schema={NewNoteSchema}
                onSubmit={handleNewNoteSubmit}>
                {renderNewNoteForm}
              </FormFactor>
              }
            </Grid>
          </Grid>
        </CheckIfAuthorized>
        :
        <MissingOrDeniedCompanyAlert />}
    </FullWidthLayout>
  );
};

const makeTableFixed = (baseProps) =>
  Core.Utils.merge({}, baseProps, {
    style: { tableLayout: 'fixed' },
    width: '100%'
  });

const Columns = [
  {
    title: 'Note',
    headerPropMap(index, columns) {
      return { width: '65%' };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { overflowWrap: 'break-word' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.note
    }
  },
  {
    title: 'Date',
    headerPropMap(index, columns) {
      return { width: '15%', style: { textAlign: 'center' } };
    },
    valueMap(note, index, data, columns) {
      return <AppDateTimeText value={note.createDate} />;
    }
  },
  {
    title: 'Associate',
    headerPropMap(index, columns) {
      return { width: '20%', style: { textAlign: 'center' } };
    },
    cellPropMap(note, index, data, columns) {
      return {
        style: { textAlign: 'center' }
      };
    },
    valueMap(note, index, data, columns) {
      return note.associate ?
        `${note.associate.firstName}  ${note.associate.lastName}` :
        '[BLANK]'
    }
  }
];

const renderNewNoteForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {

  const newNoteActions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleNoteChange = (e) =>
    setFieldValue('note', e.target.value);

  return (
    <AppDialog
      title="New Note"
      open={true}
      onClose={values.onClose}
      actionButtons={newNoteActions}
      width='sm'
    >
      <Grid container>
        <Grid item xs={12} style={{ width: 400, height: 200 }}>
          <AppInput
            autoFocus
            id="companyNote"
            rows="10"
            cols="45"
            inputProps={{
              maxLength: 1000
            }}
            multiline={true}
            onChange={handleNoteChange} />
          <AppValidationFailure message={errors.note} />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

const NoteSort = (noteA, noteB) => {
  if (noteA.createDate > noteB.createDate)
    return -1;
  else if (noteB.createDate > noteA.createDate) {
    return 1;
  }
  else return 0;
};

export default ComponentBuilder
  .wrap(NotesPage)
  .stateToProps((state, ownProps) => {
    return {
      isAce: isAce(state),
      modifyingAssociate: state.persona.modifyingAssociate,
      company: state.addressBook.modification.company,
      companyNotes: state.addressBook.modification.companyNotes
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.addressBook.modification.loadCompany(companyId),
          shell.actions.addressBook.modification.loadCompanyNotes(companyId)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveCompanyNote(companyId, noteText) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.addressBook.modification.saveCompanyNote(companyId, noteText));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();