import React from 'react';
import { Grid } from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import Core from '@atomos/core';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import isAce from '../../../hubs/persona/selectors/isAce';
import AppTitleBar from '../../../core/components/AppTitleBar';
import FormFactor from '../../../core/FormFactor/FormFactor';
import renderMultipleStopsForm from './renderMultipleStopsForm';
import ShipmentRecordNav from "../ShipmentRecordNav";
import When from "../../../core/components/condtionals/When";
import MissingOrDeniedShipmentAlert from '../MissingOrDeniedShipmentAlert';
import "./MultipleStopsPage.scss";

const LoadProcessName = 'Shipment.MultipleStop.Load';
const SaveProcessName = 'Shipment.MultipleStop.Save';
const UpdateShipment = 'Shipment.Save';

const StopTypes = [
  { id: 1, name: 'Pickup', value: 'isPickup' },
  { id: 2, name: 'Delivery', value: 'isDelivery' },
  { id: 3, name: 'Unload/Reload', value: 'isUnloadReload' }
];

const createEmptySelectedCompany = () => {
  return {
    companyId: null,
    companyName: null,
    companyAddress1: null,
    companyAddress2: null,
    companyCity: null,
    companyStateProvince: null,
    companyPostalCode: null,
    companyBusinessPhone: null
  };
};

const convertToSelectedCompany = (company) => {
  return {
    companyId: company.id,
    companyName: company.name,
    companyAddress1: company.address1,
    companyAddress2: company.address2,
    companyCity: company.city,
    companyStateProvince: company.stateProvince,
    companyPostalCode: company.postalCode,
    companyBusinessPhone: company.businessPhone
  };
};

const extractData = (source, model) => {
  const extractedPairs = Core.Utils
    .toPairs(model)
    .map(([key]) => [key, source[key]]);

  return Core.Utils
    .fromPairs(extractedPairs);
};

const MultipleStopsPage = (props) => {
  const {
    dispose,
    match,
    multipleStops = [],
    shipment = {},
    load,
    saveMultipleStops,
    updateShipment,
    createNewStop,
    searchCompanies,
    sendSnackbarMessage,
    isAce
  } = props;

  const bolNumber = match.params.id !== 'new' ?
    parseInt(match.params.id) : undefined;

  React.useEffect(() => {
    load(bolNumber);

    return () => dispose();
  }, [bolNumber, load, dispose]);

  const getCurrentSelectedStopType = (stop) => {
    switch (true) {
      case stop.isPickup:
        return StopTypes.find(st => st.value === 'isPickup');
      case stop.isDelivery:
        return StopTypes.find(st => st.value === 'isDelivery');
      case stop.isUnloadReload:
        return StopTypes.find(st => st.value === 'isUnloadReload');
      default:
        return null;
    }
  };

  const processStops = Core.Utils.range(4)
    .map((item, index) => multipleStops[index])
    .map((multipleStop) => multipleStop ? multipleStop : createNewStop())
    .map((multipleStop) => Object.assign({}, multipleStop,
      {
        selectedStopType: getCurrentSelectedStopType(multipleStop),
        selectedCompany: multipleStop.company ?
          convertToSelectedCompany(multipleStop.company) :
          null
      }));

  const initialValues = {
    bolNumber,
    isMultipleStop: Core.Utils.get(shipment, 'isMultipleStop') || false,
    stopTypes: StopTypes,
    multipleStops: processStops,
    searchCompanies,
    candidateCompanies: [],
    shipment,
    isAce,
    createEmptyCompany() {
      return Core.Utils.merge(createEmptySelectedCompany(), {
        selectedStopType: null,
        selectedCompany: null,
        arrivalDate: null,
        arrivalTime: null,
        isDelivery: false,
        isUnloadReload: false,
        isPickup: false,
        note: null,
        reference: null,
      });
    }
  };

  const handleSubmit = (values, formFactor) => {
    const multiStopsExtract = values.multipleStops
      .filter(m => m.companyId)
      .map(m =>
        !Core.Utils.isNumber(m.bolNumber)
          ? { ...m, bolNumber: bolNumber }
          : m
      );

    const newShipmentMultiStop = createNewStop();

    const multiStopCollections = Core.Utils.range(multiStopsExtract.length)
      .map((item, index) => multiStopsExtract[index])
      .map((multipleStop) => extractData(multipleStop, newShipmentMultiStop));

    const multiStopData = {
      'multiStops': multiStopCollections,
      'isMultipleStop': values.isMultipleStop
    };

    shipment.isMultipleStop = values.isMultipleStop;
    saveMultipleStops(multiStopData, bolNumber)
      .then(() => {
        updateShipment(shipment);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Stop locations saved.' });
      });
  };

  return (
    <FullWidthLayout SideNav={ShipmentRecordNav} className={"shipments-styles"}>
      <Grid container spacing={2}>
        <When condition={!shipment}>
          <MissingOrDeniedShipmentAlert />
        </When>
        <When condition={shipment}>
          <Grid item xs={12}><AppTitleBar title={`Shipments - Multi-Stop - ${bolNumber}`} /></Grid>
          {/*dialog*/}
          <Grid item xs={12}>
            <FormFactor
              initialValues={initialValues}
              onSubmit={handleSubmit}>
              {renderMultipleStopsForm}
            </FormFactor>
          </Grid>
        </When>
      </Grid>
    </FullWidthLayout>
  );

};

export default ComponentBuilder
  .wrap(MultipleStopsPage)
  .stateToProps((state, ownProps) => ({
    multipleStops: state.shipment.modification.shipmentMultipleStops,
    shipment: state.shipment.modification.shipment,
    isAce: isAce(state)
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(bolNumber) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.shipment.modification.loadShipmentMultipleStops(bolNumber),
          shell.actions.shipment.modification.loadShipment(bolNumber)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveMultipleStops(multiStopData, bolNumber) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.shipment.modification.saveShipmentMultipleStop(multiStopData, bolNumber));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async updateShipment(shipment) {
        dispatch(shell.actions.sys.processStart(UpdateShipment));
        dispatch(await shell.actions.shipment.modification.saveShipment(shipment));
        dispatch(shell.actions.sys.processComplete(UpdateShipment));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.modification.dispose());
      },
      createNewStop() {
        return shell.gateway.createShipmentMultipleStop();
      },
      async searchCompanies(options) {
        const { companies } = await shell.gateway.searchCompanies(options);
        return companies;
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();