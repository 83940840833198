import React from 'react';
import Grid from '@material-ui/core/Grid';
import * as Yup from 'yup';
import Core from '@atomos/core';
import FasterFormFactor from '../../../../core/FormFactor/FasterFormFactor';
import FullWidthLayout from '../../../../core/layouts/FullWidthLayout';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import LeftNav from '../../LeftNav';
import ButtonBar from './ButtonBar';
import AssociateForm from './AssociateForm';
import RoleNames from '../../../../hubs/persona/RoleNames';
import JackListingCard from './JackListingCard';
import NotesCard from './NotesCard';
import NewNoteDialog from './NewNoteDialog';
import trimNullifyObject from '../../../../core/utils/trimNullifyObject';
import {Redirect} from 'react-router-dom';
import FormFactorEvents from '../../../../core/FormFactor/FormFactorEvents';

const LoadAssociateProcessName = 'ManageUsers.DetailsPage.AssociateLoad';
const LoadAssociateJacksProcessName = 'ManageUsers.DetailsPage.AssociateJacksLoad';
const LoadAssociateNotesName = 'ManageUsers.DetailsPage.AssociateNotesLoad';
const LoadAssociateNoteTypesName = 'ManageUsers.DetailsPage.AssociateNoteTypesLoad';
const SaveAssociateProcessName = 'ManageUsers.DetailsPage.AssociateSave';
const SaveAssociateNoteProcessName = 'ManageUsers.DetailsPage.AssociateNoteSave';
const TerminateSessionsProcessName = 'ManageUsers.DetailsPage.TerminateSessions';

const isSelectedRoleChange = (event) =>
  event instanceof FormFactorEvents.ValueAssignmentEvent &&
  event?.pathSpec === 'selectedRole';

// Invocable rule that prevents the selectedParent
// property from retaining a value  when changing role from
// Jack to something else.
const ResetParentWhenNotJackRule = {
  invoke(event, context) {
    if (isSelectedRoleChange(event) && event.newValue.name !== RoleNames.Jack) {
      context.setFieldValue('selectedParent', null);
    }
  }
};

// Rule that prevents non-Aces from having the carrier privilege as well
// as disabling the option on screen.
const ResetCarrierPrivilegeWhenNotAceRule = {
  invoke(event, context) {
    if (isSelectedRoleChange(event)) {

      if (event.newValue.name !== RoleNames.Ace) {
        context.setSupportingValue('permissionFieldsDisabled', true);
        context.setFieldValue('associate.hasCarrierPrivilege', false);
        context.setFieldValue('associate.collectionRole', false);
        context.setFieldValue('associate.displayPayouts', false);
      }
      else {
        context.setSupportingValue('permissionFieldsDisabled', false);
        context.setFieldValue('associate.displayPayouts', true);
      }
      if (event.newValue.name == RoleNames.Queen) {
        context.setFieldValue('associate.displayPayouts', true);
      }
    }
  }
};

const DetailsPage = (props) => {

  const {
    authAssociate,
    activeAssociate,
    parentAssociate,
    jackAssociates,
    associateNotes,
    associateNoteTypes,
    roles,
    match,
    dispose,
    queenRole,
    loadAssociate,
    loadAssociateJacks,
    loadAssociateNotes,
    loadAssociateNoteTypes,
    saveAssociate,
    saveAssociateNote,
    terminateSessions,
    sendSnackbarMessage
  } = props;

  const associateId = match.params.id === 'new' ?
    null : parseInt(match.params.id);

  React.useEffect(() => {
    loadAssociateNoteTypes();
    return () => dispose();
  }, [dispose, loadAssociateNoteTypes]);

  React.useEffect(() => {
    loadAssociate(associateId);
    if (associateId) {
      loadAssociateJacks(associateId);
      loadAssociateNotes(associateId);
    }
  }, [associateId, loadAssociate, loadAssociateJacks, loadAssociateNotes]);

  const [isAddingNote, setIsAddingNote] = React.useState(false);

  if (!associateId && activeAssociate?.id > 0) {
    return (<Redirect to={`/admin/manage-users/${activeAssociate?.id}`} />);
  }

  const formProps = {
    initialValues: {
      associate: activeAssociate,
      selectedRole: activeAssociate?.roles[0] || null,
      selectedParent: parentAssociate || null
    },
    supportingValues: {
      authAssociate,
      roles: roles.filter(r => r.name !== RoleNames.King),
      queenRole: queenRole.find(r => r.name === RoleNames.Queen),
      permissionFieldsDisabled: activeAssociate?.roles[0]?.name !== RoleNames.Ace
    },
    schema: AssociateSchema,
    rules: [
      ResetParentWhenNotJackRule,
      ResetCarrierPrivilegeWhenNotAceRule
    ],
    onSubmit(values) {

      const fullAssociate = { ...values.associate };
      fullAssociate.roles = [values.selectedRole];

      fullAssociate.parentAssociateId = values.selectedParent?.id || null;

      saveAssociate(fullAssociate)
        .then(() => {
          sendSnackbarMessage({ content: 'Associate saved.' });
        });
    }
  };

  const activityTitle = activeAssociate?.id > 0 ?
    `${activeAssociate.firstName} ${activeAssociate.lastName}` :
    `Adding New Associate`;

  const handleAddNoteClick = () => {
    setIsAddingNote(true);
  };

  const handleNewNoteSaveClick = (selectedNoteType, content) => {
    
    const newNote = {
      id: 0,
      associateId: activeAssociate.id,
      typeId: selectedNoteType.id,
      content: content.trim(),
      createdByAssociateId: authAssociate.id,
      createDate: new Date()
    };

    saveAssociateNote(newNote)
      .then(() => {
        setIsAddingNote(false);
        sendSnackbarMessage({ content: 'Note saved.' })
      });
  };

  const handleNewNoteCancelClick = () => {
    setIsAddingNote(false);
  };

  const handleUnlockClick = () => {
    const unlockedAssociate = {
      ...activeAssociate,
      loginAttempts: 0
    };
    saveAssociate(unlockedAssociate)
      .then(() => {
        sendSnackbarMessage({ content: 'Account unlocked.' });
      });
  };

  const handleTerminateSessionClick = () => {
    terminateSessions(activeAssociate.id)
      .then(() => {
        sendSnackbarMessage({ content: `Associate ${activeAssociate.firstName} ${activeAssociate.lastName} has been logged out.` });
      });
  };

  const accountLocked = activeAssociate?.loginAttempts >= 3;

  return (
    <React.Fragment>
      <FullWidthLayout SideNav={LeftNav} title={`Manage Users - ${activityTitle}`}>
        <FasterFormFactor {...formProps} >
          <Grid container spacing={2}>
            <ButtonBar
              onUnlockClick={accountLocked ? handleUnlockClick : null}
              onTerminateSessionClick={handleTerminateSessionClick}
            />
            <Grid item xs={12} md={6} lg={4}>
              <AssociateForm
                sendSnackbarMessage={sendSnackbarMessage}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <JackListingCard
                    jackAssociates={jackAssociates}
                  />
                </Grid>
                <Grid item xs={12}>
                  <NotesCard
                    associateNotes={associateNotes}
                    onAddNoteClick={associateId ? handleAddNoteClick : null}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FasterFormFactor>
      </FullWidthLayout>
      {
        isAddingNote &&
        <NewNoteDialog
          noteTypes={associateNoteTypes}
          onSaveClick={handleNewNoteSaveClick}
          onCancelClick={handleNewNoteCancelClick}
        />
      }
    </React.Fragment>
  )
};

const AssociateSchema = Yup.lazy(values => {

  const associateSchema = {
    firstName: Yup
      .string()
      .nullable()
      .required('First Name is required.'),
    lastName: Yup
      .string()
      .nullable()
      .required('First Name is required.'),
    emailAddress: Yup
      .string()
      .nullable()
      .required('Email is required.')
      .email('Email is invalid.'),
    decryptedPassword: Yup
      .string()
      .nullable()
      .required('Password is required.'),
    businessPhone: Yup
      .string()
      .nullable()
      .required('Business Phone is required.')
      .matches(Core.Text.PhoneRegExp, 'Business Phone is invalid.')
  };

  if (values.associate?.faxPhone) {
    associateSchema.faxPhone = Yup
      .string()
      .nullable()
      .matches(Core.Text.PhoneRegExp, 'Business Fax is invalid.');
  }

  if (values.associate?.rateConBusinessPhone) {
    associateSchema.rateConBusinessPhone = Yup
      .string()
      .nullable()
      .matches(Core.Text.PhoneRegExp, 'Business Phone is invalid.');
  }

  if (values.associate?.rateConFaxPhone) {
    associateSchema.rateConFaxPhone = Yup
      .string()
      .nullable()
      .matches(Core.Text.PhoneRegExp, 'Fax Phone is invalid.');
  }

  if (values.associate?.rateConEmailAddress) {
    associateSchema.rateConEmailAddress = Yup
      .string()
      .nullable()
      .email('Email is invalid.');
  }

  const fullSchema = {
    associate: Yup.object().shape(associateSchema),
    selectedRole: Yup.object().nullable().required('Role is required.')
  };

  if (values.selectedRole?.name === RoleNames.Jack) {
    fullSchema.selectedParent = Yup.object().nullable().required('Queen is required.');
  }

  return Yup.object().shape(fullSchema);
});

export default ComponentBuilder
  .wrap(DetailsPage)
  .stateToProps((state, ownProps) => {
    return {
      authAssociate: state.persona.associate,
      activeAssociate: state.admin.manageUsers.associate,
      parentAssociate: state.admin.manageUsers.parentAssociate,
      jackAssociates: state.admin.manageUsers.associateJacks,
      associateNotes: state.admin.manageUsers.associateNotes,
      associateNoteTypes: state.admin.manageUsers.associateNoteTypes,
      roles: state.persona.roles,
      queenRole: state.persona.roles
    };
  })
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async dispose() {
        dispatch(await shell.actions.admin.manageUsers.dispose());
      },
      async loadAssociate(associateId) {
        dispatch(shell.actions.sys.processStart(LoadAssociateProcessName));
        dispatch(await shell.actions.admin.manageUsers.loadAssociate(associateId));
        dispatch(shell.actions.sys.processComplete(LoadAssociateProcessName));
      },
      async loadAssociateJacks(associateId) {
        dispatch(shell.actions.sys.processStart(LoadAssociateJacksProcessName));
        dispatch(await shell.actions.admin.manageUsers.loadAssociateJacks(associateId));
        dispatch(shell.actions.sys.processComplete(LoadAssociateJacksProcessName));
      },
      async loadAssociateNotes(associateId) {
        dispatch(shell.actions.sys.processStart(LoadAssociateNotesName));
        dispatch(await shell.actions.admin.manageUsers.loadAssociateNotes(associateId));
        dispatch(shell.actions.sys.processComplete(LoadAssociateNotesName));
      },
      async loadAssociateNoteTypes() {
        dispatch(shell.actions.sys.processStart(LoadAssociateNoteTypesName));
        dispatch(await shell.actions.admin.manageUsers.loadAssociateNoteTypes());
        dispatch(shell.actions.sys.processComplete(LoadAssociateNoteTypesName));
      },
      async saveAssociate(associate) {
        const cleanAssociate = trimNullifyObject(associate);
        dispatch(shell.actions.sys.processStart(SaveAssociateProcessName));
        dispatch(await shell.actions.admin.manageUsers.saveAssociate(cleanAssociate));
        dispatch(shell.actions.sys.processComplete(SaveAssociateProcessName));
      },
      async saveAssociateNote(note) {
        dispatch(shell.actions.sys.processStart(SaveAssociateNoteProcessName));
        dispatch(await shell.actions.admin.manageUsers.saveAssociateNote(note));
        dispatch(shell.actions.sys.processComplete(SaveAssociateNoteProcessName));
      },
      async terminateSessions(associateId) {
        dispatch(shell.actions.sys.processStart(TerminateSessionsProcessName));
        await shell.gateway.terminateSessions(associateId);
        dispatch(shell.actions.sys.processComplete(TerminateSessionsProcessName));
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();