import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import AppButton from '../AppButton';
import AppCard from '../cards/AppCard';
import AppDrawer from '../AppDrawer';
import AppDropdown from '../dropdowns/AppDropdown';
import AppInput from '../inputs/AppInput';

const useStyles = makeStyles(theme => ({
  container: {
    width: 400
  },
  fileInput: {
    display: 'none',
  }
}));

const AppDocumentDrawer = ({ documentTypes, open, onClose, anchor, onPublish }) => {
  const classes = useStyles();
  const [fileUploadName, setFileUploadName] = React.useState(null);
  const [docType, setDocType] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [file, setFile] = React.useState(null);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const fileInfo = file.name;
    setFile(file);
    setFileUploadName(fileInfo)
  };

  const handleDocTypeChange = (e, documentType) => {
    setDocType(documentType)
  };
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handlePublishDocument = () => {
    const document = {
      description,
      documentTypeId: docType.id,
      filename: fileUploadName,
      document: file
    };
    clearState();
    onPublish(document);
  };

  const handleCloseDrawer = () => {
    clearState();
    onClose();
  };

  const clearState = () => {
    setFileUploadName(null);
    setDocType(null);
    setDescription(null);
    setFile(null);
  };

  return (
    <AppDrawer
      anchor={anchor}
      open={open}
      onClose={handleCloseDrawer}
    >
      <AppCard title="Add Document">
        <div className={classes.container}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={4}>Doc. Type</Grid>
            <Grid item xs={12} md={8}>
              <AppDropdown
                id={'docType'}
                field={'name'}
                fieldIndex={'id'}
                options={documentTypes}
                onChange={handleDocTypeChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>Description</Grid>
            <Grid item xs={12} md={8}>
              <AppInput
                id="description"
                placeholder="optional"
                inputProps={{
                  maxLength: 300
                }}
                onChange={handleDescriptionChange}
              />
            </Grid>
            {fileUploadName && <>
              <Grid item xs={12} md={4}>File</Grid>
              <Grid item xs={12} md={8}>
                {fileUploadName}
              </Grid>
            </>
            }
            <Grid container item xs={12} justify="flex-end">
              <label htmlFor="fileUpload">
                <AppInput
                  type="file"
                  disabled={docType === null}
                  className={classes.fileInput}
                  id="fileUpload"
                  onChange={handleFileUpload}
                />
                <AppButton
                  color={'tgfGrey'}
                  component="span"
                  disabled={docType === null} 
                >
                  Browse Files
                </AppButton>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <AppButton disabled={docType === null || fileUploadName === null} onClick={handlePublishDocument}>
                    Publish
                </AppButton>
                </Grid>
                <Grid item>
                  <AppButton onClick={handleCloseDrawer}>
                    Cancel
                </AppButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </AppCard>
    </AppDrawer>
  )
}
export default AppDocumentDrawer;