import Core from '@atomos/core';
import Business from '@tgf-crm/business';
import React from 'react';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AddressBookRecordNav from '../AddressBookRecordNav';
import ComponentBuilder from '../../../core/ComponentBuilder';
import ContactsListing from './ContactsListingGrid';
import FormFactor from '../../../core/FormFactor/FormFactor';
import renderModifyContactForm from './renderModifyContactForm';

import AppAlertStatic from '../../../core/components/AppAlertStatic';
import AppButton from '../../../core/components/AppButton';
import AppDialog from '../../../core/components/AppDialog';
import composePageTitle from '../../../core/utils/composePageTitle';
import "./ContactsListing.scss";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import isAce from "../../../hubs/persona/selectors/isAce";

const LoadProcessName = 'AddressBook.ContactsPage.Load';
const SaveProcessName = 'AddressBook.ContactsPage.Save';
const DeleteProcessName = 'AddressBook.ContactsPage.Delete';

const GeneralRole = Business.Company.CompanyRoles.General;

const ContactsPage = (props) => {
  const {
    company,
    contacts,
    dispose,
    match,
    load,
    saveContact,
    deleteContact,
    sendSnackbarMessage,
    isAce,
    modifyingAssociate,
  } = props;

  const companyId = parseInt(match.params.id);
  const generalContacts = contacts
    .filter(c => c.companyContactRoles.some(ccr => ccr.companyRoleId === GeneralRole.Id));

  const [draftContact, setDraftContact] = React.useState(null);
  const [deleteCandidate, setDeleteCandidate] = React.useState(null);

  React.useEffect(() => {
    load(companyId);
    return () => dispose();
  }, [companyId, dispose, load]);

  const handleNewContactClick = (e) =>
    setDraftContact({
      companyId,
      firstName: null,
      lastName: null,
      emailAddress: null,
      phone: null,
      cellPhone: null,
      faxPhone: null,
      isPrimary: false,
      companyContactRoles: [
        { companyId, companyRoleId: GeneralRole.Id }
      ]
    });

  const handleNewContactClose = (e) =>
    setDraftContact(null);

  const handleNewContactSubmit = (values, formFactor) => {
    setDraftContact(null);
    saveContact(values)
      .then(() => {
        sendSnackbarMessage({ content: 'Contact saved.' });
      });
  };

  const handleEditContactClick = (contact) => {
    setDraftContact(contact);
  };

  const handleDeleteContactClick = (contact) => {
    setDeleteCandidate(contact);
  };

  const handleDeleteContactClose = (e) =>
  setDeleteCandidate(null);

  const handleDeleteContactConfirm = (e) => {
    setDeleteCandidate(null);
    deleteContact(deleteCandidate.companyId, deleteCandidate.id)
      .then(() => {
        sendSnackbarMessage({ content: `Contact [${deleteCandidate.firstName} ${deleteCandidate.lastName}] deleted.`, });
      });
  };

  const deleteActions = [
    {
      title: 'Cancel',
      action: handleDeleteContactClose
    },
    {
      title: 'Confirm',
      action: handleDeleteContactConfirm
    }
  ];

  const primaryContact = contacts.find(c => c.isPrimary);

  const contactInitialValues = {
    ...draftContact,
    primaryContactId: primaryContact && primaryContact.id,
    onClose: handleNewContactClose
  };

  const title = composePageTitle('Address Book', 'Contacts', company?.name);

  const showPrimaryContactAlert = contacts.length === 0 ||
    contacts.every(c => !c.isPrimary);

  return (
    <FullWidthLayout SideNav={AddressBookRecordNav} title={title} className="address-book-styles">
      {company !== null ?
      <CheckIfAuthorized
        isAce={isAce}
        modifyingAssociate={modifyingAssociate}
        company={company} >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <AppButton startIcon={<Add />} onClick={handleNewContactClick}>New Contact</AppButton>
            </Grid>
            <Grid item xs={12}>
              <AppAlertStatic>
                Track company contacts on this page. Every contact at a company can be a valuable ally in growing your book of business and ensuring everyone is paid on time.
              </AppAlertStatic>
            </Grid>
            <Grid item xs={12}>
              {
                showPrimaryContactAlert && <AppAlertStatic color={'warning'}>
                  At least one contact must be marked as Primary in order for this company to be used on a shipment.
                </AppAlertStatic>
              }
            </Grid>
            <Grid item xs={12}>
              <ContactsListing
                contacts={generalContacts}
                onEditClick={handleEditContactClick}
                onDeleteClick={handleDeleteContactClick}
              />
            </Grid>
            <Grid item xs={12}>
              {
                draftContact && <FormFactor
                  initialValues={contactInitialValues}
                  schema={NewContactSchema}
                  onSubmit={handleNewContactSubmit}>
                  {renderModifyContactForm}
                </FormFactor>
              }
            </Grid>
          </Grid>
          {
            deleteCandidate && <AppDialog
              title={'Contact Deletion'}
              width={'sm'}
              open={true}
              onClose={handleDeleteContactClose}
              actionButtons={deleteActions}>
              Confirm that you want to delete contact [{deleteCandidate.firstName} {deleteCandidate.lastName}]?
            </AppDialog>
          }
      </CheckIfAuthorized>
      :
      <MissingOrDeniedCompanyAlert />}
    </FullWidthLayout>
  );
};

const NewContactSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .required('First Name is required.'),
  emailAddress: Yup.string()
    .nullable()
    .email('Email is invalid.'),
  phone: Yup.string()
    .nullable()
    .matches(Core.Text.PhoneRegExp, 'Direct Number is invalid.'),
  cellPhone: Yup.string()
    .nullable()
    .matches(Core.Text.PhoneRegExp, 'Cell Phone is invalid.'),
});

export default ComponentBuilder
  .wrap(ContactsPage)
  .stateToProps((state, ownProps) => {
    return {
      isAce: isAce(state),
      modifyingAssociate: state.persona.modifyingAssociate,
      company: state.addressBook.modification.company,
      contacts: state.addressBook.modification.contacts
        .filter(c => c.companyContactRoles.some(ccr => ccr.companyRoleId === GeneralRole.Id))
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        const actions = await Promise.all([
          shell.actions.addressBook.modification.loadCompany(companyId),
          shell.actions.addressBook.modification.loadCompanyContacts(companyId)
        ]);
        actions.forEach(dispatch);
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async saveContact(contact) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        dispatch(await shell.actions.addressBook.modification.saveCompanyContact(contact));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));
      },
      async deleteContact(companyId, contactId) {
        dispatch(shell.actions.sys.processStart(DeleteProcessName));
        dispatch(await shell.actions.addressBook.modification.deleteCompanyContact(companyId, contactId));
        dispatch(shell.actions.sys.processComplete(DeleteProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    }
  })
  .build();