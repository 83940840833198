import React from 'react';
import AppDenseGrid from '../../../../core/components/AppDenseGrid/AppDenseGrid';
import AppDateTimeText from '../../../../core/components/text/AppDateTimeText';

const RefNumCell = ({ rowData: record }) =>
  [record.refNum1Description, record.refNum1]
    .filter(x => x)
    .join(' ');


const TrackingNoteAuditCell = ({ rowData: record }) => {

  if(!(record.loadTrackingAssociateShortName && record.loadTrackingCreateDate))
    return null;

  return (
    <React.Fragment>
      <strong>{record.loadTrackingAssociateShortName}</strong> (<AppDateTimeText value={record.loadTrackingCreateDate} />)
    </React.Fragment>
  )
};

const ShipmentsListingPage = (props) => {

  const {
    count,
    data,
    orderBy,
    order,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = props;


  const GridColumns = [
    {
      field: 'bolNumber',
      title: 'Ship #',
      dataType: 'component',
      styles: {
        width: 55,
      },
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/shipment/${record.bolNumber}/details`, record.bolNumber])
    },
    {
      field: 'associateShortName',
      title: 'Rep',
      dataType: 'string',
      styles: {
        width: 40
      },
    },
    {
      field: 'bolDate',
      title: 'Pickup Date',
      dataType: 'date',
      styles: {
        width: 60
      }
    },
    {
      field: 'loadTrackingPhase',
      title: 'Phase',
      dataType: 'string',
      styles: {
        width: 80
      },
    },
    {
      field: 'companyName',
      title: 'Customer',
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/address-book/${record.companyId}/details`, record.companyName]),
      styles: {
        width: 180
      },
    },
    {
      field: 'customerRep',
      title: 'Contact',
      dataType: 'string',
      styles: {
        width: 80
      },
    },
    {
      field: 'refNum1',
      title: 'Reference #',
      dataType: 'component',
      styles: {
        width: 100
      },
      component: RefNumCell
    },
    {
      field: 'freightCategory',
      title: 'Type',
      dataType: 'string',
      styles: {
        width: 25
      },
    },
    {
      field: 'carrierName',
      title: 'Carrier',
      dataType: 'component',
      component: AppDenseGrid.Cells
        .LinkCell((record) => [`/carrier/${record.carrierMcNumber}/compliance`, record.carrierName]),
      styles: {
        width: 120
      },
    },
    {
      field: 'proNumber',
      title: 'Tracking #',
      dataType: 'string',
      styles: {
        width: 80
      },
    },
    {
      field: 'loadTrackingNote',
      title: 'Notes',
      dataType: 'string',
      styles: {
        width: 210
      },
    },
    {
      field: 'loadTrackingAssociateShortName',
      title: 'Rep / Date',
      dataType: 'component',
      styles: {
        width: 100
      },
      component: TrackingNoteAuditCell
    }
  ];

  return (
    <AppDenseGrid
      count={count}
      data={data}
      orderBy={orderBy}
      order={order}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSort={onSort}
      columns={GridColumns}
    />
  )
};

export default ShipmentsListingPage;