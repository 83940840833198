import React from 'react';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppMuiTable from '../../../../core/components/AppDenseGrid';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import {CheckCircle, Edit} from '@material-ui/icons';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';
import RoleNames from '../../../../hubs/persona/RoleNames';
import FormFactorGraft from '../../../../core/FormFactor/FormFactorGraft';
import {Link} from 'react-router-dom';

const IsActiveCell = ({ rowData: associate }) =>
  associate.isActive ?
    <CheckCircle color={'primary'} fontSize={'small'} /> :
    null;

const ControlsCell = ({ rowData: associate }) =>
  <AppIconButton to={`/admin/manage-users/${associate.id}`} Icon={Edit} component={Link} />

const JackListingCard = (props) => {

  const {
    jackAssociates = []
  } = props;

  const tableColumns = [
    {
      title: 'Last Name',
      field: 'lastName',
      noSort: true,
      styles: {
        width: 40,
      },
      dataType: 'string'
    },
    {
      title: 'First Name',
      field: 'firstName',
      noSort: true,
      styles: {
        width: 40,
      },
      dataType: 'string'
    },
    {
      title: 'System ID',
      field: 'systemId',
      noSort: true,
      styles: {
        width: 30,
      },
      dataType: 'string'
    },
    {
      title: 'Account Active',
      field: 'isActive',
      noSort: true,
      styles: {
        width: 30,
        textAlign: 'center'
      },
      dataType: 'component',
      component: IsActiveCell
    },
    {
      title: ' ',
      field: 'editButtons',
      noSort: true,
      styles: {
        width: 20,
        textAlign: 'center'
      },
      dataType: 'component',
      component: ControlsCell
    }
  ];

  const hasJacks = jackAssociates.length > 0;

  const jacksWarningSlices = ['values.selectedRole'];
  const jacksWarningGraft = ([selectedRole]) => ({ selectedRole });

  return (
    <AppMuiCard title={'Jacks'}>

      <FormFactorGraft
        as={DisallowedJacksWarning}
        slices={jacksWarningSlices}
        graftMap={jacksWarningGraft}
        hasJacks={hasJacks}
      />

      { !hasJacks && 'Associate has no jacks assigned.' }

      {
        hasJacks &&
        <AppMuiTable
          columns={tableColumns}
          data={jackAssociates}
          count={jackAssociates.length}
          />
      }

    </AppMuiCard>
  );
};

const DisallowedJacksWarning = (props) => {

  const {
    hasJacks,
    selectedRole
  } = props;

  const canHaveJacks = selectedRole?.name === RoleNames.Queen;

  if (!hasJacks || canHaveJacks)
    return null;

  return (
    <div style={{ marginBottom: 10 }}>
      <AppAlertStatic color={'warning'}>
        Associate role [{selectedRole?.name}] shouldn't have jacks.
      </AppAlertStatic>
    </div>
  );
};

export default JackListingCard;