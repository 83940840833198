import Qs from 'qs';

const coerceDataSet = (data) => {
  return data.map((row) => {
    return {
      year: parseInt(row.year),
      quarter: row.quarter ? parseInt(row.quarter) : null,
      month: row.month ? parseInt(row.month) : null,
      week: row.week ? parseInt(row.week) : null,
      day: row.day ? parseInt(row.day) : null,
      shipmentCount: parseInt(row.shipmentCount) || 0,
      totalAge: parseInt(row.averageAge) || 0,
      averageAge: parseFloat(row.averageAge) || 0,
      maxAge: parseInt(row.maxAge) || 0,
      minAge: parseInt(row.minAge) || 0,
      totalOverdueAge: parseInt(row.averageOverdueAge) || 0,
      averageOverdueAge: parseFloat(row.averageOverdueAge) || 0,
      maxOverdueAge: parseInt(row.maxOverdueAge) || 0,
      minOverdueAge: parseInt(row.minOverdueAge) || 0,
      totalCustomerCost: parseFloat(row.totalCustomerCost) || 0,
      totalCarrierCost: parseFloat(row.totalCarrierCost) || 0,
      totalAdjCustomerCost: parseFloat(row.totalAdjCustomerCost) || 0,
      totalAdjCarrierCost: parseFloat(row.totalAdjCarrierCost) || 0,
      averageCustomerCost: parseFloat(row.averageCustomerCost) || 0,
      averageCarrierCost: parseFloat(row.averageCarrierCost) || 0,
      averageAdjCustomerCost: parseFloat(row.averageAdjCustomerCost) || 0,
      averageAdjCarrierCost: parseFloat(row.averageAdjCarrierCost) || 0,
      maxCustomerCost: parseFloat(row.maxCustomerCost) || 0,
      maxCarrierCost: parseFloat(row.maxCarrierCost) || 0,
      maxAdjCustomerCost: parseFloat(row.maxAdjCustomerCost) || 0,
      maxAdjCarrierCost: parseFloat(row.maxAdjCarrierCost) || 0,
      minCustomerCost: parseFloat(row.minCustomerCost) || 0,
      minCarrierCost: parseFloat(row.minCarrierCost) || 0,
      minAdjCustomerCost: parseFloat(row.minAdjCustomerCost) || 0,
      minAdjCarrierCost: parseFloat(row.minAdjCarrierCost) || 0,
      totalMargin: parseFloat(row.totalMargin) || 0,
      averageMargin: parseFloat(row.averageMargin) || 0,
      maxMargin: parseFloat(row.maxMargin) || 0,
      minMargin: parseFloat(row.minMargin) || 0,
      averageMarginPercent: parseFloat(row.averageMarginPercent) || 0,
      maxMarginPercent: parseFloat(row.maxMarginPercent) || 0,
      minMarginPercent: parseFloat(row.minMarginPercent) || 0
    };
  });
};

const loadFinancialHealthDataSet = async (companyId, timePeriod, startDate, endDate) => {
  try {
    const qsParams = {
      timePeriod: timePeriod,
      startDate: startDate,
      endDate: endDate
    };

    const qs = Qs.stringify(qsParams);

    const results = await window.shell.gateway.communicator.get(`/company/${companyId}/financial-health?${qs}`);

    // Reverse order for charting.
    return coerceDataSet(results.reverse());
  }
  catch (error) {
    console.error('Failed to load Financial Health DataSet.', { companyId, timePeriod, startDate, endDate });
    console.error(error);

    // Fail safely by returning an empty array.
    return [];
  }
};

export default loadFinancialHealthDataSet;