import dispose from './dispose';
import loadAssociates from './loadAssociates';
import loadAssociate from './loadAssociate'
import loadAssociateJacks from './loadAssociateJacks';
import loadAssociateNotes from './loadAssociateNotes';
import loadAssociateNoteTypes from './loadAssociateNoteTypes';
import loadRoles from './loadRoles';
import saveAssociate from './saveAssociate';
import saveAssociateNote from './saveAssociateNote';

export default {
  dispose,
  loadAssociates,
  loadAssociate,
  loadAssociateJacks,
  loadAssociateNotes,
  loadAssociateNoteTypes,
  loadRoles,
  saveAssociate,
  saveAssociateNote
};