import React from "react";
import {Grid} from '@material-ui/core'
import {Lock, Save, Undo, SettingsEthernet, FileCopy, Assignment} from '@material-ui/icons';
import Core from '@atomos/core';
import { Invoice, Shipment } from '@tgf-crm/business';
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import DetailCard from './includes/DetailCard';

import ReferenceNumberCard from "./includes/ReferenceNumberCard/ReferenceNumberCard";
import GeneralNotesCard from "./includes/GeneralNotesCard";
import CarrierContactDetailCard from "./includes/CarrierContactDetailCard";
import DriverDetailCard from "./includes/DriverDetailCard";
import isHazardous from "../../../hubs/shipment/actions/modification/isHazardous";
import ChangeStatusDialog from './includes/ChangeStatusDialog';
import LockShipmentDialog from './includes/LockShipmentDialog';
import StatusTypeNames from '../../../hubs/shipment/StatusTypeNames';
import AppTooltip from '../../../core/components/AppTooltip';
import {Link} from 'react-router-dom';

const InvoiceAssertionsFactory = Invoice.InvoiceAssertions;
const ShipmentAssertionsFactory = Shipment.ShipmentAssertions;
const ShipmentInvoiceAssertionsFactory = Shipment.ShipmentInvoiceAssertions;

const renderDetailForm = ({values, supportingValues, errors, isValid, isDirty, setFieldValue, setFieldValues, setSupportingValue, submitForm, resetForm}) => {

  const invoiceAssertions = InvoiceAssertionsFactory();
  const shipmentAssertions = ShipmentAssertionsFactory(values.shipmentStatus, values.freightCategoryTypes);
  const shipmentInvoiceAssertions = ShipmentInvoiceAssertionsFactory(shipmentAssertions, invoiceAssertions);
  const permManager = new Shipment.ShipmentStatusPermissionManager(invoiceAssertions);

  const associateFullName = values.shipment ?
    `${Core.Utils.get(values, 'shipment.associate.firstName')} ${Core.Utils.get(values, 'shipment.associate.lastName')} ` :
    '';

  const detailCardProps = {
    selectedShipmentStatus: values.selectedShipmentStatus,
    selectedFreightCategory: values.selectedFreightCategory,
    selectedEquipmentType: values.selectedEquipmentType,
    bolDate: values.bolDate,
    estimatedDeliveryDate: values.estimatedDeliveryDate,
    pickupTime: values.pickupTime,
    deliveryTime: values.deliveryTime,
    customerRep: values.customerRep,
    shipmentStatus: values.shipmentStatus,
    freightCategoryTypes: values.freightCategoryTypes,
    candidateEquipmentTypes: values.candidateEquipmentTypes,
    equipmentTypes: values.equipmentTypes,
    selectedCustomer: values.selectedCustomer,
    selectedConsignee: values.selectedConsignee,
    selectedShipper: values.selectedShipper,
    selectedThirdParty: values.selectedThirdParty,
    isMultipleStop: values.isMultipleStop,
    selectedCarrier: values.selectedCarrier,
    candidateCarriers: values.candidateCarriers,
    isNew: values.isNew,
    isLoadingCandidateCarriers: values.isLoadingCandidateCarriers,
    getCarrier: values.getCarrier,
    allowDeliveryDate: values.isTlCategory,
    lockThirdParty: values.isTlCategory,
    thirdPartyCompanies: values.defaultThirdPartyCompanies,
    tallgrassBillingCompany: values.tallgrassBillingCompany,
    hasCustomerChanged: values.hasCustomerChanged,
    lockedAssociate: values.lockedAssociate,
    lockedDate: values.lockedDate,
    isHardCopyPodRequired: Boolean(values.selectedCustomer?.companyIsPodRequired),
    setFieldValue,
    setFieldValues,
    errors
  };

  const referenceNumberCardProps = {
    isAce: values.isAce,
    selectedShipmentStatus: values.selectedShipmentStatus,
    pickupNumber: values.pickupNumber,
    deliveryNumber: values.deliveryNumber,
    refNum1Description: values.refNum1Description,
    refNum1: values.refNum1,
    refNum2Description: values.refNum2Description,
    refNum2: values.refNum2,
    refNum3Description: values.refNum3Description,
    refNum3: values.refNum3,
    refNum4Description: values.refNum4Description,
    refNum4: values.refNum4,
    isHazardousMaterialsShipment: isHazardous(values.hazardousStates, values.hazardousStateId),
    isBlind: values.isBlind,
    isDeliveryOrderRequired: values.isDeliveryOrderRequired,
    hazardousStates: values.hazardousStates,
    setFieldValue
  };

  const GeneralNotesCardProps = {
    isAce: values.isAce,
    selectedShipmentStatus: values.selectedShipmentStatus,
    note: values.note,
    rateConNote: values.rateConNote,
    ratingRefNumber: values.ratingRefNumber,
    proNumber: values.proNumber,
    isTlCategory: values.isTlCategory,
    allowRatingNote: values.isTlCategory,
    allowRatingRef: !values.isTlCategory,
    setFieldValue
  };

  const CarrierContactDetailCardProps = {
    isAce: values.isAce,
    selectedShipmentStatus: values.selectedShipmentStatus,
    experienceNote: values.experienceNote,
    repName: values.repName,
    repPhone: values.repPhone,
    repEmail: values.repEmail,
    setFieldValue,
    repPhoneError: errors.repPhone,
    repEmailError: errors.repEmail
  };

  const DriverDetailCardProps = {
    isAce: values.isAce,
    selectedShipmentStatus: values.selectedShipmentStatus,
    driverName: values.driverName,
    driverPhone: values.driverPhone,
    truckNumber: values.truckNumber,
    trailerNumber: values.trailerNumber,
    setFieldValue,
    driverPhoneError: errors.driverPhone
  };

  const handleResetClick = (e) =>
    resetForm();

  const lockedStatusType = values.shipmentStatus
    .find(st => st.name === StatusTypeNames.Locked);

  // Map the availableStatusTypes into a structure that provides whether
  // the status can be changed and corresponding permission message.
  const availableStatusTypeOptions = values.shipmentStatus
    // Exclude LOCKED as the change status dialog can never switch to locked directly.
    .filter(st => st !== lockedStatusType)
    .map(statusType => {

      const permContext = {
        shipment: values.shipment,
        invoice: values.invoice,
        associate: values.associate,
        currentStatus: values.selectedShipmentStatus.name,
        proposedStatus: statusType.name
      };

      const permission = permManager.getPermission(permContext);

      if (!permission || !(permission.canView() || permission.canAction())) {
        return null;
      }

      return {
        statusType,
        canChange: permission.canAction(),
        message: permission.message
      };
    })
    .filter(x => Boolean(x));

  // The user can change the status when the form isn't dirty and they have
  // available status types to change to.
  const canChangeStatus = !isDirty && availableStatusTypeOptions.length > 0;

  // Determine whether the current shipment can be locked using the button.
  const shipmentReadyToLock = values.shipment &&
    values.invoice &&
    !isDirty &&
    shipmentAssertions.readyToLock.matches(values.shipment);

  // Change the lock confirmation message according to admin vs agent.
  const lockConfirmMessage = values.isAce ?
    adminConfirmMessage(values.shipment.bolNumber, values.selectedShipper, values.selectedConsignee) :
    agentConfirmMessage(values.shipment.bolNumber, values.selectedShipper, values.selectedConsignee);

  const handleChangeStatusClick = () => {
    setSupportingValue('showChangeStatusDialog', true);
  };
  const handleChangeStatusCancelClick = () => {
    setSupportingValue('showChangeStatusDialog', false);
  };
  const handleChangeStatusSaveClick = (statusType) => {
    const fieldValues = [
      ['statusId', statusType.id],
      ['selectedShipmentStatus', statusType]
    ];

    // When switching to hold we may need to auto-audit the shipment/invoice.
    if (statusType.name === StatusTypeNames.Hold) {

      // If the shipment/invoice is should be auto-audited then assign
      // the appropriate dates, amount, and capture the associate switching it to hold.
      if (shipmentInvoiceAssertions.shouldBeAutoAudited.matches({ shipment: values.shipment, invoice: values.invoice })) {
        const auditDate = new Date();
        const dueDate = auditDate.toMoment().add(30, 'days').toDate();
        fieldValues.push(['invoice.auditDate', auditDate]);
        fieldValues.push(['invoice.auditFinalizedDate', auditDate]);
        fieldValues.push(['invoice.invoiceDueDate', dueDate]);
        fieldValues.push(['invoice.finalizingAssociateId', values.associate.id]);
        fieldValues.push(['invoice.invoicedAmount', values.invoice.adjustedCarrierCost]);
      }

    }

    setFieldValues(fieldValues).then(() => {
      setSupportingValue('showChangeStatusDialog', false);
      submitForm();
    });

  };
  const handleLockClick = () => {
    setSupportingValue('showLockDialog', true);
  };
  const handleLockCancelClick = () => {
    setSupportingValue('showLockDialog', false);
  };
  const handleLockSaveClick = () => {
    setFieldValues([
      ['statusId', lockedStatusType.id],
      ['selectedShipmentStatus', lockedStatusType],
      ['lockedAssociateId', values.associate.id],
      ['lockedDate', new Date()]
    ]).then(() => {
      setSupportingValue('showLockDialog', false);
      submitForm()
    });
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <AppButton type={'submit'} startIcon={<Save/>} disabled={!(isValid && isDirty)}>Save</AppButton>
          </Grid>
          <Grid item>
            <AppButton type={'button'} startIcon={<Undo/>} color={'warning'} disabled={!isDirty}
                       onClick={handleResetClick}>Reset</AppButton>
          </Grid>
          {
            !values.isNew &&
            <React.Fragment>
              <Grid item>
                <AppButton
                  type={'button'}
                  startIcon={<SettingsEthernet />}
                  onClick={handleChangeStatusClick}
                  disabled={!canChangeStatus}>
                  Change Status
                </AppButton>
              </Grid>
              <Grid item>
                <AppTooltip title={''}>
                  <AppButton type={'button'} startIcon={<Lock />} onClick={handleLockClick} disabled={!shipmentReadyToLock}>Lock</AppButton>
                </AppTooltip>
              </Grid>
              <Grid item>
                <AppButton
                  type={'button'}
                  component={Link}
                  to={`/shipment/${values.bolNumber}/create-docs`}
                  color={'primary'}
                  startIcon={<Assignment />}
                  disabled={isDirty}
                >
                  Create Docs
                </AppButton>
              </Grid>
              <Grid item>
                <AppButton
                  type={'button'}
                  component={Link}
                  to={`/shipment/${values.bolNumber}/duplicate`}
                  startIcon={<FileCopy/>}
                  disabled={isDirty}
                  color={'primary'}>
                  Duplicate
                </AppButton>
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </Grid>
      <Grid item xs={12} style={{textAlign: 'right'}}>
        <label>Author: <b>{associateFullName}</b></label>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <DetailCard
              {...detailCardProps}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ReferenceNumberCard
              {...referenceNumberCardProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <GeneralNotesCard
                  {...GeneralNotesCardProps}
                />
              </Grid>
              {
                values.isTlCategory &&
                <Grid item xs={12} md={4}>
                  <CarrierContactDetailCard
                    {...CarrierContactDetailCardProps}
                  />
                </Grid>
              }
              {
                values.isTlCategory &&
                <Grid item xs={12} md={4}>
                  <DriverDetailCard
                    {...DriverDetailCardProps}
                  />
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
        supportingValues?.showChangeStatusDialog &&
        <ChangeStatusDialog
          bolNumber={values.shipment.bolNumber}
          currentStatusType={values.selectedShipmentStatus}
          statusTypeOptions={availableStatusTypeOptions}
          onSave={handleChangeStatusSaveClick}
          onCancel={handleChangeStatusCancelClick}
        />
      }
      {
        supportingValues?.showLockDialog &&
        <LockShipmentDialog
          confirmMessage={lockConfirmMessage}
          onSave={handleLockSaveClick}
          onCancel={handleLockCancelClick}
          canSave={values.selectedShipper && values.selectedConsignee}
        />
      }
    </React.Fragment>
  )
};

const adminConfirmMessage = (bolNumber, selectedShipper, selectedConsignee) => (
  selectedShipper && selectedConsignee ?
    <p>
      Are you sure you want to lock shipment <b>#{bolNumber}</b>?
    </p> :
    <p style={{fontWeight: 600, color: "red"}}>
      This shipment cannot be locked at this time due to the Shipper and/or Consignee fields not being completed.  Contact management to assist.
    </p>

);

const agentConfirmMessage = (bolNumber, selectedShipper, selectedConsignee) => (
  selectedShipper && selectedConsignee ?
    <p>
      You are about to lock shipment <b>#{bolNumber}</b>. Please make sure your customer's profile has invoice instructions added and that the POD is attached to this shipment if your customer requires PODs for invoicing.
    </p> :
    <p style={{fontWeight: 600, color: "red"}}>
      This shipment cannot be locked at this time due to the Shipper and/or Consignee fields not being completed.  Contact management to assist.
    </p>
);

export default renderDetailForm;