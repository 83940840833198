import React, { useState } from 'react';
import {
  ListAlt,
  Assessment,
  Notes,
  LibraryAddCheck,
  LocalShipping,
  Assignment,
  ArtTrack
} from '@material-ui/icons';

import ComponentBuilder from '../../core/ComponentBuilder';
import AppSideBar from '../../core/components/AppSideBar';
import selectIsAce from '../../hubs/persona/selectors/isAce';
import FreightCategoryTypeNames from '../../hubs/shipment/FreightCategoryTypeNames';

const ShipmentUrl = '/shipment/';

const BaseLinks = (shipmentNumber) => [
  { title: 'Details', key: 'details', icon: ArtTrack, href: `${ShipmentUrl}${shipmentNumber}/details` },
];

const AdditionalLinks = (shipmentNumber) => [
  { title: 'Load Contents', key: 'contents', icon: Assignment, href: `${ShipmentUrl}${shipmentNumber}/contents` },
  { title: 'Tracking Notes', key: 'load-tracking', icon: Notes, href: `${ShipmentUrl}${shipmentNumber}/load-tracking` },
  { title: 'Documents', key: 'documents', icon: ListAlt, href: `${ShipmentUrl}${shipmentNumber}/documents` },
  { title: 'Financial', key: 'financial', icon: Assessment, href: `${ShipmentUrl}${shipmentNumber}/financial` }
];

const AuditLink = (shipmentNumber) => [
  { title: 'Auditing', key: 'auditing', icon: LibraryAddCheck, href: `${ShipmentUrl}${shipmentNumber}/auditing` }
];

const MultiStopLink = (shipmentNumber) => [
  { title: 'Multi-Stop', key: 'multiple-stops', icon: LocalShipping, href: `${ShipmentUrl}${shipmentNumber}/multiple-stops` }
];

const Wrapper = (props) => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppSideBar menuList={props.links || []} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open}/>
  );
};

export default ComponentBuilder
  .wrap(Wrapper)
  .stateToProps((state, ownProps) => {

    const shipment = state.shipment.modification.shipment;
    const freightCategoryTypes = state.support.freightCategoryTypes;

    if (!shipment || !freightCategoryTypes) {
      return { links: [] };
    }

    const isNew = shipment.bolNumber === 0;
    const defaultFreightCategoryType = freightCategoryTypes.find(x => x.name === FreightCategoryTypeNames.Truckload);
    const isLoadTl = defaultFreightCategoryType && defaultFreightCategoryType.id === shipment.freightCategoryId;
    const isAce = selectIsAce(state);

    let links = [];

    if (isNew) {
      links = links.concat(BaseLinks('new'));
    }
    else {
      links = BaseLinks(shipment.bolNumber)
        .concat(AdditionalLinks(shipment.bolNumber));
    }

    if (isAce) {
      links = links.concat(AuditLink(shipment.bolNumber));
    }

    if (isLoadTl) {
      links = links.concat(MultiStopLink(shipment.bolNumber));
    }

    return {
      links
    };
  })
  .build();