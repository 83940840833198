import React from 'react';
import FinancialHealthLineChart from './FinancialHealthLineChart';
import {
  ChartDimensions,
  PaymentAgeChartDescriptor
} from './SupportTypes';
import ChartSettingsSummaryBar from './ChartSettingsSummaryBar';
import ChartLegendBar from './ChartLegendBar';
import AppNumberText from '../../../../core/components/text/AppNumberText';

const PaymentAgeChart = ({ company, settings: chartSettings, data }) => {

  const finalizer = ({ company, chartSettings, data, group, horizontalScale, verticalScale }) => {

    const paymentTerms = company?.paymentTerms;

    group.append('g')
      .append('line')
      .style('stroke', '#ff0000')
      .style('stroke-width', 1)
      .style('stroke-dasharray', '3,3')
      .attr('x1', ChartDimensions.LeftMargin)
      .attr('y1', verticalScale(paymentTerms))
      .attr('x2', ChartDimensions.Width)
      .attr('y2', verticalScale(paymentTerms));
  };

  return (
    <React.Fragment>
      <ChartSettingsSummaryBar settings={chartSettings} />
      <FinancialHealthLineChart
        finalizer={finalizer}
        company={company}
        settings={chartSettings}
        chartDescriptor={PaymentAgeChartDescriptor}
        data={data}
      />
      <ChartLegendBar chartDescriptor={PaymentAgeChartDescriptor} settings={chartSettings} data={data} AggregateText={AppNumberText} />
    </React.Fragment>
  );
};

export default PaymentAgeChart;