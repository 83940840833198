import React from 'react';
import FinancialHealthLineChart from './FinancialHealthLineChart';
import ChartSettingsSummaryBar from './ChartSettingsSummaryBar';
import {
  MoneyChartDescriptor
} from './SupportTypes';
import ChartLegendBar from './ChartLegendBar';

const MoneyChart = ({ company, settings: chartSettings, data }) => {

  return (
    <React.Fragment>
      <ChartSettingsSummaryBar settings={chartSettings} />
      <FinancialHealthLineChart
        company={company}
        settings={chartSettings}
        chartDescriptor={MoneyChartDescriptor}
        data={data}
      />
      <ChartLegendBar chartDescriptor={MoneyChartDescriptor} settings={chartSettings} data={data} />
    </React.Fragment>
  );
};

export default MoneyChart;