import React from 'react';
import { Grid } from '@material-ui/core';
import { LibraryBooks as LibraryBooksIcon } from '@material-ui/icons';

import ComponentBuilder from '../../../../core/ComponentBuilder';
import AppNumericInput from '../../../../core/components/inputs/AppNumericInput';
import AppDatePicker from '../../../../core/components/inputs/AppDatePicker';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';
import AppValidationFailure from '../../../../core/components/AppValidationFailure';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";

const CopyCarrierCostTooltip = 'Use adjusted carrier cost.';
const PendingFinalizePrefix = 'Marked as finalized by';
const FinalizePrefix = 'Finalized by';

const AuditConfirmation = (props) => {

  const {
    invoicedAmount,
    invoiceAuditDate,
    invoiceDueDate,
    invoicedAmountError,
    invoiceAuditDateError,
    invoiceDueDateError,
    finalizingAssociateName,
    auditFinalizedDate,
    markAsFinalized,
    shipmentFinalized,
    onCopyCarrierCost,
    onFieldChange,
    onMarkFinalized,
    userPermissionMatrix
  } = props;

  const handleDateFieldChange = (key) => (date, value) => {
    const fieldDate = date && date.isValid() ?
      date.toDate() : null;
    onFieldChange(key, fieldDate);
  };

  return (
    <AppMuiCard
      title="Audit Confirmation"
      action={
        <AppIconButton
          color={'primary'}
          key={'copy'}
          disabled={shipmentFinalized}
          Icon={LibraryBooksIcon}
          tooltip={CopyCarrierCostTooltip}
          onClick={onCopyCarrierCost}/>
      }>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <label htmlFor={'invoicedAmount'}>
                Invoiced Amount
              </label>
            </Grid>
            <Grid item xs={12}>
              <AppNumericInput
                id={'invoicedAmount'}
                decimalScale={2}
                label=""
                disabled={shipmentFinalized}
                value={invoicedAmount}
                onChange={e => onFieldChange('invoicedAmount', e.target.value)}
              />
              <AppValidationFailure message={invoicedAmountError} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AppDatePicker
            id={'invoiceAuditDate'}
            label={'Audit Date'}
            disabled={shipmentFinalized}
            value={invoiceAuditDate}
            onChange={handleDateFieldChange('invoiceAuditDate')}
          />
          <AppValidationFailure message={invoiceAuditDateError} />
        </Grid>
        <Grid item xs={12}>
          <AppDatePicker
            id={'invoiceDueDate'}
            label={'Invoice Due Date'}
            disabled={shipmentFinalized}
            value={invoiceDueDate}
            onChange={handleDateFieldChange('invoiceDueDate')}
          />
          <AppValidationFailure message={invoiceDueDateError} />
        </Grid>
        <Grid item xs={12}>
          <FinalizePanel
            finalizingAssociateName={finalizingAssociateName}
            auditFinalizedDate={auditFinalizedDate}
            markAsFinalized={markAsFinalized}
            onMarkFinalized={onMarkFinalized}
            userPermissionMatrix={userPermissionMatrix}
          />
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

const FinalizePanel = (props) => {
  const {
    finalizingAssociateName,
    auditFinalizedDate,
    markAsFinalized
  } = props;

    return (
      (finalizingAssociateName && auditFinalizedDate) ?
      <Finalized
        isPending={markAsFinalized}
        name={finalizingAssociateName}
        date={auditFinalizedDate.toMoment().format('L LT')}
      /> : null
    );
};

const Finalized = ({ isPending, name, date }) => {

  const color = isPending ?
    'warning' : 'info';

  const prefix = isPending ?
    PendingFinalizePrefix : FinalizePrefix;

  return (
    <AppAlertStatic color={color} icon={false}>
      {prefix} {name} on {date}
    </AppAlertStatic>
  );
}

const styles = (theme) => ({
  copyCarrierCostButton: {
    padding: 0
  },
});

export default ComponentBuilder
  .wrap(AuditConfirmation)
  .withStyles(styles)
  .build();