const DefaultChartManifest = [
  {
    id: 1,
    title: 'Quarterly Aging',
    type: 'payment-age',
    timePeriod: 'quarter',
    startDate: null,
    endDate: null,
    aggregateType: 'average',
    features: {
      age: true,
      overdueAge: true
    }
  },
  {
    id: 2,
    title: 'Monthly Margin & Customer Cost',
    type: 'money',
    timePeriod: 'month',
    startDate: null,
    endDate: null,
    aggregateType: 'average',
    features: {
      adjCustomerCost: true,
      margin: true
    }
  },
  {
    id: 3,
    title: 'Monthly Shipment Volume',
    type: 'volume',
    timePeriod: 'month',
    startDate: null,
    endDate: null,
    aggregateType: 'total',
    features: {
      volume: true
    }
  }
];

export default DefaultChartManifest;