import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import AppMuiPhoneInput from '../../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput';
import FormFactorGraft from '../../../../core/FormFactor/FormFactorGraft';
import PasswordField from './PasswordField';
import FormFactorValueInput from '../../../../core/FormFactor/FormFactorValueInput';
import AppMuiSwitch from '../../../../core/components/inputs/AppSwitch';
import FormFactorValueDropdown from '../../../../core/FormFactor/FormFactorValueDropdown';
import RoleNames from '../../../../hubs/persona/RoleNames';
import AppMuiCard from '../../../../core/components/cards/AppCard';
import QueenSelectorField from './QueenSelectorField';
import FormFactorValidationFailure from '../../../../core/FormFactor/FormFactorValidationFailure';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    rateConHeader: {
      marginTop: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.tgfGrey.border}`,
      textAlign: 'center'
    }
  };
});

const AssociateForm = (props) => {
  const classes = useStyles();

  const {
    sendSnackbarMessage
  } = props;

  const passwordFieldSlices = [
    'values.associate.decryptedPassword',
    'setFieldValues'
  ];
  const passwordFieldGraftMap = ([password, setFieldValues]) => {
    return {
      password,
      onPasswordChange: (newPassword) => {
        setFieldValues([
          ['associate.decryptedPassword', newPassword],
          ['associate.password', newPassword]
        ]);
      }
    };
  };

  const parentQueenSelectionSlices = [
    'values.selectedRole',
    'values.selectedParent',
    'supportingValues.queenRole',
    'setFieldValues'
  ];
  const parentQueenSelectionGraftMap = ([selectedRole, selectedParent, queenRole, setFieldValues]) => ({
    disabled: selectedRole?.name !== RoleNames.Jack,
    value: selectedParent,
    roleId: queenRole.id,
    onSelectAssociate: (associate) => {
      setFieldValues([
        ['parentAssociateId', associate?.id || null],
        ['selectedParent', associate]
      ]);
    }
  });

  return (
    <AppMuiCard title={'Details'}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <FormFactorValueInput
            field={'associate.firstName'}
            id={'firstName'}
            label={'First Name'}
            inputProps={{ maxLength: 50 }}
          />
          <FormFactorValidationFailure field={'associate.firstName'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormFactorValueInput
            field={'associate.lastName'}
            id={'lastName'}
            label={'Last Name'}
            inputProps={{ maxLength: 50 }}
          />
          <FormFactorValidationFailure field={'associate.lastName'} />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={9}>
            <FormFactorValueInput
              field={'associate.emailAddress'}
              id={'emailAddress'}
              label={'Email'}
              inputProps={{ maxLength: 50 }}
            />
            <FormFactorValidationFailure field={'associate.emailAddress'} />
          </Grid>
          <Grid item xs={3}>
            <FormFactorValueInput
              field={'associate.systemId'}
              id={'systemId'}
              label={'CRM ID'}
              inputProps={{ maxLength: 10 }}
            />
            <FormFactorValidationFailure field={'associate.systemId'} />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <FormFactorGraft
            slices={passwordFieldSlices}
            graftMap={passwordFieldGraftMap}
            as={PasswordField}
            sendSnackbarMessage={sendSnackbarMessage}
          />
          <FormFactorValidationFailure field={'associate.decryptedPassword'} />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={8}>
            <FormFactorValueInput
              field={'associate.businessPhone'}
              as={AppMuiPhoneInput}
              id={'businessPhone'}
              label={'Business Phone'}
            />
            <FormFactorValidationFailure field={'associate.businessPhone'} />
          </Grid>
          <Grid item xs={2}>
            <FormFactorValueInput
              field={'associate.businessPhoneExt'}
              id={'businessPhoneExt'}
              label={'Ext'}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormFactorValueInput
              field={'associate.faxPhone'}
              as={AppMuiPhoneInput}
              id={'businessFax'}
              label={'Business Fax'}
            />
            <FormFactorValidationFailure field={'associate.faxPhone'} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormFactorValueDropdown
              formField={'selectedRole'}
              optionsFormField={'roles'}
              optionField={'name'}
              optionFieldIndex={'id'}
              id={'selectedRole'}
              label={'Role'}
          />
          <FormFactorValidationFailure field={'selectedRole'} />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormFactorGraft
              slices={parentQueenSelectionSlices}
              graftMap={parentQueenSelectionGraftMap}
              as={QueenSelectorField}
          />
          <FormFactorValidationFailure field={'selectedParent'} />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormFactorValueInput
              field={'associate.isActive'}
              as={AppMuiSwitch}
              valueProp={'checked'}
              valueDefault={false}
              eventValueMap={(e) => e.target.checked}
              id={'isActive'}
              label={'Account Active'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormFactorValueInput
              field={'associate.displayPayouts'}
              as={AppMuiSwitch}
              valueProp={'checked'}
              valueDefault={false}
              eventValueMap={(e) => e.target.checked}
              additionalGraft={([disabled]) => ({ disabled })}
              id={'displayPayouts'}
              label={'Display Payouts'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormFactorValueInput
            field={'associate.openLinksInNewTab'}
            as={AppMuiSwitch}
            valueProp={'checked'}
            valueDefault={false}
            eventValueMap={(e) => e.target.checked}
            additionalGraft={([disabled]) => ({ disabled })}
            id={'openLinksInNewTab'}
            label={'Open Links In New Tab'}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormFactorValueInput
              field={'associate.hasCarrierPrivilege'}
              as={AppMuiSwitch}
              valueProp={'checked'}
              valueDefault={false}
              eventValueMap={(e) => e.target.checked}
              additionalSlices={['supportingValues.permissionFieldsDisabled']}
              additionalGraft={([disabled]) => ({ disabled })}
              id={'hasCarrierPrivilege'}
              label={'Carrier Privilege'}
          />
        </Grid>
        <Grid item xs={12}  md={6}>
          <FormFactorValueInput
              field={'associate.collectionRole'}
              as={AppMuiSwitch}
              valueProp={'checked'}
              valueDefault={false}
              eventValueMap={(e) => e.target.checked}
              additionalSlices={['supportingValues.permissionFieldsDisabled']}
              additionalGraft={([disabled]) => ({ disabled })}
              id={'collectionRole'}
              label={'Collections Role'}
          />
        </Grid>
        <Grid item xs={12} className={classes.rateConHeader}>
          <Typography variant={'h4'}>
            Rate Confirmation Information
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={8}>
            <FormFactorValueInput
              field={'associate.rateConBusinessPhone'}
              as={AppMuiPhoneInput}
              id={'rateConBusinessPhone'}
              label={'Business Phone'}
            />
            <FormFactorValidationFailure field={'associate.rateConBusinessPhone'} />
          </Grid>
          <Grid item xs={2}>
            <FormFactorValueInput
              field={'associate.rateConBusinessPhoneExt'}
              id={'rateConBusinessPhoneExt'}
              label={'Ext'}
              inputProps={{ maxLength: 10 }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormFactorValueInput
              field={'associate.rateConFaxPhone'}
              as={AppMuiPhoneInput}
              id={'rateConFaxPhone'}
              label={'Business Fax'}
            />
            <FormFactorValidationFailure field={'associate.rateConFaxPhone'} />
          </Grid>
          <Grid item xs={12}>
            <FormFactorValueInput
              field={'associate.rateConEmailAddress'}
              id={'rateConEmailAddress'}
              label={'Email'}
              inputProps={{ maxLength: 100 }}
            />
            <FormFactorValidationFailure field={'associate.rateConEmailAddress'} />
          </Grid>
        </Grid>
      </Grid>
    </AppMuiCard>
  );
};

export default AssociateForm;