import React from 'react';
import {Grid} from '@material-ui/core';
import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppMuiInput from '../../../../core/components/inputs/AppInput';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';

const ChangeStatusDialog = (props) => {

  const {
    currentStatusType,
    statusTypeOptions,
    bolNumber,
    onSave,
    onCancel
  } = props;

  const [canChangeStatus, setCanChangeStatus] = React.useState(false);
  const [message, setMessage] = React.useState(null);
  const [statusType, setStatusType] = React.useState(null);

  const handleSaveClick = () => {
    onSave(statusType);
  };

  const dialogActions = [
    {
      title: 'Cancel',
      action: onCancel
    },
    {
      title: 'Save',
      action: handleSaveClick,
      disabled: !canChangeStatus
    }
  ];

  const title = `Change Status for Shipment #${bolNumber}`;

  const statusTypes = statusTypeOptions.map(st => st.statusType);

  const handleStatusTypeChange = (e, statusType) => {
    const statusTypeOption = statusTypeOptions.find(sto => sto.statusType.id === statusType.id);
    setCanChangeStatus(statusTypeOption.canChange);
    setMessage(statusTypeOption.message);
    setStatusType(statusType);
  };

  return (
    <AppDialog
      title={title}
      open={true}
      onClose={onCancel}
      width={'sm'}
      actionButtons={dialogActions}>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <AppMuiInput
            readOnly
            label={'Current Status'}
            value={currentStatusType?.name || ''}
            />
        </Grid>
        <Grid item xs={6}>
          <AppDropdown
            label={'New Status'}
            fieldIndex={'id'}
            field={'name'}
            options={statusTypes}
            onChange={handleStatusTypeChange}
          />
        </Grid>
        <Grid item xs={12}>
          {
            message &&
            <AppAlertStatic
              title={message}
              color={canChangeStatus ? 'warning' : 'error'}
            />
          }
        </Grid>
      </Grid>
    </AppDialog>
  );
};

export default ChangeStatusDialog;