import ActionBuilder from '../../../ActionBuilder2';
import ActionTypes from '../../../ActionTypes';

const loadHistory = async (shell, mcNumbers, offset, limit, sort) => {

  const { shipments, count } = await shell.gateway.getCarrierHistory({
    filter: {
      carrierMcNumber: mcNumbers
    },
    offset,
    limit,
    sort
  });

  return {
    type: ActionTypes.Carrier.Modification.CarrierHistory.Loaded,
    shipments,
    count
  };
}

export default ActionBuilder
  .for(loadHistory)
  .build();