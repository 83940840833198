export const MoneyAggregateOptions = [
  {
    id: 'total',
    name: 'Total'
  },
  {
    id: 'average',
    name: 'Average'
  },
  {
    id: 'minimum',
    name: 'Minimum'
  },
  {
    id: 'maximum',
    name: 'Maximum'
  }
];

export const MoneyChartFeatures = [
  ['customerCost', 'Customer Cost'],
  ['carrierCost', 'Carrier Cost'],
  ['adjCustomerCost', 'Adj. Customer Cost'],
  ['adjCarrierCost', 'Adj. Carrier Cost'],
  ['margin', 'Margin']
];

export const MoneyChartDescriptor = {
  id: 'money',
  name: 'Money',
  features: {
    customerCost: {
      lineColor: '#002bff',
      fieldAggregates: {
        total: 'totalCustomerCost',
        average: 'averageCustomerCost',
        maximum: 'maxCustomerCost',
        minimum: 'minCustomerCost'
      }
    },
    adjCustomerCost: {
      lineColor: '#e862b3',
      fieldAggregates: {
        total: 'totalAdjCustomerCost',
        average: 'averageAdjCustomerCost',
        maximum: 'maxAdjCustomerCost',
        minimum: 'minAdjCustomerCost'
      }
    },
    carrierCost: {
      lineColor: '#11838f',
      fieldAggregates: {
        total: 'totalCarrierCost',
        average: 'averageCarrierCost',
        maximum: 'maxCarrierCost',
        minimum: 'minCarrierCost'
      }
    },
    adjCarrierCost: {
      lineColor: '#fb8b24ff',
      fieldAggregates: {
        total: 'totalAdjCarrierCost',
        average: 'averageAdjCarrierCost',
        maximum: 'maxAdjCarrierCost',
        minimum: 'minAdjCarrierCost'
      }
    },
    margin: {
      lineColor: '#4e9b40',
      fieldAggregates: {
        total: 'totalMargin',
        average: 'averageMargin',
        maximum: 'maxMargin',
        minimum: 'minMargin'
      }
    }
  },
  getFeatureTitle(featureName) {
    return MoneyChartFeatures.find(([name]) => name === featureName)[1];
  },
  fields(featureNames, aggregateType) {
    return featureNames.map((featureName) =>
      this.features[featureName].fieldAggregates[aggregateType]);
  },
  totalShipments(data) {
    return data.reduce((total, row) => total + row.shipmentCount, 0);
  },
  total(featureName, data) {
    return data.reduce((agg, row) => {
      const fieldName = this.features[featureName].fieldAggregates.total;
      const fieldValue = row[fieldName];
      return agg + fieldValue;
    }, 0);
  },
  average(featureName, data) {
    return this.total(featureName, data) / this.totalShipments(data);
  },
  maximum(featureName, data) {
    return data.reduce((highValue, row) => {
      const fieldName = this.features[featureName].fieldAggregates.maximum;
      const fieldValue = row[fieldName];
      return highValue > fieldValue ? highValue : fieldValue
    }, 0)
  },
  minimum(featureName, data) {
    return data.reduce((lowValue, row) => {
      const fieldName = this.features[featureName].fieldAggregates.minimum;
      const fieldValue = row[fieldName];
      return lowValue < fieldValue ? lowValue : fieldValue
    }, Number.MAX_VALUE)
  }
};

export const PaymentAgeAggregateOptions = [
  {
    id: 'total',
    name: 'Total'
  },
  {
    id: 'average',
    name: 'Average'
  },
  {
    id: 'minimum',
    name: 'Minimum'
  },
  {
    id: 'maximum',
    name: 'Maximum'
  }
];

export const PaymentAgeChartFeatures = [
  ['age', 'Age (Days)'],
  ['overdueAge', 'Overdue Age (Days)']
];

export const PaymentAgeChartDescriptor = {
  id: 'payment-age',
  name: 'Payment Age',
  warningLabel: 'Any "Payment Age" data presented involving a shipment before 5/1/2021 will likely be incorrect due to previous back office processes.',
  features: {
    age: {
      lineColor: '#2c41a6',
      fieldAggregates: {
        total: 'totalAge',
        average: 'averageAge',
        maximum: 'maxAge',
        minimum: 'minAge'
      }
    },
    overdueAge: {
      lineColor: '#b92525',
      fieldAggregates: {
        total: 'totalOverdueAge',
        average: 'averageOverdueAge',
        maximum: 'maxOverdueAge',
        minimum: 'minOverdueAge'
      }
    }
  },
  getFeatureTitle(featureName) {
    return PaymentAgeChartFeatures.find(([name]) => name === featureName)[1];
  },
  fields(featureNames, aggregateType) {
    return featureNames.map((featureName) =>
      this.features[featureName].fieldAggregates[aggregateType]);
  },
  totalDays(data) {
    return data.reduce((total, row) => total + (row.shipmentCount > 0 ? 1 : 0), 0);
  },
  total(featureName, data) {
    return data.reduce((agg, row) => {
      const fieldName = this.features[featureName].fieldAggregates.total;
      const fieldValue = row[fieldName];
      return agg + fieldValue;
    }, 0);
  },
  average(featureName, data) {
    return this.total(featureName, data) / this.totalDays(data)
  },
  maximum(featureName, data) {
    return data.reduce((highValue, row) => {
      const fieldName = this.features[featureName].fieldAggregates.maximum;
      const fieldValue = row[fieldName];
      return highValue > fieldValue ? highValue : fieldValue
    }, 0)
  },
  minimum(featureName, data) {
    return data.reduce((lowValue, row) => {
      const fieldName = this.features[featureName].fieldAggregates.minimum;
      const fieldValue = row[fieldName];
      return lowValue < fieldValue ? lowValue : fieldValue
    }, Number.MAX_VALUE)
  }
};

export const VolumeAggregateOptions = [
  {
    id: 'total',
    name: 'Total'
  }
];

export const VolumeChartFeatures = [
  ['volume', 'Volume']
];

export const VolumeChartDescriptor = {
  id: 'volume',
  name: 'Volume',
  features: {
    volume: {
      lineColor: '#2ca62e',
      fieldAggregates: {
        total: 'shipmentCount'
      }
    }
  },
  getFeatureTitle(featureName) {
    return VolumeChartFeatures.find(([name]) => name === featureName)[1];
  },
  fields(featureNames, aggregateType) {
    return featureNames.map((featureName) =>
      this.features[featureName].fieldAggregates[aggregateType]);
  },
  total(featureName, data) {
    return data.reduce((agg, row) => {
      const fieldName = this.features[featureName].fieldAggregates.total;
      const fieldValue = row[fieldName];
      return agg + fieldValue;
    }, 0);
  }
};

export const TimePeriods = [
  'day',
  'week',
  'month',
  'quarter',
  'year'
];

export const TimePeriodFields = {
  day: ['year', 'month', 'day'],
  week: ['year', 'week'],
  month: ['year', 'month'],
  quarter: ['year', 'quarter'],
  year: ['year'],
  format(timePeriod, row) {
    if (!row)
      return '';

    const fields = this[timePeriod];
    return fields.map(field => row[field]).join('-');
  }
};

export const ChartTypeOptions = [MoneyChartDescriptor, PaymentAgeChartDescriptor, VolumeChartDescriptor]
  .map(({ id, name, warningLabel }) => ({ id, name, warningLabel }));

export const ChartDimensions = {
  LeftMargin: 70,
  BottomMargin: 30,
  TopMargin: 10,
  Width: 650,
  Height: 200,
  HorizontalTickSize: 80,
  VerticalTickSize: 60,
  TooltipWidth: 100,
  TooltipHeight: 30
};