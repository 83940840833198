import React from 'react';
import Core from '@atomos/core';
import {Grid, Typography} from '@material-ui/core';

import ComponentBuilder from '../../../core/ComponentBuilder';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import ListingFilterBar from './ListingFilterBar';
import ShipmentsListingTable from './includes/ShipmentsListingPage';
import CrmAssociateComboBox from "../../../crm/components/CrmAssociateComboBox";
import RoleNames from "../../../hubs/persona/RoleNames";
import isAce from "../../../hubs/persona/selectors/isAce";
import CrmShipmentBolNumberSearchComboBox from "../../../crm/components/CrmShipmentBolNumberSearchComboBox";
import "./ListingPage.scss";

const LoadProcessName = 'Shipment.ListingPage.Load';

const ListingPage = (props) => {

  const {
    associateRoles,
    match,
    history,
    shipments,
    shipmentCount,
    trackedCounts,
    isAce,
    load,
    dispose,
  } = props;

  const {
    trackedSet
  } = match.params;

  const queenRoleId = Core.Utils.get(Core.Utils.find(associateRoles, r => r.name === RoleNames.Queen), 'id', null);

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['bolNumber', 'desc']]);
  const [filterAssociate, setFilterAssociate] = React.useState(null);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('bolNumber');

  React.useEffect(() => () => dispose(), [])
  React.useEffect(() => {
    if (!trackedSet) {
      history.replace('/load-board/working');
    }
    else {
      load(trackedSet, filterAssociate, offset, limit, sort);
    }
  }, [trackedSet, filterAssociate, offset, limit, sort, history, load]);

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

    // Handles when the user clicks on column headers for sorting.
    const handleSortChange = (column) => {
      const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

      setSort([[column, changeOrder]]);
      setOrder(changeOrder);
      setOrderBy(column);
    };

  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };

  const handleSelectedBolNumber = (selectedBolNumber) => {
    if (selectedBolNumber)
      history.push(`/shipment/${selectedBolNumber}/details`);
  };

  const onSelectAssociate = (associate) => {
    associate ?
      setFilterAssociate(associate.id) :
      setFilterAssociate(null);
  };

  const title = (
    <Grid container>
      <Grid item xs={10}>
        <Typography variant={'h3'}>
          Load Board
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <CrmShipmentBolNumberSearchComboBox
          placeholder={'Shipment number ...'}
          onChange={handleSelectedBolNumber}
          filterMap={Core.Utils.identity}
          popupIcon={' '}
          style={{ minWidth: 100 }}
        />
      </Grid>
    </Grid>
  );

  return (
    <FullWidthLayout title={title} className={"shipments-styles"}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item>
            <ListingFilterBar
              trackedSet={trackedSet}
              counts={trackedCounts}
              isAce={isAce}
              onClick={() => setOffset(0)}
            />
            </Grid>
            <Grid item>
              {
                isAce &&
                <CrmAssociateComboBox
                  placeholder={'Search agents...'}
                  roleId={queenRoleId}
                  onSelectAssociate={onSelectAssociate}
                  style={{ minWidth: '250px'}}
                />
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <ShipmentsListingTable
            count={shipmentCount}
            data={shipments}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
          />
        </Grid>
      </Grid>
    </FullWidthLayout>
  );
};

export default ComponentBuilder
  .wrap(ListingPage)
  .stateToProps((state, ownProps) => ({
    filterTgfOnly: state.shipment.loadBoard.filterTgfOnly,
    shipmentCount: state.shipment.loadBoard.shipmentCount,
    shipments: state.shipment.loadBoard.shipments,
    trackedCounts: state.shipment.loadBoard.trackedCounts,
    associateRoles: state.persona.roles,
    isAce: isAce(state)
  }))
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(trackedSet, associateId, pageNumber, pageSize, sort) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.shipment.loadBoard.load(trackedSet, null, associateId, pageNumber, pageSize, sort));
        dispatch(await shell.actions.persona.loadRoles());
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.shipment.loadBoard.dispose())
      }
    };
  })
  .build();