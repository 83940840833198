import React from 'react';
import CrmAssociateComboBox from '../../../../crm/components/CrmAssociateComboBox';

const QueenSelectorField = (props) => {
  const {
    disabled,
    ...otherProps
  } = props;

  return (
    <React.Fragment>
      <label>Queen</label>
      <CrmAssociateComboBox
        disabled={disabled}
        {...otherProps}
      />
    </React.Fragment>
  )
};

export default QueenSelectorField;