import { Grid } from '@material-ui/core';
import React from 'react';
import AppCard from 'core/components/cards/AppCard';
import AppSwitch from 'core/components/inputs/AppSwitch';
import FormFactorValueDropdown from 'core/FormFactor/FormFactorValueDropdown';
import FormFactorValueInput from 'core/FormFactor/FormFactorValueInput';
import FormFactorValidationFailure from '../../../../core/FormFactor/FormFactorValidationFailure';

const InvoiceSettingsCard = () => {
  return (
    <AppCard title={'Invoice Options'}>
      <Grid container spacing={2} alignItems={'flex-end'}>
        <Grid item xs={6}>
          <FormFactorValueDropdown
            formField={'selectedInvoiceDeliveryMethodType'}
            optionsFormField={'invoiceDeliveryTypes'}
            optionField={'name'}
            optionFieldIndex={'id'}
            id={'selectedInvoiceDeliveryMethodType'}
            label={'Delivery Method'}
          />
          <FormFactorValidationFailure field={'selectedInvoiceDeliveryMethodType'} />
        </Grid>
        <Grid item xs={6}>
          <FormFactorValueInput
            field={'batchInvoices'}
            as={AppSwitch}
            valueProp={'checked'}
            valueDefault={false}
            eventValueMap={(e) => e.target.checked}
            id={'batchInvoices'}
            label={'Batch Invoices'}
            additionalSlices={['supportingValues.allowInvoiceBatching']}
            additionalGraft={([allowBatching]) => ({ disabled: !allowBatching })}
          />
        </Grid>
        <Grid item xs={12}>
          <FormFactorValueInput
            field={'deliveryMethodNote'}
            id={'deliveryMethodNote'}
            label={'Delivery Method Notes'}
            inputProps={{ maxLength: 1000, rowsMin: 8 }}
            multiline={true}
          />
          <FormFactorValidationFailure field={'deliveryMethodNote'} />
        </Grid>
      </Grid>
    </AppCard>
  );
};

export default InvoiceSettingsCard;