import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableSortLabel, TableCell } from '@material-ui/core';
import computeCellStyle from './computeCellStyle';
import combineClassNames from '../../../utils/combineClassNames';

const useStyles = makeStyles((theme) => ({
  rowPadding: {
    paddingLeft: '5px!important'
  },
  ascActive: {
    boxShadow: '0px 3px 0px 0px #8c4537 inset'
  },
  descActive: {
    boxShadow: '0px -3px 0px 0px #8c4537 inset'
  }
}))

const AppTableHead = (props) => {
  const classes = useStyles();
  const {
    columns,
    orderBy,
    order,
    handleDirection,
    handleSort
  } = props;

  return (
    <TableHead>
      <TableRow>
        {
          columns.map((column, index) => {

            const isSorted = !column.noSort && orderBy === columns[index].field;

            const className = combineClassNames(
              classes.rowPadding,
              isSorted && classes[`${order}Active`]
            );

            return (<TableCell
              className={className}
              style={computeCellStyle(column.styles, column.styles.head)}
              key={index}>
              {
                column.noSort ?
                  column.title :
                  <TableSortLabel
                    direction={handleDirection(index, order)}
                    onClick={() => handleSort(index)}
                    hideSortIcon={true}
                  >
                    {column.title}
                  </TableSortLabel>
              }
            </TableCell>)
          })
        }
      </TableRow>
    </TableHead>
  )
};

export default AppTableHead;