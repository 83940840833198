import React from 'react';
import {TableCell, TableRow} from '@material-ui/core';

import AppIconButton from '../../../core/components/AppButton/AppIconButton';
import { Edit, Delete } from '@material-ui/icons';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';
import Core from '@atomos/core';
import SecureAction from "../permissions/SecureAction";
import AppIntegerText from '../../../core/components/text/AppIntegerText';

const widthHeaderMap = (width) => (index, columns) =>
  ({ width, style: { padding: '3px 6px', textAlign: 'center' } });

const makeTableFixed = (baseProps) =>
  Core.Utils.merge({}, baseProps, {
    style: { tableLayout: 'fixed' },
    width: '100%'
  });

const Columns = [
  {
    title: 'Pkg. Count',
    headerPropMap: widthHeaderMap('8%')
  },
  {
    title: 'Pkg. Type',
    headerPropMap: widthHeaderMap('12%')
  },
  {
    title: 'Contains',
    headerPropMap: widthHeaderMap('10%')
  },
  {
    title: 'Description',
    headerPropMap: widthHeaderMap('32%')
  },
  {
    title: 'Dimensions',
    headerPropMap: widthHeaderMap('10%')
  },
  {
    title: 'Class',
    headerPropMap: widthHeaderMap('8%')
  },
  {
    title: 'NMFC',
    headerPropMap: widthHeaderMap('8%')
  },
  {
    title: 'Weight (lbs)',
    headerPropMap: widthHeaderMap('10%')
  },
  {
    title: '',
    headerPropMap: widthHeaderMap('10%')
  }
];

const baseCellStyle = {
  padding: '3px 6px',
  textAlign: 'center'
};

const descriptionCellStyle = {
  ...baseCellStyle,
  overflowWrap: 'anywhere',
  textAlign: 'left'
};

const ContentListingGrid = ({ contents, freightClasses, packageTypes, onEditClick, onDeleteClick}) =>
  <AppMuiSimpleTable
    columns={Columns}
    tablePropMap={makeTableFixed}>
    {
      contents.map((contents, index) =>
        <TableRow key={index}>
          <TableCell style={baseCellStyle}>
            <AppIntegerText value={contents.packageCount} />
          </TableCell>
          <TableCell style={baseCellStyle}>
            {contents.packageTypeId ? packageTypes.find(p => p.id === contents.packageTypeId).name : ''}
          </TableCell>
          <TableCell style={baseCellStyle}>
            <AppIntegerText value={contents.pieceCount} />
          </TableCell>
          <TableCell style={descriptionCellStyle}>
            {contents.description}
          </TableCell>
          <TableCell style={baseCellStyle}>
            {contents.dimensions}
          </TableCell>
          <TableCell style={baseCellStyle}>
            {contents.freightClassId ? freightClasses.find(c => c.id === contents.freightClassId).value : ''}
          </TableCell>
          <TableCell style={baseCellStyle}>
            {contents.nmfc}
          </TableCell>
          <TableCell style={baseCellStyle}>
            <AppIntegerText value={contents.packageWeight} />
          </TableCell>
          <TableCell style={baseCellStyle}>
            <SecureAction
              pageName={'loadContents'}
              actionName={'addContent'}
              as={AppIconButton}
              Icon={Edit}
              onClick={() => onEditClick(contents)}
            />
            <SecureAction
              pageName={'loadContents'}
              actionName={'deleteContent'}
              as={AppIconButton}
              Icon={Delete}
              color={'danger'}
              onClick={() => onDeleteClick(contents)}
            />
          </TableCell>
        </TableRow>
      )
    }
  </AppMuiSimpleTable>

export default ContentListingGrid;