import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import ComponentBuilder from "../../core/ComponentBuilder";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FreightCategoryTypeNames from '../../hubs/shipment/FreightCategoryTypeNames';
import AppTextBox from "../../core/components/inputs/AppTextBox/AppTextBox";
import combineClassNames from '../../core/utils/combineClassNames';
import AppTooltip from "../../core/components/AppTooltip";
import When from "../../core/components/condtionals/When";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 0,
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.border,
      padding: '0 0 0 10px'
    }
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}));

const constructTooltipTitle = (carrier) => {
  if (carrier) {
    return (
      <>
        <div>
          <strong>{carrier.mcNumber} / {carrier.name}</strong>
        </div>
        <Address
          address1={carrier.address1}
          address2={carrier.address2}
          city={carrier.city}
          stateProvince={carrier.stateProvince}
          postalCode={carrier.postalCode}
        />
      </>
    )
  }

  return null;
}

const CrmShipmentCarrierComboBox = (props) => {
  const classes = useStyles();
  const {
    searchCarriers,
    onChange,
    staticContext,
    filterMap,
    placeholder,
    disabled = false,
    ...otherProps
  } = props;

  const value = props.value;
  const [isOpen, setIsOpen] = React.useState(false);
  const [carriers, setCarriers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tooltipTitle, setTooltipTitle] = React.useState('');

  React.useEffect(() => {
        setTooltipTitle(constructTooltipTitle(value));
  }, [value]);

  const handleSearchTermChange = async (e) => {
    setIsLoading(true);
    const carriers = await searchCarriers(e.target.value);

    setCarriers(carriers);
    setIsLoading(false);
  };

  const handleOnChange = (e, carrierListing, reason) => {
    onChange(carrierListing);
  };

  const filterOptions = createFilterOptions({
    // matchFrom: 'start', *** ('any' | 'start' [optional]): Defaults to 'any'.
    stringify: (option) => option.name + option.mcNumber,
  });

  return (
    <Autocomplete
      open={isOpen}
      className={combineClassNames(classes.root, disabled ? classes.disabled : null)}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      disabled={disabled}
      options={carriers}
      loading={isLoading}
      onChange={handleOnChange}
      {...otherProps}
      getOptionSelected={(carrierListing, selectedAbListing) => carrierListing.mcNumber === selectedAbListing.mcNumber}
      getOptionLabel={(carrierListing) => `${carrierListing.mcNumber} / ${carrierListing.name}`}
      filterOptions={filterOptions}
      renderInput={
        (params) => (
          <AppTooltip title={tooltipTitle}>
            <AppTextBox
              {...params}
              variant={'outlined'}
              onChange={handleSearchTermChange}
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps
              }}
            />
          </AppTooltip>
        )
      }
      renderOption={
        (option, state) => {
          return (
            <React.Fragment>
              {
                option.freightCategory.name === FreightCategoryTypeNames.Truckload ?
                  <span>{option.mcNumber} / {option.name} </span> :
                  <span>{option.name} </span>
              }
            </React.Fragment>
          )
        }
      }
    />
  );

};

const Address = (props) => {

  const {
    address1,
    address2 = '',
    city,
    stateProvince,
    postalCode
  } = props;

  const hasAddress = Boolean(
    address1 &&
    city &&
    stateProvince &&
    postalCode
  );

  if (!hasAddress)
    return null;

  return (
    <React.Fragment>
      <div>
        {address1}
      </div>
      <When condition={address2}>
        {() =>
          <div>
            {address2}
          </div>
        }
      </When>
      <div>
        {city}, {stateProvince} {postalCode}
      </div>
    </React.Fragment>
  );
};


export default ComponentBuilder
  .wrap(CrmShipmentCarrierComboBox)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async searchCarriers(searchTerm) {

        if (searchTerm.trim().length === 0)
          return [];

        const filter = ownProps.filterMap({
          $or: [
            {
              name: {
                $like: `${searchTerm}%`
              }
            },
            {
              mcNumber: {
                $like: `${searchTerm}%`
              }
            }
          ]
        });

        const searchOptions = {
          filter,
          limit: ownProps.limit
        }

        const { carriers } = await shell.gateway.searchCarriers(searchOptions);
        return carriers;
      }
    }

  })
  .build();