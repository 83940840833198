import React from 'react';
import AppCard from '../../../../core/components/cards/AppCard';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';
import {AddToPhotos, Replay} from '@material-ui/icons';
import AppIconButton from '../../../../core/components/AppButton/AppIconButton';
import CustomerAnalysisChart from './CustomerAnalysisChart';
import CustomizeChartDialog from './CustomizeChartDialog';
import DefaultChartManifest from './DefaultChartManifest';

const ManifestStorageKey = 'tgf-crm-financial-health-chart-manifest';

const CustomerAnalysisCard = (props) => {

  const { company } = props;

  const manifestText = localStorage.getItem(ManifestStorageKey);
  const initialManifest = manifestText ?
    JSON.parse(manifestText) : DefaultChartManifest;

  const [activeChartSettings, setActiveChartSettings] = React.useState(null);
  const [customerAnalysisManifest, setCustomerAnalysisManifest] = React.useState(initialManifest);

  const handleAddChartClick = () => {
    setActiveChartSettings({
      title: 'Customer Chart',
      type: 'money',
      timePeriod: 'day',
      startDate: null,
      endDate: null,
      aggregateType: null,
      features: {}
    });
  };

  const handleResetChartClick = () => {
    setCustomerAnalysisManifest(DefaultChartManifest);
    localStorage.setItem(ManifestStorageKey, JSON.stringify(DefaultChartManifest));
  };

  const handleCustomizeChartSaveClick = (newChartSettings) => {
    let updatedManifest = null;
    if (!newChartSettings.id) {
      updatedManifest = [
        ...customerAnalysisManifest,
        { ...newChartSettings, id: Date.now() }
      ];
    }
    else {
      updatedManifest = customerAnalysisManifest.map(cs => {
        return cs.id === newChartSettings.id ?
          newChartSettings : cs;
      });
    }
    setCustomerAnalysisManifest(updatedManifest);
    localStorage.setItem(ManifestStorageKey, JSON.stringify(updatedManifest));
    setActiveChartSettings(null);
  };

  const handleCustomizeChartCancelClick = () => {
    setActiveChartSettings(null);
  }

  const handleChartEditClick = (chartSettings) => {
    setActiveChartSettings(chartSettings);
  };

  const handleChartDeleteClick = (chartSettings) => {
    const updatedManifest = customerAnalysisManifest.filter(cs => {
      return cs.id !== chartSettings.id
    });
    localStorage.setItem(ManifestStorageKey, JSON.stringify(updatedManifest));
    setCustomerAnalysisManifest(updatedManifest);
  };

  const configureAction = (
    <React.Fragment>
      <AppIconButton
        Icon={Replay}
        tooltip={'Click to reset charts.'}
        color={'primary'}
        onClick={handleResetChartClick}
      />
      <AppIconButton
        Icon={AddToPhotos}
        tooltip={'Click to add a new chart.'}
        color={'primary'}
        onClick={handleAddChartClick}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <AppCard title={'Customer Analysis'} action={configureAction}>
        {
          customerAnalysisManifest.length === 0 &&
          <NoChartsConfigured />
        }
        {
          company &&
          customerAnalysisManifest.length > 0 &&
          customerAnalysisManifest.map((chartSettings, index) => {
            return (<CustomerAnalysisChart
              key={index}
              company={company}
              settings={chartSettings}
              onDeleteClick={() => handleChartDeleteClick(chartSettings)}
              onEditClick={() => handleChartEditClick(chartSettings)}
            />);
          })
        }
      </AppCard>
      {
        activeChartSettings &&
        <CustomizeChartDialog
          settings={activeChartSettings}
          onSaveClick={handleCustomizeChartSaveClick}
          onCancelClick={handleCustomizeChartCancelClick}
        />
      }
    </React.Fragment>
  );
};

const NoChartsConfigured = () => {
  return (
    <AppAlertStatic color={'info'}>
      You have no charts configured. Click the plus icon to begin adding
      charts to this page.  Each chart configured will be saved in your browser's storage to
      make reloading frequent charts easier.
    </AppAlertStatic>
  );
};

export default CustomerAnalysisCard;