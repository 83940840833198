const DetailsActions = [];
const DetailsFields = [
  'status',
  'freightCategory',
  'customer',
  'bolDate',
  'pickupTime',
  'estimatedDeliveryDate',
  'deliveryTime',
  'equipmentType',
  'customerRep',
  'shipper',
  'consignee',
  'carrier',
  'pickupNumber',
  'deliveryNumber',
  'refNum1Description',
  'refNum1',
  'refNum2Description',
  'refNum2',
  'refNum3Description',
  'refNum3',
  'refNum4Description',
  'refNum4',
  'hazardousState',
  'isBlind',
  'isDeliveryOrderRequired',
  'isHardCopyPodRequired',
  'note',
  'rateConNote',
  'proNumber',
  'ratingRefNumber',
  'repName',
  'repPhone',
  'repEmail',
  'experienceNote',
  'driverName',
  'driverPhone',
  'truckNumber',
  'trailerNumber',
  'thirdPartyId'
];

const PermissionSet = {
  name: 'details',
  actions: DetailsActions,
  fields: DetailsFields
};

export default PermissionSet;