import React from 'react';
import { Grid } from '@material-ui/core';

import AppDialog from '../../../../core/components/AppDialog/AppMuiDialog';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppMuiInput from '../../../../core/components/inputs/AppInput/AppMuiInput';
import AppNumericInput from '../../../../core/components/inputs/AppNumericInput/AppMuiNumericInput';
import AppValidationFailure from '../../../../core/components/AppValidationFailure';

const renderModifyContentForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {
  const actions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleFieldChange = (key) => (e) =>
    setFieldValue(key, e.target.value);

  const handlePackageTypeChange = (e, value) => {
    const packageType = value;

    if (packageType) {
      setFieldValue('packageTypeId', packageType.id);
      setFieldValue('selectedPackageType', packageType);
    } else {
      setFieldValue('packageTypeId', null);
      setFieldValue('selectedPackageType', null);
    }
  };

  const handleFreightClassChange = (e, value) => {
    const freightClass = value;

    if (freightClass) {
      setFieldValue('freightClassId', freightClass.id);
      setFieldValue('selectedFreightClass', freightClass);
    } else {
      setFieldValue('freightClassId', null);
      setFieldValue('selectedFreightClass', null);
    }
  };

  const fullPackageTypes = [{ id: null, name: '' }]
    .concat(values.packageTypes);

  return (
    <AppDialog
      title={values.id && values.id > 0 ? 'Modify Content' : 'New Content'}
      width='md'
      open={true}
      onClose={values.onClose}
      actionButtons={actions}
    >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <AppNumericInput
              label={'Package Count'}
              min={0}
              value={values.packageCount}
              onChange={handleFieldChange('packageCount')}
            />
            <AppValidationFailure message={errors.packageCount} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppDropdown
              label="Package Type"
              field={'name'}
              fieldIndex={'id'}
              options={fullPackageTypes}
              value={values.selectedPackageType}
              onChange={handlePackageTypeChange}
            />
            <AppValidationFailure message={errors.packageTypeId} />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppNumericInput
              label={'Said to Contain'}
              min={0}
              value={values.pieceCount}
              onChange={handleFieldChange('pieceCount')}
            />
          </Grid>
          <Grid item xs={12}>
            <AppMuiInput
              label={'Description (max. 250 characters)'}
              multiline={true}
              rows='3'
              value={values.description || ''}
              inputProps={{ maxLength: 250 }}
              onChange={handleFieldChange('description')}
            />
            <AppValidationFailure message={errors.description} />
          </Grid>
          {
            !values.isLoadTl &&
            <React.Fragment>
              <Grid item xs={12} md={2}>
                <AppDropdown
                  label="Class"
                  field={'value'}
                  fieldIndex={'id'}
                  options={values.freightClasses}
                  value={values.selectedFreightClass}
                  onChange={handleFreightClassChange}
                />
                <AppValidationFailure message={errors.selectedFreightClass} />
              </Grid>
              <Grid item xs={12} md={3}>
                <AppMuiInput
                  label={'NMFC'}
                  value={values.nmfc || ''}
                  inputProps={{ maxLength: 10 }}
                  onChange={handleFieldChange('nmfc')}
                />
                <AppValidationFailure message={errors.nmfc} />
              </Grid>
            </React.Fragment>
          }
          <Grid item xs={12} md={4}>
            <AppMuiInput
              label={'Dimensions (L x W x H in inches)'}
              value={values.dimensions || ''}
              inputProps={{ maxLength: 15 }}
              onChange={handleFieldChange('dimensions')}
            />
            <AppValidationFailure message={errors.dimensions}/>
          </Grid>
          <Grid item xs={12} md={3}>
            <AppNumericInput
              label={'Total Weight (lbs)'}
              min={0}
              value={values.packageWeight}
              onChange={handleFieldChange('packageWeight')}
            />
            <AppValidationFailure message={errors.packageWeight} />
          </Grid>
        </Grid>
    </AppDialog>
  );
};

export default renderModifyContentForm;