import React from 'react';
import {Button, ButtonGroup, Grid} from '@material-ui/core';
import AppDropdown from '../../../../core/components/dropdowns/AppDropdown';
import AppDialog from '../../../../core/components/AppDialog';
import AppInput from '../../../../core/components/inputs/AppInput';
import ComponentBuilder from '../../../../core/ComponentBuilder';
import AppDatePicker from '../../../../core/components/inputs/AppDatePicker';
import AppMuiCheckbox from '../../../../core/components/inputs/AppCheckbox';
import {
  TimePeriods,
  MoneyChartFeatures,
  ChartTypeOptions,
  PaymentAgeChartFeatures,
  VolumeChartFeatures,
  MoneyAggregateOptions,
  PaymentAgeAggregateOptions,
  VolumeAggregateOptions
} from './SupportTypes';
import AppAlertStatic from '../../../../core/components/AppAlertStatic';

const identifyAggregateOptions = (chartType) =>
  chartType === 'money' ?
    MoneyAggregateOptions :
    chartType === 'payment-age' ?
      PaymentAgeAggregateOptions :
      VolumeAggregateOptions;

const defaultFeatureKey = (chartType) =>
  chartType === 'money' ?
    'margin' :
    chartType === 'payment-age' ?
      'age' :
      'volume';

const buildDefaultFeatures = (chartType) => ({
  [defaultFeatureKey(chartType)]: true
});

const maybeDefaultFeatures = (features, chartType) => {
  const pairs = Object.entries(features);
  return pairs.length === 0 || pairs.every(([key, isEnabled]) => !isEnabled) ?
    buildDefaultFeatures(chartType) :
    features;
};

const CustomizeChartDialog = (props) => {

  const {
    classes,
    settings: chartSettings,
    onSaveClick,
    onCancelClick
  } = props;

  const [selectedType, setSelectedType] = React.useState(
    ChartTypeOptions.find(ct => ct.id === chartSettings.type) || null
  );
  const [title, setTitle] = React.useState(chartSettings.title || '');
  const [timePeriod, setTimePeriod] = React.useState(chartSettings.timePeriod || TimePeriods[0]);
  const [startDate, setStartDate] = React.useState(chartSettings.startDate || null);
  const [endDate, setEndDate] = React.useState(chartSettings.endDate || null);
  const [aggregateOptions, setAggregateOptions] = React.useState(identifyAggregateOptions(chartSettings.type))
  const [selectedAggregateType, setSelectedAggregateType] = React.useState(
    aggregateOptions.find(agg => agg.id === chartSettings.aggregateType) || aggregateOptions[0]
  );
  const [features, setFeatures] = React.useState(maybeDefaultFeatures(chartSettings.features, chartSettings.type));

  const handleSave = () => {

    const newSettings = {
      id: chartSettings.id,
      title,
      type: selectedType?.id,
      timePeriod,
      startDate,
      endDate,
      aggregateType: selectedAggregateType?.id,
      features
    };

    onSaveClick(newSettings);
  };

  const handleChartTypeChange = (e, chartType) => {
    setSelectedType(chartType);

    const newAggOptions = identifyAggregateOptions(chartType.id);

    setAggregateOptions(newAggOptions);
    // If the chart type doesn't support the new aggregate, default it to
    // the first available.
    if (!newAggOptions.some(agg => agg.id === selectedAggregateType?.id)) {
      setSelectedAggregateType(newAggOptions[0]);
    }

    setFeatures(buildDefaultFeatures(chartType.id));
  };

  const handleFeatureChange = (features) => {
    setFeatures(features);
  };

  const handleDate = (setMethod) => (date) =>
    setMethod(date && date.isValid() ? date.toDate() : null);

  const handleAggregateTypeChange = (e, aggType) => {
    setSelectedAggregateType(aggType);
  };

  const chartSettingsActions = [
    {
      title: 'Cancel',
      action: onCancelClick
    },
    {
      title: 'Save',
      action: handleSave
    }
  ];

  const featureDescriptors = selectedType?.id === 'money' ?
    MoneyChartFeatures :
    selectedType?.id === 'payment-age' ?
      PaymentAgeChartFeatures :
      VolumeChartFeatures;

  return (
    <AppDialog
      title={'Customize Chart'}
      width={'xs'}
      open={true}
      onClose={onCancelClick}
      actionButtons={chartSettingsActions}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AppInput
            label={'Title'}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <AppDropdown
            label={'Chart Type'}
            fieldIndex={'id'}
            field={'name'}
            options={ChartTypeOptions}
            value={selectedType}
            onChange={handleChartTypeChange}
          />
        </Grid>
        {
          selectedType?.warningLabel &&
          <AppAlertStatic
            style={{fontSize:9}}
            title={selectedType.warningLabel}
            color={'warning'}
          />
        }
        <Grid item xs={12}>
          <label>Time Period</label>
          <ButtonGroup>
            {
              TimePeriods.map((tp) =>
                <Button
                  key={tp}
                  onClick={() => setTimePeriod(tp)}
                  className={tp === timePeriod ? classes.timePeriodActive : null}
                >
                  {tp}
                </Button>
              )
            }
          </ButtonGroup>
        </Grid>
        <Grid item xs={6}>
          <AppDatePicker
            id="startDate"
            name="startDate"
            label="Start Date"
            value={startDate}
            onChange={handleDate(setStartDate)}
          />
        </Grid>
        <Grid item xs={6}>
          <AppDatePicker
            id="endDate"
            name="endDate"
            label="End Date"
            value={endDate}
            onChange={handleDate(setEndDate)}
          />
        </Grid>
        <Grid item xs={12}>
          <AppDropdown
            label={'Aggregate Type'}
            fieldIndex={'id'}
            field={'name'}
            options={aggregateOptions}
            value={selectedAggregateType}
            onChange={handleAggregateTypeChange}
          />
        </Grid>
        <Grid item xs={12} className={classes.featuresContainer}>
          <CheckboxFeatures
            featureDescriptors={featureDescriptors}
            features={features}
            onChange={handleFeatureChange}
          />
        </Grid>
      </Grid>
    </AppDialog>
  );
};

const CheckboxFeatures = ({ featureDescriptors, features, onChange }) => {

  const handleChange = (featureName, enabled) => {

    const changedFeatures = {
      ...features,
      [featureName]: enabled
    };

    if (Object.values(changedFeatures).some(x => x)) {
      onChange(changedFeatures);
    }
  };

  return (
    <Grid container>
      {
        featureDescriptors.map(([featureName, featureTitle]) =>
          <Grid item key={featureName}>
            <AppMuiCheckbox
              label={featureTitle}
              checked={features[featureName] || false}
              handleChange={(e) => handleChange(featureName, e.target.checked)}
            />
          </Grid>
        )
      }
    </Grid>
  );
}

export default ComponentBuilder
  .wrap(CustomizeChartDialog)
  .withStyles((theme) => ({
    timePeriodActive: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white
    },
    featuresContainer: {
      minHeight: 150
    }
  }))
  .build();